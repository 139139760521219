import React from 'react';
import { Card} from "antd";

class  HelpActivateForm extends React.Component{
    render() {
        return (
            <Card className={'help-card'} title={'Help'} extra={<a href="#">hello@buzzz.io</a>} bordered={false}>
                <h3>Video tutorial</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae blanditiis consectetur cum ducimus enim nostrum officiis reprehenderit sapiente sunt, voluptatum. Debitis dicta neque praesentium sed soluta. Beatae cumque exercitationem temporibus.</p>
              <video src="http://clips.vorwaerts-gmbh.de/VfE_html5.mp4" controls></video>
            </Card>
        )
    }
}

export default HelpActivateForm;