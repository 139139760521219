import React, { Component } from 'react';
import {Button, Card, Form, Input, Checkbox, Select, notification} from 'antd';
import MobileInput from '@components/common/MobileNumberLayout';
import commonApiService from '@apiService';
import { PRIVACY_POLICY, TERMS_CONDITION } from '@constants/links';
import _ from 'lodash';
const AUTOFILLED = 'is-autofilled';
const onAutoFillStart = el => el.classList.add(AUTOFILLED);
const onAutoFillCancel = el => el.classList.remove(AUTOFILLED);

const onAnimationStart = ({ target, animationName }) => {
  switch (animationName) {
    case 'onAutoFillStart':
      return onAutoFillStart(target);
    case 'onAutoFillCancel':
      return onAutoFillCancel(target);
    default:
  }
};

class CreateAccountLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      subscriptions: [],
      fullFeatures: false,
      first_name: '',
      last_name: '',
      email: '',
      selectedPlan: props.plan,
      phone_number: '',
    };
    window.scrollTo(0, 0);
  }

  createAccInitiate = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      const phoneNumberData = this.refs.mobileInput.getPhoneNumber();
      if (!err && phoneNumberData) {
        this.setState({
          loading: true,
        });
        values.phone_number = phoneNumberData;
        this.props.nextStep(values);
      }
    });
  };

  setValues = values => {
    this.props.form.setFieldsValue({
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      confirm_password: values.confirm_password,
      companyName: values.companyName,
      companySize: values.companySize,
      sendEmailUpdates: values.sendEmailUpdates,
    });
    this.refs.mobileInput.updateInput(values.phone_number);
  };

  responseGoogle = resp => {
    if (resp.profileObj) {
      const name = resp.profileObj.name.split(' ');
      this.setState({
        first_name: _.first(name),
        last_name: _.tail(name).join(' '),
        email: resp.profileObj.email,
      });
    }
  };

  checkPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback("The passwords don't match");
    } else {
      callback();
    }
  };

  checkIfUniqueEmail = (rule, value, callback) => {
	  const form = this.props.form;
	  const enteredEmail = form.getFieldValue('email');

	  commonApiService
          .fetch('web/email/valid', {
			  email: enteredEmail
		  })
		  .then(
			  successResult => {
				  successResult.data.isUniqueEmail ?  callback() :callback("Email id is Already registered with us.");
			  },
			  errorResult => {
				  callback("Invalid Email");
			  },
		  );

  };

  componentDidMount() {
    if (this.props.user_details) {
      this.setValues(this.props.user_details);
    }
    let inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.addEventListener('animationstart', onAnimationStart, false);
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      details,
      pIsCustom,
      // pIsTrialAvailable,
      price,
    } = this.state.selectedPlan;
    return (
      <div className="create-acc">
        <div className="container">
          <div className="side-box">
            <h2>
              Create Account<span>.</span>
            </h2>
            <Card bordered={false}>
              <Form onSubmit={this.createAccInitiate}>
                <Form.Item>
                  {getFieldDecorator('first_name', {
                    validateTrigger: 'onBlur',
                    initialValue: this.state.first_name,
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Name!',
                      },
                    ],
                  })(<Input placeholder=" " />)}
                  <div className="floating-label required">First Name</div>
                </Form.Item>

                <Form.Item>
                  {getFieldDecorator('last_name', {
                    validateTrigger: 'onBlur',
                    initialValue: this.state.last_name,
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Name!',
                      },
                    ],
                  })(<Input placeholder=" " />)}
                  <div className="floating-label required">Last Name</div>
                </Form.Item>

                <Form.Item>
                  {getFieldDecorator('email', {
                    validateTrigger: 'onBlur',
                    initialValue: this.state.email,
                    rules: [
                      {
                        type: 'email',
                        message: 'The input is not valid Email address!',
                      },
                      {
                        required: true,
                        message: 'Please input your Email address!',
                      },
                      {
                          validator: this.checkIfUniqueEmail,
                      }
                    ],
                  })(<Input placeholder=" " />)}
                  <div className="floating-label required">Email address</div>
                </Form.Item>
                <MobileInput ref={'mobileInput'} />

                <Form.Item>
                  {getFieldDecorator('password', {
                    validateTrigger: 'onBlur',
                    rules: [
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ],
                  })(<Input type="password" placeholder=" " />)}
                  <div className="floating-label required">Password</div>
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('confirm_password', {
                    validateTrigger: 'onBlur',
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Confirm password!',
                      },
                      {
                        validator: this.checkPassword,
                      },
                    ],
                  })(<Input type="password" placeholder=" " />)}
                  <div className="floating-label required">
                    Confirm Password
                  </div>
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('companyName', {})(
                    <Input placeholder=" " />,
                  )}
                  <div className="floating-label">Company Name</div>
                </Form.Item>

                <Form.Item>
                  {getFieldDecorator('companySize', {
                    initialValue: 'Company Size',
                  })(
                    <Select placeholder={'Company Size'}>
                      <Select.Option value="less than 10">
                        less than 10
                      </Select.Option>
                      <Select.Option value="10 to 50">10 to 50</Select.Option>
                      <Select.Option value="50 to 100">50 to 100</Select.Option>
                      <Select.Option value="more than 100">
                        more than 100
                      </Select.Option>
                    </Select>,
                  )}
                </Form.Item>

                <Form.Item>
                  {getFieldDecorator('sendEmailUpdates', {})(
                    <Checkbox>Enable email updates</Checkbox>,
                  )}
                </Form.Item>

                <Button
                  className={'custom'}
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  Sign up
                </Button>

                <div className={'post-text'}>
                  <p>By signing up, you agree to Buzzz's</p>
                  <p>
                    <a target={'_blank'} href={TERMS_CONDITION}>
                      Terms of Conditions
                    </a>{' '}
                    and{' '}
                    <a target={'_blank'} href={PRIVACY_POLICY}>
                      Privacy Policy
                    </a>
                  </p>
                </div>
              </Form>
            </Card>
          </div>
        </div>
        <div className="selected-plan">
          <h3>Your Plan</h3>
          <Card bordered={false}>
            <img src={details.image_url_selected} alt="plan" />
            <h4>
              {details.heading}
              <span>.</span>
            </h4>
            <p>{details.organization_type}</p>
            {pIsCustom ? (
              <div className={'price'}>Say Hi!</div>
            ) : (
              <div className={'price'}>
                ${price} <span>p/m</span>
              </div>
            )}
          </Card>
          <p className={'link'} onClick={this.props.changePlan}>
            Change Plan
          </p>
        </div>
      </div>
    );
  }
}

export default Form.create()(CreateAccountLayout);
