import React from 'react';
import { PRIVACY_POLICY, TERMS_CONDITION, TENTWENTY } from '@constants/links';
// TODO: CHANGE IMAGE PATHS

export default () => (
  <div className="footer">
    <div className="footer-element">
      <p>© 2019 Buzzz. All rights reserved.</p>
      <p className={'light'}>
        <a target={'_blank'} href={TERMS_CONDITION}>
          Terms
        </a>{' '}
        and{' '}
        <a href={PRIVACY_POLICY} target={'_blank'}>
          Privacy Policy
        </a>
      </p>
    </div>
    <div className="footer-element shift-right">
      <a target={'_blank'} href={TENTWENTY}>
        <img src="/images/tentwenty.svg" alt="tentwenty.me" />
      </a>
    </div>
  </div>
);
