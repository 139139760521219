import React, { Component } from 'react';
import {Button, Dropdown, Menu, notification, Steps} from 'antd';

import AddWebsite from '@layouts/AddWebsite/subcomponent/AddWebsite';
import Verification from '@layouts/AddWebsite/subcomponent/Verification';
import SetupAction from '@layouts/AddWebsite/subcomponent/SetupAction';
import AllSet from './AllSet';

import './SetupLayout.scss';
import commonApiService from '@apiService';
import Footer from '@components/Footer';

class SetupLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: -1,
      dataStore: null,
    };
  }

  logoutUser = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('subscription_id');
    localStorage.removeItem('owner');
    localStorage.removeItem('super_user');
    localStorage.removeItem('ping_my_domain');
    localStorage.removeItem('follow_up_status_general');
    localStorage.removeItem('follow_up_status');
    localStorage.removeItem('subscription_expiry_date');
    localStorage.removeItem('is_plan_expired');
    localStorage.removeItem('trial_expires_in');
    // localStorage.clear();
    //
    // this.props.actions.logoutUser();
    commonApiService
      .post('/logout', { device_token: localStorage.getItem('fcm_token') })
      .then(
        successResult => {
          localStorage.removeItem('fcm_token');
        },
        errorResult => {
          localStorage.removeItem('fcm_token');
        },
      );

    this.props.auth2 && this.props.auth2.signOut().then(function() {});
    //send a request to logout the user involved
    this.setState(
      {
        logged_in: false,
        super_user: false,
      },
      () => {
        window.messaging &&
          window.messaging.getToken().then(function(newtoken) {
            localStorage.setItem('fcm_token', newtoken);
          });
        this.props.history.push('/signin');
      },
    );
  };

  prefetch = website => {
    let params = {};
    let step = -1;
    if (website) {
      params = {
        domain_key: website.key,
      };
      step = 1;
    } else {
      step = 0;
    }

    commonApiService
      .list('/web/domains/prefetch', params)
      .then(successResult => {
        let url = {};
        if (website) {
          url = new URL(website.url);
        }
        this.setState({
          step: step,
          dataStore: {
            domain_id: website ? website.id : null,
            key: successResult.data.key,
            script: successResult.data.script,
            display_name: website && website.title,
            url: {
              prefix: url && url.protocol ? url.protocol + '//' : null,
              link: url ? url.hostname + url.pathname : null,
            },
          },
        });
      })
      .catch(errorResult => {
        console.log(errorResult);
        this.setState({
          loading: false,
        });
      });
  };

  changeWebsite = () => {
    commonApiService
      .list('/web/domains', {})
      .then(successResult => {
        const websites = successResult.data.companies;
        if (websites.length === 1 && websites[0].verified && websites[0].forms.length === 0) {
          notification.open({
            message: 'info',
            description: 'Website is already added! Please continue to add forms',
            className: 'notification-neutral',
          });
        }else{
          this.setState({
            step: 0,
          });
        }
      }).catch(errorResult => {
       this.props.history.push('signin');
      });;
  };

  goToVerification = values => {
    this.setState({
      step: 1,
      dataStore: values,
    });
  };

  showOptions = values => {
    this.setState({
      step: 2,
      dataStore: values,
    });
  };

  componentWillMount() {
    document.title = 'Setup | Buzzz.';
    commonApiService
      .list('/web/domains', {})
      .then(successResult => {
        const websites = successResult.data.companies;
        if (websites.length === 1) {
          const website = websites[0];
          if (website.verified && website.forms.length === 0) {
            this.setState({
              step: 2,
              dataStore: {
                domain_id: website.id,
              },
            });
          } else if (!website.verified) {
            this.prefetch(website);
          } else {
            this.allSet();
          }
        } else if (websites.length > 1) {
          this.allSet();
        } else {
          this.prefetch();
        }

        document.getElementById('overlay').style.opacity = 0;
        setTimeout(() => {
          document.getElementById('overlay').style.display = 'none';
        }, 1000);
      })
      .catch(errorResult => {
        this.props.history.push('signin');
      });
  }

  allSet = () => {
    this.setState({
      step: 3,
    });
  };

  render() {
    const { step, dataStore } = this.state;
    const menu = (
      <Menu>
        <Menu.Item>
          <a onClick={this.logoutUser} href className="logout">
            Logout
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className={'init-setup auth'}>
        <div className="white-header">
          <div className={'container'}>
            <div className="logo-container">
              <img src={'/images/buzzz_black.svg'} alt="" />
            </div>
            <div className="extra mobile-hide custom-ant-step">
              <Steps size={'small'} current={step}>
                <Steps.Step title="Install Buzzz on your site" />
                <Steps.Step title="Tracking ID verification" />
                <Steps.Step title="Add forms" />
              </Steps>
            </div>
          </div>
          <Dropdown
            getPopupContainer={() => document.getElementById('dropdown-zone')}
            overlay={menu}
            placement={'bottomRight'}
            trigger={['click']}
            overlayClassName="profile-dropdown"
          >
            <Button
              className="profile-btn"
              type="default"
              size="large"
              shape="circle"
            >
              <span className="icon-user" />
            </Button>
          </Dropdown>
        </div>
        <div className="main container typo add-domain">
          <div className={'steps-wrap desktop-hide'}>
            <Steps size={'small'} current={step}>
              <Steps.Step title="Install Buzzz on your site" />
              <Steps.Step title="Tracking ID verification" />
              <Steps.Step title="Add forms" />
            </Steps>
          </div>
          {
            {
              0: (
                <AddWebsite
                  nextStep={this.goToVerification}
                  dataStore={dataStore}
                />
              ),
              1: (
                <Verification
                  prevStep={this.changeWebsite}
                  nextStep={this.showOptions}
                  dataStore={dataStore}
                  parentSetup={true}
                />
              ),
              2: <SetupAction nextStep={this.allSet} dataStore={dataStore} parentSetup={true}/>,
              3: <AllSet />,
            }[step]
          }
        </div>

        <Footer />
      </div>
    );
  }
}

export default SetupLayout;
