import React, { Component } from 'react';
import { Affix, Button, Tooltip, Row, Col, Avatar, Modal, Input, notification, Icon } from 'antd';
// import { Link } from 'react-router-dom';
import commonApiService from '@apiService';
import './WebsiteLayout.scss';

import AddFormModal from '../AddForm/subcomponents/AddFormModel';
import WebsiteInfo from './subcomponents/WebsiteInfo';
import WebsiteForm from './subcomponents/WebsiteForms';
import WebsiteUsers from './subcomponents/WebsiteUsers';

class WebsiteLayout extends Component {
  constructor(props) {
    super(props);
    let fullAccess = false;
    if (localStorage && localStorage.user) {
      let user = JSON.parse(localStorage.user);
      let owner = localStorage.owner === 'true';
      fullAccess = owner ? true : user.access_level === 'FullAccess';
    }

    this.state = {
      domain: {},
      fullAccess: fullAccess,
      deleteWebsite: {
        visible: false,
        error : false
      },
      allowed: true,
      loading: false
    };
  }

  componentWillMount() {
    this.getDomainDetails();
  }

  getDomainDetails = () => {
    this.domainId = this.props.match.params.domain_id;
    const params = {
      id: this.domainId,
    };
    commonApiService
      .list('/web/domains', params)
      .then(successResult => {

        if(!successResult.data.success){
          this.props.history.push('/view-website');
          return;
        }

        this.setState(
          {
            domain: successResult.data.domain,
            users: successResult.data.users,
            allowed: successResult.data.usage_limit.allowed
          },
          () => {
            document.title = `${this.state.domain.display_name} | Buzzz.`;
          },
        );
        this.usageLimit = successResult.data.usage_limit;
      })
      .catch(errorResult => {
        if(errorResult.errorCode === 404){
          this.props.history.push('/view-website');
        }
      });
  };

  handleShowAddForm = () => {
    this.refs.add_form_modal.showAddForm();
  };

  handleShowWebsiteUsers = () => {
    this.refs.assign_user_modal.showUserList();
  };


  confirmDelete = () => {
    const domainName = this.refs.deleteConfirmInput && this.refs.deleteConfirmInput.state.value;
    console.log(domainName);
    const deleteWebsiteState = this.state.deleteWebsite;

    if(domainName === this.state.domain.display_name){
      deleteWebsiteState.error = false;
      // delete api
      this.setState({
        loading: true,
        deleteWebsite: deleteWebsiteState
      });
      commonApiService
        .put('/web/domains/domain_discard', { id: this.state.domain.id})
        .then(successResult => {
          notification.open({
            message: 'Website Deleted!',
            description: "Website Deleted",
            className: 'notification-neutral'
          });
          this.setState({
            loading: false
          });
          setTimeout(() => {
            this.props.history.push('/view-website');
          }, 2000)
        })
        .catch(errorResult => {
          notification.open({
            message: 'Error!',
            description: "Something went wrong!",
            className: 'notification-error'
          });
        })
    }else{
      deleteWebsiteState.error = true;
      this.setState({
        deleteWebsite: deleteWebsiteState
      })
    }

  }

  render() {
    let usersDOM = [];

    if (this.state.users && this.state.users.length > 0) {
      const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
      const userCount =
        this.state.users.length < 4 ? this.state.users.length : 4;
      for (let j = 0; j < userCount; j++) {
        const user = this.state.users[j];
        if (user.avatar) {
          usersDOM.push(
            <div key={j} className={'user-profile-pic'}>
              <Avatar src={user.avatar} />
            </div>,
          );
        } else {
          const initials =
            user.first_name[0].toUpperCase() + user.last_name[0].toUpperCase();
          const color =
            colorList[
              (user.first_name[0].charCodeAt() +
                user.last_name[0].charCodeAt()) %
                4
            ];
          usersDOM.push(
            <div key={j} className={'user-profile-pic'}>
              <Avatar style={{ backgroundColor: color, color: '#fff' }}>
                {initials}
              </Avatar>
            </div>,
          );
        }
      }
      if (this.state.users.length > 4) {
        usersDOM.push(<p>+{this.state.users.length - 4}</p>);
      }
    }

    return (
      <>
        <Affix offsetTop={localStorage.getItem('appInstalled') === "true" ? 0 : 48}>
          <div className="headerbar headerbar-dashboard">
            <div className="headerbar-block breadcrumb left">
              <div className="breadcrumb-title">
                {this.state.domain && this.state.domain.display_name}
              </div>
              <div className="breadcrumb-subtitle">Admin</div>
            </div>
            <div className="headerbar-block right">
              <Button.Group>
                <div className="user-images">{usersDOM}</div>
                <Button
                  className={'no-bg mobile-hide'}
                  size="large"
                  type="default"
                  onClick={this.handleShowWebsiteUsers}
                >
                  Assign User
                </Button>
                <Tooltip title={'Add new form to this website'}>
                  <Button
                    className="no-bg new-form-btn mobile-hide"
                    size="large"
                    type="default"
                    hidden={this.fullAccess  || !this.state.allowed}
                    onClick={this.handleShowAddForm}
                  >
                    + New Form
                  </Button>
                </Tooltip>
              </Button.Group>
            </div>
            <div className="clear-fix" />
          </div>
        </Affix>
        <main className="main-container">
          <Button.Group className={'desktop-hide'}>
            <Button
              className={'no-bg'}
              size="large"
              type="default"
              onClick={this.handleShowWebsiteUsers}
            >
              Assign User
            </Button>
            <Tooltip title={'Add new form to this website'}>
              <Button
                className="no-bg new-form-btn"
                size="large"
                type="default"
                hidden={this.fullAccess  || !this.state.allowed}
                onClick={this.handleShowAddForm}
              >
                + New Form
              </Button>
            </Tooltip>
          </Button.Group>
          <div className="domain-detail-container">
            <Row gutter={16}>
              <Col md={13} sm={24}>
                <WebsiteInfo domain={this.state.domain} refresh={this.getDomainDetails}/>
              </Col>
              <Col md={11} sm={24}>
                <div className="website-form">
                  <WebsiteForm domain={this.state.domain} refresh={this.getDomainDetails} />
                </div>
              </Col>
            </Row>
          </div>

          <div className={'delete-website'}>
            <Button type={'danger'} size={'large'} onClick={() => { this.setState({deleteWebsite: { visible: true, error: false}})}}>Delete this domain</Button>
          </div>
        </main>
        <AddFormModal
          history={this.props.history}
          ref="add_form_modal"
          domainId={this.state.domain.id}
          domainName={this.state.domain.display_name}
        />
        <WebsiteUsers
          ref={'assign_user_modal'}
          domainId={this.state.domain.id}
          assignedUsers={this.state.users}
          onClose={this.getDomainDetails}
        />
        <Modal
          className="modal-confirm"
          onCancel={() => {
            this.setState({ deleteWebsite: { visible: false, error: false} });
          }}
          visible={this.state.deleteWebsite.visible}
          footer={false}
          centered={true}
        >
          <h2>Are you absolutely sure?</h2>

          <p>Once you delete a website, there is no going back.</p>
          <p>This will permanently delete website, forms, assigned users and prospect data</p>
          <p>Please type in the name of the website to confirm.</p>
          <Input ref={'deleteConfirmInput'} placeholder={'YOUR WEBSITE NAME'} addonAfter={this.state.deleteWebsite.error && <Icon type="exclamation-circle" />}/>
          <Button.Group>
            <Button
              size="large"
              type="danger"
              onClick={this.confirmDelete}
              loading={this.state.loading}
            >
              Delete this Domain
            </Button>
          </Button.Group>
        </Modal>
      </>
    );
  }
}

export default WebsiteLayout;
