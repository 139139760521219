import React, { Component } from 'react';

import ActivateForm from '@layouts/AddForm/subcomponents/ActivateForm';
import ExtractedForms from '@layouts/AddForm/subcomponents/ExtractedForms';
import SetupActionList from './ActionList';

class SetupAction extends Component {
  state = {
    choice: 0,
    formStep: 0,
    dataStore: {},
  };

  setChoice = selectedChoice => {
    this.setState({
      choice: selectedChoice,
    });
  };

  goBack = () => {
    this.setState({
      formStep: 0
    })
  };

  setExtractedForms = dataStore => {
    this.setState({
      formStep: 1,
      dataStore: dataStore,
    });
  };

  componentWillMount() {
    this.setState({
      dataStore: this.props.dataStore,
    });
    window.scrollTo(0, 0);
  }

  render() {
    switch (this.state.choice) {
      case 0:
        return (
          <div className={'setup-action'}>
            <h3>Let’s activate your form now.</h3>
            <SetupActionList setChoice={this.setChoice} />
          </div>
        );
      case 1:
        switch (this.state.formStep) {
          case 0:
            return (
              <div className={'form-add-action'}>
                <h3>Let’s activate your form now.</h3>
                <ActivateForm
                  dataStore={this.state.dataStore}
                  nextStep={this.setExtractedForms}
                  parentSetup={this.props.parentSetup}
                />
              </div>
            );
          case 1:
            return (
              <ExtractedForms
                dataStore={this.state.dataStore}
                nextStep={this.props.nextStep}
                goBack={this.goBack}
                parentSetup={this.props.parentSetup}
              />
            );
          default:
            return null;
        }
      default:
        return null;
    }
  }
}

export default SetupAction;
