import React, { Component } from 'react';
import { Alert, Badge, Icon, Popover } from 'antd';
import moment from 'moment';

class AlertsLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert_list: [],
    };
  }

  startTimer = domain_id => {
    return setInterval(() => {
      let alert_list = this.state.alert_list;
      for (let i = 0; i < alert_list.length; i++) {
        if (alert_list[i].domain_id === domain_id) {
          alert_list[i].downtime++;
          this.setState({
            alert_list: alert_list,
          });
          break;
        }
      }
    }, 1000);
  };

  domainUp = data => {
    let alert_list = this.state.alert_list;
    for (let i = 0; i < alert_list.length; i++) {
      if (alert_list[i].domain_id === data.domain_id) {
        clearInterval(alert_list[i].interval);
        alert_list.splice(i, 1);
        this.setState({
          alert_list: alert_list,
        });
        break;
      }
    }
  };

  secToHrMinSec = totalSec => {
    let days = parseInt(parseInt(totalSec) / (24 * 60 * 60));
    let remainderSeconds = parseInt(totalSec) % (24 * 60 * 60);

    let hrs = parseInt(parseInt(remainderSeconds) / (60 * 60));
    remainderSeconds = parseInt(remainderSeconds) % (60 * 60);

    let mins = parseInt(parseInt(remainderSeconds) / 60);
    remainderSeconds = parseInt(remainderSeconds) % 60;

    let secs = parseInt(remainderSeconds) % 60;

    days = days < 10 ? '0' + days : days;
    hrs = hrs < 10 ? '0' + hrs : hrs;
    mins = mins < 10 ? '0' + mins : mins;
    secs = secs < 10 ? '0' + secs : secs;

    days = days <= 0 ? '' : days + ':';

    return days + hrs + ':' + mins + ':' + secs;
  };

  domainDown = data => {
    let newDomain = true;
    let alert_list = this.state.alert_list;

    for (let i = 0; i < alert_list.length; i++) {
      if (alert_list[i].domain_id === data.domain_id) {
        newDomain = false;
        break;
      }
    }

    if (newDomain) {
      let time = 'Today ' + moment().format('HH:mm');
      let downtime = 0;

      if (data.since) {
        let day = moment(data.since * 1000).isSame(moment(), 'day')
          ? 'Today'
          : moment(data.since * 1000).format('DD/MM/YYYY');
        time = day + ' ' + moment(data.since * 1000).format('HH:mm');
        downtime = moment().unix() - data.since;
      }

      let newAlert = {
        domain_id: data.domain_id,
        title: data.domain_title + ' is Down',
        down: true,
        interval: this.startTimer(data.domain_id),
        downtime: downtime,
        time: time,
      };
      alert_list.push(newAlert);
      this.setState({
        alert_list: alert_list,
      });
    }
  };

  componentWillReceiveProps(nextprops) {
    if (this.props !== nextprops) {
      if (
        nextprops.verified_domain_data_success !== null &&
        nextprops.verified_domain_data_success !==
          this.props.verified_domain_data_success
      ) {
        let domains = nextprops.verified_domain_data_success.companies;

        domains.forEach((domain, i) => {
          if (domain.status.type === 'WEBSITE_DOWN') {
            this.domainDown({
              domain_id: domain.id,
              domain_title: domain.title,
              since: domain.status.since,
            });
          }
        });
      }
    }
  }

  render() {
    let domain_alerts =
      this.state.alert_list &&
      this.state.alert_list.map((alert, i) => {
        const title = (
          <>
            <span className="title">
              <Icon type="warning" theme="filled" /> {alert.title}
            </span>
            <span className="sub-title">{alert.time}</span>
          </>
        );

        let duration = (
          <>
            <span className="subtext">Downtime</span>
            <span className="maintext">
              {this.secToHrMinSec(alert.downtime)}
            </span>
          </>
        );
        if (i === 0) {
          return null;
        }

        return (
          <Alert key={i} message={title} description={duration} type="error" />
        );
      });

    let alertDom = null;

    if (this.state.alert_list.length > 0) {
      const alert = this.state.alert_list[0];
      const title = (
        <>
          <span className="title">
            <Icon type="warning" theme="filled" /> {alert.title}
          </span>
          <span className="sub-title">{alert.time}</span>
        </>
      );

      let duration = (
        <>
          <span className="subtext">Downtime</span>
          <span className="maintext">{this.secToHrMinSec(alert.downtime)}</span>
        </>
      );

      alertDom = (
        <div className={'alert-wrapper'}>
          <Alert message={title} description={duration} type="error" />
          {this.state.alert_list.length > 1 ? (
            <Popover placement={'bottomRight'} content={domain_alerts}>
              <Badge count={this.state.alert_list.length - 1} />
            </Popover>
          ) : null}
        </div>
      );
    }

    return (
      <div className="global-error mobile-hide">
        {this.props.network_down ? (
          <Alert
            message={<span className="title">Network Error</span>}
            description="We have detected that you are not connected to the internet currently. Please check your network connection and try again"
            type="warning"
          />
        ) : (
          alertDom
        )}
      </div>
    );
  }
}

export default AlertsLayout;
