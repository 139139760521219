import React, { Component } from 'react';
import { Button, Form, Input, notification } from 'antd';
import MobileInput from '@components/common/MobileNumberLayout';
import commonApiService from '@apiService';

const FormItem = Form.Item;
function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class ContactUsLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    let user = localStorage.getItem('user')
    user = user ? JSON.parse(user) : {};
    this.props.form.setFieldsValue({
      name: `${user.first_name} ${user.last_name}`,
      email: user.email
    });
    this.refs.mobileInput.updateInput(user.contact);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      const phoneNumberData = this.refs.mobileInput.getPhoneNumber();
      if (!err && phoneNumberData) {
        commonApiService
          .post('/web/contact_us', {
            name: values.name,
            email: values.email,
            contact_number: '',
            message: values.message,
          })
          .then(successResult => {
            notification.open({
              message: 'Thank You!',
              description:
                'We received your query! we will get back to you soon',
              className: 'notification-success',
            });
            this.props.handleCancel();
          })
          .catch(errorResult => {
            notification.open({
              message: 'Error',
              description: 'Something went wrong! Please try again',
              className: 'notification-error',
            });
          });
      }
    });
  };

  changeContactNumber = (status, value, countryData, number, id) => {
    let phone_number = {
      status: status,
      country_code: countryData.dialCode,
      phone_number: value,
      country: countryData.iso2,
    };
    this.setState({
      phone_number: phone_number,
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem>
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Please input your Email address!',
              },
            ],
          })(<Input placeholder=" " />)}
          <div className="floating-label">Enter your name</div>
        </FormItem>
        <FormItem>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: 'The input is not valid Email address!',
              },
              {
                required: true,
                message: 'Please input your Email address!',
              },
            ],
          })(<Input placeholder=" " />)}
          <div className="floating-label">Enter your email</div>
        </FormItem>
        <MobileInput ref={'mobileInput'} />
        <FormItem>
          {getFieldDecorator('message', {
            rules: [
              {
                required: true,
                message: 'Please input Message!',
              },
              {
                min: 20,
                message: 'minimum 20 characters!',
              },
            ],
          })(<Input.TextArea placeholder=" " />)}
          <div className="floating-label">{ this.props.callBack ? `Let us know what you'd like to talk about...` : 'Enter your Message'}</div>
        </FormItem>
        <FormItem>
          <Button
            size="large"
            disabled={hasErrors(getFieldsError())}
            type="primary"
            className={'has-width'}
            htmlType="submit"
            loading={this.state.loading}
          >
            {this.props.callBack ? 'Submit call back request' : 'Send' }
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(ContactUsLayout);
