import React from 'react';
import './GuideLayout.scss';
import { Button } from "antd";
import arrowRight from './arrowRight.svg';
import arrowLeft from './arrowLeft.svg';
import icClose from './ic_close.svg';
import icNotific from './ic_green_notification.svg';
import icProfile from './ic_green_profile.svg';
import icWebsite from './ic_green_website.svg';

class GuideLayout extends  React.Component{

    state = {
        step: 1,
        resize: Math.random(),
        title: '',
        message: '',
        focusStyle: {},
        guideStyle: {},
        arrowStyle: {},
        arrowImage: arrowRight,
    };

    updateOnResize = () => {
        setTimeout(() => {
            this.setStep(this.state.step)
        }, 300);
    };

    componentDidMount() {
        window.addEventListener("resize", this.updateOnResize);
        this.setStep(1);
        this.updateOnResize();
    }
    
    setStep = (step) => {
        switch (step) {
            case 1:
                this.websiteGuide();
                break;
            case 2:
                this.notificationGuide();
                break;
            case 3:
                this.userSettingGuide();
                break;
            default:
                this.props.closeGuide();
        }
    };

    nextStep = () => {
        this.setStep(this.state.step + 1);
    };

    websiteGuide = () => {
        const block = document.getElementById('home-graph-0');
        const val = block.getBoundingClientRect();

        const notification = document.getElementById('notification-btn');
        const notificationVal = notification.getBoundingClientRect();

        this.setState({
            image: icWebsite,
            focusStyle : {
                width: val.width,
                height: val.height,
                top: val.top,
                left: val.left,
                borderRadius: '8px'
            },
            guideStyle : {
                top: 50 + notificationVal.top,
                right: 0,
            },
            arrowImage: arrowLeft,
            arrowStyle: {
                left: val.right + 10,
                top: val.bottom - 200,
            },
            step: 1,
            resize: Math.random(),
            title: 'Your website',
            message: 'This is the focus of your dashboard. See everything coming in, when it comes in, and your response time to each and every lead.'
        })
    }

    notificationGuide = () => {
        const block = document.getElementById('notification-btn');

        const val = block.getBoundingClientRect();
        this.setState({
            image: icNotific,
            focusStyle : {
                width: val.width,
                height: val.height,
                top: val.top,
                left: val.left,
                borderRadius: '50%'
            },
            guideStyle : {
                top: 50 + val.top,
                right: 0,
            },
            arrowImage: arrowRight,
            arrowStyle: {
                left: val.left - 80,
                top: val.top + (val.height / 2),
            },
            step: 2,
            resize: Math.random(),
            title: 'Notifications',
            message: 'This will alert and update you with all activity taking place on your Buzzz account. From new leads to status updates, this is everything you need to know.'
        })
    }

    userSettingGuide = () => {
        const block = document.getElementById('profile-btn');

        const val = block.getBoundingClientRect();
        this.setState({
            image: icProfile,
            focusStyle : {
                width: val.width,
                height: val.height,
                top: val.top,
                left: val.left,
                borderRadius: '50%'
            },
            guideStyle : {
                top: 50 + val.top,
                right: 0,
            },
            arrowImage: arrowRight,
            arrowStyle: {
                left: val.left - 80,
                top: val.top + (val.height / 2),
            },
            step: 3,
            resize: Math.random(),
            title: 'Your profile',
            message: 'Here you can update your contact details, see what forms and websites you are currently assigned to, but most importantly, upload the best selfie you can take.'
        })
    }

    render() {

        const {step, title, message, image, arrowImage} = this.state;
        return (
            <div className={'guide-notify'}>
                <div className={'guide-overlay'}>
                    <div className="focus" style={this.state.focusStyle}>
                        <img style={this.state.arrowStyle} src={arrowImage} alt=""/>
                    </div>
                </div>
                <div id={'guide-box'} className="guide-overlay-desc" style={this.state.guideStyle}>
                    <Button className={'close-btn'} shape={'circle'} onClick={this.props.closeGuide}><img src={icClose} alt=""/></Button>
                    <div className={'tutorial'}>
                        <div className={'left'}>
                            <img src={image} alt=""/>
                            <div className="steps">
                                <span onClick={() => {this.setStep(1)}} className={ step === 1 ? 'step active' : 'step'}/>
                                <span onClick={() => {this.setStep(2)}} className={ step === 2 ? 'step active' : 'step'}/>
                                <span onClick={() => {this.setStep(3)}} className={ step === 3 ? 'step active' : 'step'}/>
                            </div>
                        </div>
                        <div className={'right'}>
                            <h3>{title}</h3>
                            <p>{message}</p>
                            <div>
                                <Button type={step === 3 ? 'primary' : 'default'} size={'small'} className={'has-width'} onClick={() => { this.setStep(step+1)}}>
                                    {step === 3 ? 'Done' : 'Next'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default GuideLayout