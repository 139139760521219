import React , { Component } from 'react';


class HelpTip extends Component{

  render() {

    const { help , toggleHelp, helpText = 'Help' } = this.props;
    if(help) {
      return (
        <>
          <span className={'help-tip close'}>{helpText}</span>
          <span onClick={toggleHelp} className={'close'}>&times;</span>
        </>
      )
    }else{
      return  ( <span className={'help-tip'} onClick={toggleHelp}>{helpText}</span> )
    }
  }
}

export default HelpTip;