import React, { Component } from 'react';
import { Button, Card, Collapse } from 'antd';

import SingleForm from './SingleForm';
import commonApiService from '@apiService';

const Panel = Collapse.Panel;

class WebsiteForms extends Component {
  state = {
    forms: [],
    activePanelList: ['0'],
  };

  getAllForms = () => {
    let forms = this.state.forms.map((form, index) => {
      return form.active
        ? this.refs['singleForm' + index].setFormFieldData()
        : null;
    });
    forms = forms.filter(Boolean);

    this.registerDomainForms(forms);
  };

  registerDomainForms = forms => {
    const params = {
      domain_id: this.dataStore.domain_id,
      forms: forms,
    };

    console.log(params);

    commonApiService
      .post('/web/domains/forms', params)
      .then(successResult => {
        console.log('DomainFormRegisterSuccess', successResult);
        this.props.nextStep();
      })
      .catch(errorResult => {
        console.log('DomainFormRegisterFailure', errorResult);
      });
  };

  render() {
    const { forms, id } = this.props.domain;

    const FormBlock =
      forms &&
      forms.map((form, index) => {
        return (
          <Panel
            header={
              <>
                <span className="panel-title">{form.name}</span>
                <span className="float-right">
                  <Button
                    icon="down"
                    shape="circle"
                    className={'no-bg-strict'}
                  />
                </span>
              </>
            }
            showArrow={false}
            key={index}
          >
            <div className="single-form-wrap one">
              <SingleForm
                websiteForm={form}
                key={index}
                formKey={index}
                domainId={id}
                refresh={this.props.refresh}
              />
            </div>
          </Panel>
        );
      });

    return (
      <Card
        id="view_form"
        title={'Website Forms'}
        className={'form-add-action'}
        bordered={false}
      >
        <Collapse defaultActiveKey={['0']} accordion={true}>
          {FormBlock}
        </Collapse>
      </Card>
    );
  }
}

export default WebsiteForms;
