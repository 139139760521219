import { createStore, applyMiddleware } from 'redux';
import RootReducer from './../reducers/RootReducer';
import thunk from 'redux-thunk';

import { composeWithDevTools } from 'redux-devtools-extension';

function configureStore(preloadedState){
    return createStore(
        RootReducer,
        preloadedState,
        composeWithDevTools(
            applyMiddleware(
                thunk
            )
        )
    )
}

const store = configureStore();

export default store;