import React, { Component } from 'react';
import {Affix, Button, Steps} from 'antd';

import commonApiService from '@apiService';
import { parseQuery } from '@queryHelper';
import './AddWebsiteLayout.scss';

import AddWebsite from './subcomponent/AddWebsite';
import Verification from './subcomponent/Verification';
import SetupAction from './subcomponent/SetupAction';
import {Link} from "react-router-dom";

class AddWebsiteLayout extends Component {
  state = {
    step: -1,
    verifyFlag: false,
    dataStore: {},
  };

  prefetch = website => {
    let params = {};
    let url = {};
    let step = -1;
    let verifyFlag = false;
    if (website) {
      url = new URL(website.url);
      params = {
        domain_key: website.key,
      };
      step = 1;
      verifyFlag = true;
    } else {
      step = 0;
    }

    commonApiService
      .list('/web/domains/prefetch', params)
      .then(successResult => {
        this.setState({
          step: step,
          dataStore: {
            domain_id: website ? website.id : null,
            key: successResult.data.key,
            script: successResult.data.script,
            url: {
              prefix: url ? url.protocol + '//' : null,
              link: url ? url.hostname + url.pathname : null,
            },
            verifyFlag: verifyFlag,
          },
        });
      })
      .catch(errorResult => {
        console.log(errorResult);
      });
  };

  changeWebsite = () => {
    this.setState({
      step: 0,
    });
  };

  goToVerification = values => {
    this.setState({
      step: 1,
      dataStore: values,
    });
  };

  showOptions = values => {
    this.setState({
      step: 2,
      dataStore: values,
    });
  };

  componentWillMount() {
    if (this.props.location.search) {
      const query = parseQuery(this.props.location.search);
      this.prefetch(query);
    } else {
      this.prefetch();
    }
  }

  showAllSet = dataStore => {
    this.setState(
      {
        step: 3,
        dataStore: dataStore
      }
    );
  };

  allSet = () => {
    this.props.history.push('/dashboard/' + this.state.dataStore.domain_id);
  };

  render() {
    let { dataStore, step} = this.state;
    let { verifyFlag, url } = dataStore;

    return (
      <>
        <Affix offsetTop={localStorage.getItem('appInstalled') === "true" ? 0 : 48}>
          <div className="headerbar headerbar-dashboard">
            <div className="headerbar-block breadcrumb left">
              <div className="breadcrumb-title">
                {verifyFlag ? url.prefix + url.link : 'My Website'}
              </div>
              <div className="breadcrumb-subtitle">
                {verifyFlag ? 'Verify Website' : 'Add New Website'}
              </div>
            </div>
            <div className="headerbar-block right" />
            <div className="clear-fix" />
          </div>
        </Affix>
        <main className={'main-container add-domain typo'}>
          <div className={'add-domain-steps custom-ant-step'}>
            <Steps size={'small'} current={this.state.step}>
              <Steps.Step title="Install Buzzz on your site" />
              <Steps.Step title="Tracking ID verification" />
              <Steps.Step title="Add forms" />
            </Steps>
          </div>
          {
            {
              0: (
                <AddWebsite
                  nextStep={this.goToVerification}
                  dataStore={this.state.dataStore}
                />
              ),
              1: (
                <Verification
                  prevStep={this.changeWebsite}
                  nextStep={this.showOptions}
                  dataStore={this.state.dataStore}
                  verifyFlag={verifyFlag}
                />
              ),
              2: (
                <SetupAction
                  nextStep={this.showAllSet}
                  dataStore={this.state.dataStore}
                />
              ),
              3: (
                <div className={'all-set'}>
                  <div className="all-set-block">
                    <img src={'/images/domains/set.svg'} alt="set" />
                    <h3 className={'all-set-h3'}>Successfully completed adding Website and Form.</h3>
                    <Button  type={'primary'}  size={'large'} onClick={this.allSet} >
                      See the added website & form
                    </Button>
                  </div>
                </div>
              )
            }[step]
          }
        </main>
      </>
    );
  }
}

export default AddWebsiteLayout;
