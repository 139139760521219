import React from 'react';
import { Route, Switch } from 'react-router-dom';

import MainLayoutContainer from '@containers/MainLayoutContainer';
import AuthLayout from '@layouts/Auth/AuthLayout';
import SignUpLayout from '@layouts/Auth/SignUp/SignUpLayout';
import SetupLayout from '@layouts/Setup/SetupLayout';

const routes = (
  <Switch>
    <Route exact path={'/forgot'} component={AuthLayout} />
    <Route exact path={'/signin'} component={AuthLayout} />
    <Route
      exact
      path={'/resetpassword/:validation_key'}
      component={AuthLayout}
    />
    <Route exact path={'/activate/:activation_key'} component={AuthLayout} />
    <Route exact path={'/signup'} component={SignUpLayout} />
    <Route exact path={'/setup'} component={SetupLayout} />
    <Route path="/" component={MainLayoutContainer} />
  </Switch>
);

export default routes;
