import React, {Component} from 'react';
import {Line} from 'react-chartjs-2';

let customTooltips = (chartTooltipId) => {
  return function (tooltip) {
    // Tooltip Element
    let tooltipEl = document.getElementById(chartTooltipId);

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = chartTooltipId;
      tooltipEl.classList.add('chartjs-tooltip');
      tooltipEl.innerHTML = '<table></table>';
      this._chart.canvas.parentNode.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltip.yAlign) {
      tooltipEl.classList.add(tooltip.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    function getBody(bodyItem) {
      return bodyItem.lines;
    }

    // Set Text
    if (tooltip.body) {
      let titleLines = tooltip.title || [];
      let bodyLines = tooltip.body.map(getBody);

      let innerHtml = '<thead>';

      titleLines.forEach(function (title) {
        innerHtml += '<tr><th>' + title + '</th></tr>';
      });
      innerHtml += '</thead><tbody>';

      let currentVal = {};

      bodyLines.forEach(function (body, i) {
        body = body.join();
        let bodyInfo = body.split(':');
        currentVal = bodyInfo[1];
      });

      innerHtml +=
        `<tr>
              <td class="tooltip-body-inner">
                  <div>${currentVal}</div>
              </td>
          </tr>`;
      innerHtml += '</tbody>';

      let tableRoot = tooltipEl.querySelector('table');
      tableRoot.innerHTML = innerHtml;
    }

    let positionY = this._chart.canvas.offsetTop;
    let positionX = this._chart.canvas.offsetLeft;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = (positionX + tooltip.caretX) + 'px';
    tooltipEl.style.top = (positionY + tooltip.caretY) + 'px';
    tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
    tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
    tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
    tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
  }
};

class Graph extends Component {

  constructor(props){
    super(props);
    this.chartTooltipId = Math.floor(100 * Math.random());
  }
  render() {

    const {type, graphs, width, height} = this.props


    let line_graph_data = {
      labels: graphs.labels,
      datasets: [{
        label: 'current',
        borderColor: "black",
        backgroundColor: "black",
        pointRadius: 0,
        data: graphs.data,
        fill: false
      }]
    };

    let line_graph_options = {
      animation: false,
      elements: {
        line: {
          borderWidth: 1.5
        }
      },
      tooltips: {
        enabled: false,
        mode: 'index',
        position: 'nearest',
        intersect: false,
        custom: customTooltips(this.chartTooltipId)
      },
      legend: false,
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            ticks: {
              padding: 0,
              fontColor: "#2a2a2c80",
              fontSize: 10,
              maxRotation: 0,
              minRotation: 0,
              callback: function (label, index, labels) {
                let prevlabel = index !== 0 ? labels[index-1].split(' ')[1] : '';
                let currlabel = labels[index].split(' ')[1];
                return prevlabel !== currlabel  ? currlabel.toUpperCase() : '';
              },
            },
          },
          {
            gridLines: {
              drawBorder: false,
              display: false
            },
            ticks: {
              padding: -10,
              fontColor: "#000000",
              fontSize: 11,
              // maxRotation: 0,
              // minRotation: 0,
              callback: function (label, index, labels) {
                return label.split(' ')[0];
              }
            }
          }
        ],
        yAxes: [{
          type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
          display: true,
          position: 'left',
          ticks: {
            padding: 12,
            fontSize: 11,
            fontColor: "#00000066",
            precision: 0
          },
          gridLines: {
            color: "#F5F5F5",
            drawBorder: false,
            drawTicks: false
          }

        }],
      }
    }


    return (
      <div className="line-graph">
        <Line
          width={width}
          height={height}
          ref={'line_graph' + Math.random()}
          data={line_graph_data}
          options={line_graph_options}
          key={Math.random()}
        />
      </div>
    )
  }
}


export default Graph;