import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';

const SaveButton = ({
  saveLabel,
  loading,
  savingLabel,
  disabled,
  size,
  style,
  icon,
  className,
  label,
  onClick,
}) => (
  <Form.Item label={label}>
    <Button
      type="primary"
      htmlType="submit"
      icon={icon}
      disabled={disabled}
      style={style}
      loading={loading}
      size={size}
      className={className}
      onClick={onClick}
    >
      {loading ? savingLabel : saveLabel || 'Save'}
    </Button>
  </Form.Item>
);

SaveButton.propTypes = {
  cancelLink: PropTypes.string,
  span: PropTypes.number,
  offset: PropTypes.number,
  saveLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  saving: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  icon: PropTypes.string,
};

SaveButton.defaultProps = {
  savingLabel: 'Saving',
};

export default SaveButton;
