import React, { Component } from 'react';
import {Button, Card, Checkbox, notification, Popover} from 'antd';

import SingleForm from './SingleForm';
import commonApiService from '@apiService';
import HelpExtractForm from '@components/common/Help/HelpExtractForm';
import HelpTip from '@components/common/Help/HelpTip';

class ExtractedForms extends Component {
  dataStore = {
    url: {
      prefix: null,
      link: null,
      script: null,
    },
    domain_id: null,
    forms: [],
    warn: false,
  };

  state = {
    forms: [],
    help: false,
    complete: false
  };

  componentWillMount() {
    const { dataStore } = this.props;

    if (dataStore && dataStore.forms) {
      this.dataStore = dataStore;
      if (dataStore.forms.length >= 1) {
        dataStore.forms[0].active = true;
      }
      this.setState({
        forms: dataStore.forms,
      });
    }
    window.scrollTo(0, 0);
  }

  chooseForm = (ev, formIndex) => {
    const forms = this.state.forms;
    forms[formIndex].active = ev.target.checked;

    this.setState({
      forms: forms,
    });
  };

  goBack = () => {
    this.setState({
      help: false
    });
    this.props.goBack();
  };

  nextStep = () => {
    this.setState({
      help: false
    });
    this.props.nextStep(this.dataStore);
  };

  getAllForms = () => {
    let forms = this.state.forms.map((form, index) => {
      return form.active
        ? this.refs['singleForm' + index].setFormFieldData()
        : null;
    });
    forms = forms.filter(Boolean);

    let warn = false;
    for (let i = 0; i < forms.length; i++) {
      warn = warn || forms[i].warn;
    }
    if (!warn) {
      this.registerDomainForms(forms);
    }

    this.setState({
      help: false
    });
  };

  registerDomainForms = forms => {
    const params = {
      domain_id: this.dataStore.domain_id,
      forms: forms,
    };

    commonApiService
      .post('/web/domains/forms', params)
      .then(successResult => {
        notification.open({
          message: 'Success',
          description: successResult.data.msg,
          className: 'notification-success',
        });
        this.props.nextStep(this.dataStore);
      })
      .catch(errorResult => {
        notification.open({
          message: 'Error',
          description: 'Something went wrong! Please try again',
          className: 'notification-error',
        });
      });
  };

  toggleHelp = () => {
    this.setState({
      help: !this.state.help
    })
  };

  render() {
    const { forms, complete } = this.state;

    const FormBlock = forms.map((form, index) => {
      return (
        <div
          className={
            forms.length === 1 ? 'single-form-wrap one' : 'single-form-wrap'
          }
        >
          <h4>Form {index + 1}</h4>
          {/*<div className={'label-badge'}>*/}
          {/*  {' '}*/}
          {/*  <div className={'left-badge'}>*/}
          {/*    <span className={'number'}>1</span>*/}
          {/*  </div>*/}
          {/*  Start by adding your website name and website to see if the link is*/}
          {/*  active.*/}
          {/*</div>*/}
          <div className={'single-form-cover'}>
            <Checkbox
              defaultChecked={index === 0}
              onChange={e => {
                this.chooseForm(e, index);
              }}
            />
            <div
              className={form.active ? 'single-form' : 'single-form inactive'}
            >
              <SingleForm
                ref={'singleForm' + index}
                websiteForm={form}
                key={index}
                formKey={index}
                formUrl={this.dataStore.form_url}
              />
            </div>
          </div>
        </div>
      );
    });

    const getPopupContainerProps = this.props.parentSetup ? {} : {
      getPopupContainer: () => document.getElementById('extract-form-card')
    };

    return (
      <div className={'form-add-action'}>
        <h3>
          {forms.length > 1
            ? `We’ve found ${forms.length} forms :) `
            : 'We’ve found your form :) '}
        </h3>
        {
          complete ? (
            <>
              <img src={'/images/domains/set.svg'} alt="set" />
              <h3>Successfully completed adding Website and Form</h3>
              <Button
                type={'primary'}
                size={'large'}
                onClick={this.nextStep}
              >
                See the added website & form
              </Button>
            </>
          ) : (
            <Popover
              content={<HelpExtractForm/>}
              overlayClassName={'help-popover'}
              {...getPopupContainerProps}
              placement={'right'}
              visible={this.state.help}
            >
              <Card bordered={false} className={this.state.help ? 'move-aside' : ''} id={'extract-form-card'}>
                <h4>We’ve found the following forms {<HelpTip help={this.state.help} toggleHelp={this.toggleHelp}/>}</h4>

                <p className={'light large link'}>{this.dataStore.form_url}</p>

                {FormBlock}

                <div className="submit-box">
                  {/*<p className={'label-badge'}>*/}
                  {/*  {' '}*/}
                  {/*  <div className={'left-badge'}>*/}
                  {/*    <span className={'number'}>2</span>*/}
                  {/*  </div>*/}
                  {/*  Submit your form with dummy information.*/}
                  {/*</p>*/}

                  <Button.Group>
                    <Button
                      onClick={this.goBack}
                      type={'default'}
                      size={'large'}
                    >
                      Go back
                    </Button>
                    <Button
                      type={'primary'}
                      size={'large'}
                      onClick={this.getAllForms}
                    >
                      Complete installation
                    </Button>
                  </Button.Group>
                </div>
              </Card>
            </Popover>
          )
        }
      </div>
    );
  }
}

export default ExtractedForms;
