import React, {Component} from "react";
import {Affix, Button, Collapse, Form, Icon, Input, message, Modal, Radio, Skeleton, Tooltip} from "antd";
import moment from "moment";
import commonApiService from '@apiService';
import arrowDownRed from "./images/arrow_down_red.svg";
import arrowNA from "./images/arrow_na.svg";
import arrowUpGreen from "./images/arrow_up_green.svg";
import arrowDownGreen from "./images/arrow_down_green.svg";
import arrowUpRed from "./images/arrow_up_red.svg";
import {Link} from "react-router-dom";

const FormItem = Form.Item;

const follow_up_status = JSON.parse(localStorage.getItem('follow_up_status'));
const follow_up_status_general = JSON.parse(
  localStorage.getItem('follow_up_status_general'),
);


class ModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      initLoading: true,
      fetchedAll: false,
      visible: false,
      logs: [],
      data: null,
      archived: false,
      add_note: false,
      custom_fields: [],
      page: 1,
    };
    document.title = 'Dashboard | Buzzz.';
  }

  componentWillMount() {
    this.setState({
      visible: false,
      logs: [],
      data: null,
      archived: false,
      add_note: false,
      custom_fields: [],
      page: 1,
    });
  }

  showModal = data => {
    this.setState({ initLoading: true, visible: true,fetchedAll: false, data: data }, () => {
      this.getLogs();
    });
  };

  getLogs = () => {
    let { page } = this.state;
    commonApiService
      .list('/notifications/' + this.state.data.id)
      .then(successResult => {
        const currState = {
          logs: successResult.data.notification.history || [],
          custom_fields: successResult.data.notification.custom_fields,
          archived: successResult.data.notification.is_archived !== 0,
          page: (page += 1),
          initLoading: false
        };
        if(currState.logs && currState.logs.length === 0){
          currState.fetchedAll = true;
        }
        this.setState(currState);
      })
      .catch(errResult => {});
  };

  showMore = () => {
    let { page } = this.state;
    this.setState({
      loading: true
    });
    commonApiService
      .list('/notifications/get_logs', {
        notification_id: this.state.data.id,
        page: this.state.page,
      })
      .then(successResult => {
        if(successResult.data.call_logs && successResult.data.call_logs.length > 0) {
          this.setState({
            logs: [...this.state.logs, ...successResult.data.call_logs],
            page: (page += 1),
            loading: false
          });
        }else{
          message.error('No more results found!');
          this.setState({
            fetchedAll: true,
            loading: false
          });
        }

      })
      .catch(errResult => {});
  };

  archiveEnquiry = () => {
    let url = this.state.archived
      ? '/notifications/unarchive/'
      : '/notifications/archive/';
    commonApiService
      .put(url + this.state.data.id)
      .then(successResult => {
        this.setState({
          archived: !this.state.archived,
        });
      })
      .catch(errResult => {});
  };

  deleteEnqry = () => {
    commonApiService
      .put('/notifications/delete/' + this.state.data.id)
      .then(successResult => {
        this.setState({ visible: false }, this.props.initialise);
      })
      .catch(errResult => {});
  };

  onStatusChange = e => {
    this.setState({
      note_status: e.target.value,
    });
  };

  onNoteChange = e => {
    this.setState({
      note: e.target.value,
    });
  };

  submitNote = () => {
    if (this.state.note && this.state.note_status) {
      commonApiService
        .post('/notifications/notes', {
          notification_id: this.state.data.id,
          note: this.state.note,
          follow_up_status_id: this.state.note_status,
        })
        .then(successResult => {
          this.closeNoteModal();
        })
        .catch(errResult => {});
    }
  };

  closeModal = () => {
    this.setState(
      {
        visible: false,
      }, () => {
        this.props.initialise(true);
      }
    );
  };

  closeNoteModal = () => {
    this.setState(
      {
        add_note: false,
      },
      this.getLogs,
    );
  };

  render() {
    const panels = this.state.logs.length > 0 ? this.state.logs.map((item, index) => {
      return (
        <Collapse.Panel
          key={index}
          header={
            <Skeleton paragraph={false} active loading={this.state.initLoading}>
              <div className="collapse-header">
                <div
                  className={
                    follow_up_status[item.follow_up_status_id].type +
                    ' history-block'
                  }
                >
                  <span
                    className={
                      'followup-icon icon-' +
                      follow_up_status[item.follow_up_status_id].web_icon
                    }
                  />
                  <span className='follow-up-title'>{follow_up_status[item.follow_up_status_id].title} {' '}
                    <span className="capsule">
                      {item.user_details.first_name}
                  </span>
                  </span>
                  <span className={'timestamp'}>{moment(item.created_at).format('hh:mm A')}</span>
                </div>

                <span className="float-right">
                  <Button shape="circle" type="default" icon="down" />
                </span>
              </div>
            </Skeleton>
          }
          showArrow={false}
        >
          {item.note || 'No Data Available'}
        </Collapse.Panel>
      );
    }) : (
      <Collapse.Panel
        key={0}
        header={
          <div className="collapse-header">
            <div className={' history-block'}>
              Call logs appear here!
            </div>
          </div>
        }
        showArrow={false}
      />
    );

    const formItems =
      this.state.custom_fields.length &&
      this.state.custom_fields.map((item, index) => {
        return (
          <FormItem key={index}>
            <Input readOnly={true} value={item.value} />
            <div className="floating-label">{item.label}</div>
          </FormItem>
        );
      });

    const radioStatus =
      follow_up_status_general &&
      follow_up_status_general.map((item, index) => {
        return (
          <Radio.Button key={index} value={item.id}>
            <span className={'GENERAL followup-icon icon-' + item.web_icon} />
            {item.title}
          </Radio.Button>
        );
      });
    const {data} =  this.state;

    return (
      <div>
        <Modal
          visible={this.state.visible}
          className="modal-custom submission"
          onCancel={this.closeModal}
          footer={null}
          // footer={[
          //     <Button className='float-left' type='default' key="note" onClick={(e)=>{ this.setState({add_note : true})}}>Add Note</Button>,
          //     <Button type='default' key="archive" onClick={this.archiveEnquiry}>{this.state.archived ? 'Unarchive' : 'Archive'}</Button>,
          //     <Popconfirm placement="top" title={'Are you sure?'} onConfirm={this.deleteEnqry} okText="Yes" cancelText="No" okType='danger'>
          //         <Button type='default' key="delete" >Delete</Button>
          //     </Popconfirm>
          // ]}
        >
          <div className="header-title">
            <h2>{data && data.first_name}</h2>
            <p>{data && (moment.unix(data.created_at).fromNow() + ' ' + moment.unix(data.created_at).format('hh:mm A'))}</p>
          </div>
          <div  className={'submission-body'}>
            <div>
              <div className="subtitle">Call History & Notes</div>
              <div className="panel-container">
                <Collapse accordion bordered={false}>{panels}</Collapse>
              </div>
              <Button.Group>
                <Button
                  hidden={this.state.fetchedAll}
                  loading={this.state.loading}
                  size="small"
                  onClick={this.showMore}
                >
                  { this.state.loading ? 'Please wait' : 'Show more'}
                </Button>
              </Button.Group>
            </div>
            {
              data ? (
                <div className={'form-submission'}>
                  <div className="subtitle">Customer Details</div>
                  <div className="form-container">
                    <FormItem>
                      <Input readOnly={true} value={data.first_name || ''} />
                      <div className="floating-label">Name</div>
                    </FormItem>
                    <FormItem>
                      <Input readOnly={true} value={data.phone || ''} />
                      <div className="floating-label">Contact</div>
                    </FormItem>
                    <FormItem>
                      <Input readOnly={true} value={data.email || ''} />
                      <div className="floating-label">Email</div>
                    </FormItem>
                  </div>
                </div>
              ) : null
            }
            <div className={'form-submission'}>
              <div className="subtitle">Form Submission</div>
              <div className="form-container">
                <Form>{formItems}</Form>
              </div>
            </div>
          </div>

        </Modal>
        <Modal
          visible={this.state.add_note}
          className="modal-custom submission add-note"
          onCancel={this.closeNoteModal}
          footer={[
            <Button type="default" key="note" onClick={this.submitNote}>
              Save
            </Button>,
          ]}
        >
          <h2>Add Note</h2>
          <div>
            <Radio.Group
              onChange={this.onStatusChange}
              value={this.state.note_status}
            >
              {radioStatus}
            </Radio.Group>
            <Input.TextArea
              value={this.state.note}
              onChange={this.onNoteChange}
              rows={4}
              placeholder="Add a note"
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export const EnquiryDOM = ({ diff, percent, count }) => (
  <div className="no-enquiry">
    <div
      className={
        diff < 0
          ? 'value-text danger-color'
          : diff > 0
          ? 'value-text success-color'
          : 'value-text na-color light'
      }
    >
      Enquiries
    </div>
    <div className="value-container">
      <div className="value">{count}</div>
      <div className="sup">
        <img
          src={
            diff < 0
              ? arrowDownRed
              : diff > 0
              ? percent === 100
                ? arrowNA
                : arrowUpGreen
              : arrowNA
          }
          alt="arrow"
        />
        {diff < 0 ? (
          <span className={'danger-bgcolor light'}>
            {Math.round(percent)}%
          </span>
        ) : diff > 0 ? (
          percent === 100 ? (
            <span className={'na-bgcolor'}>NA</span>
          ) : (
            <span className={'success-bgcolor light'}>
              {Math.round(percent)}%
            </span>
          )
        ) : (
          <span className={'na-bgcolor'}>NA</span>
        )}
      </div>
    </div>
  </div>
);

export const ResponseDOM = ({ diff, percent, count }) => (
  <div className="no-enquiry">
    <div
      className={
        diff < 0
          ? 'value-text success-color'
          : diff > 0
          ? 'value-text danger-color'
          : 'value-text na-color light'
      }
    >
      Response time
    </div>
    <div className="value-container">
      <div className="value">{Math.round(count)}</div>
      <div className="sup">
        <img
          src={
            diff < 0
              ? arrowDownGreen
              : diff > 0
              ? percent === 100
                ? arrowNA
                : arrowUpRed
              : arrowNA
          }
          alt="arrow"
        />
        {diff < 0 ? (
          <span className={'success-bgcolor light'}>
            {Math.round(percent)}%
          </span>
        ) : diff > 0 ? (
          percent === 100 ? (
            <span className={'na-bgcolor'}>NA</span>
          ) : (
            <span className={'danger-bgcolor light'}>
              {Math.round(percent)}%
            </span>
          )
        ) : (
          <span className={'na-bgcolor'}>NA</span>
        )}
      </div>
    </div>
  </div>
);



export default ModalContent;
