import React, { Component } from 'react';
import routes from './routes';
import axios from 'axios';
import { base_url } from './config.js';
import { Router, withRouter } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';

import 'react-intl-tel-input/dist/main.css';

const customHistory = createBrowserHistory();

axios.defaults.baseURL = base_url;

//Interceptor To be used to intercept all the responses and if 401, and session
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('super_user');
      // this.props.history.push('/')
    } else return Promise.reject(error);
  },
);

axios.get('https://api.ipdata.co/?api-key=test').then((res) => {
  localStorage.setItem('country', res.data.country_code);
}, (err) => {

})

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const ScrollToTopWrapper = withRouter(ScrollToTop);

class App extends Component {
  render() {
    return (
      <Router history={customHistory}>
        <ScrollToTopWrapper>{routes}</ScrollToTopWrapper>
      </Router>
    );
  }
}

export default App;
