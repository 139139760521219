import React, { Component } from 'react';
import commonApiService from '@apiService';
import { Button, Card, Form, Input } from 'antd';
import { Link } from 'react-router-dom';

const FormItem = Form.Item;

class ActivateLayout extends Component {
  constructor(props) {
    console.log('activate');
    document.title = 'Activate Account | Buzzz.';
    super(props);
    this.state = {
      activate_account: false,
      loading: false,
    };
  }

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('new_password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm_password'], { force: true });
    }
    callback();
  };

  activateAccount = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const activate_account_data = {
          activation_key: this.props.match.params.activation_key,
          password: values.new_password,
        };

        this.setState({
          loading: true,
        });

        commonApiService.put('/activate_account', activate_account_data).then(
          successResult => {
            this.setState({
              loading: false,
              activate_account: true,
            });
          },
          errorResult => {
            console.log(errorResult);
            this.props.form.setFields({
              confirm_password: {
                value: this.props.form.getFieldValue('confirm_password'),
                errors: [new Error(errorResult.errors)],
              },
            });
            this.setState({
              loading: false,
            });
          },
        );
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="reset">
        <h2>
          Activate Account<span>.</span>
        </h2>
        <Card bordered={false}>
          <div hidden={this.state.activate_account}>
            <Form onSubmit={this.activateAccount}>
              <FormItem>
                {getFieldDecorator('new_password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                  ],
                })(<Input type="password" placeholder=" " />)}
                <div className="floating-label">Enter your password</div>
              </FormItem>
              <FormItem>
                {getFieldDecorator('confirm_password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(
                  <Input
                    type="password"
                    placeholder=" "
                    onBlur={this.handleConfirmBlur}
                  />,
                )}
                <div className="floating-label">Confirm your password</div>
              </FormItem>
              <FormItem>
                <Button
                  className={'auth-cust'}
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  Set Password
                </Button>
              </FormItem>
              <div className={'redirect'}>
                <Link
                  to="/signin"
                  onClick={e => {
                    this.setState(
                      {
                        activate_account: false,
                      },
                      function() {
                        this.props.form.resetFields();
                      },
                    );
                  }}
                >
                  {this.state.activate_account
                    ? 'Go to Login'
                    : '← Back to Login'}
                </Link>
              </div>
            </Form>
          </div>

          <div className={'post-block'} hidden={!this.state.activate_account}>
            <p className={'subtittle'}>
              Your Account has been set successfully<span>.</span>
            </p>
            <Link to="/signin">
              <Button
                type="primary"
                className={'auth-cust'}
                loading={this.state.loading}
              >
                Sign in to Continue
              </Button>
            </Link>
          </div>
        </Card>
      </div>
    );
  }
}
export default Form.create()(ActivateLayout);
