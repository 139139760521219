import { combineReducers } from 'redux';
import MainReducer from './home/MainReducer';
import DomainReducer from './domain/DomainReducer';
import SubscriptionReducer from './subscription/SubscriptionReducer';
import UserReducer from './user/UserReducer';

const AppReducer = combineReducers({
    MainReducer,
    DomainReducer,
    SubscriptionReducer,
    UserReducer,
});

const RootReducer = (state, action) => {

    if (action.type === 'LOGOUT_SUCCESS') {
        state = undefined
    }
    return AppReducer(state, action)
}

export default RootReducer;


