import React, { Component } from 'react';
import { Input, Button, Card, notification } from 'antd';
import './admin.scss';
import commonApiService from '@apiService';
const { TextArea } = Input;

class EmailTemplateLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      templates: [],
    };
  }

  componentDidMount() {
    this.getEmailTemplates();
  }

  getEmailTemplates = () => {
    this.setState({
      loading: true,
    });
    commonApiService.list('/web/admin/email_templates').then(
      response => {
        this.setState({
          loading: false,
          templates: response.data.email_templates,
        });
      },
      error => {
        notification.open({
          message: 'Error',
          description: error.errors,
          className: 'notification-error',
        });
      },
    );
  };

  updateEmailTemplate = (template, i) => {
    for (var key in template.value) {
      if (key === 'action') {
        template.value[key].title = this.refs[
          key + '_' + i + 'title'
        ].input.value;
        template.value[key].link = this.refs[
          key + '_' + i + 'link'
        ].input.value;
      } else {
        template.value[key] =
          key === 'content'
            ? this.refs[key + '_' + i].textAreaRef.value
            : this.refs[key + '_' + i].input.value;
      }
    }

    this.setState({
      loading: true,
    });
    commonApiService
      .put('/web/admin/email_templates', template)
      .then(
        response => {
          notification.open({
            message: 'Success',
            description: response.data.msg,
            className: 'notification-success',
          });
          template.edit = false;
          this.setState({});
        },
        error => {
          notification.open({
            message: 'Error',
            description: error.errors,
            className: 'notification-error',
          });
        },
      )
      .then(() => {
        // this.getEmailTemplates();
      });
  };

  render() {
    let email_template_list =
      this.state.templates &&
      this.state.templates.map((template, i) => {
        let fields = [];
        for (var key in template.value) {
          fields.push(
            <div key={key} className="config-row-grp config-row-grp-template">
              <div className="field-key">{key}:</div>
              {key !== 'content' && key !== 'action' ? (
                <Input
                  ref={key + '_' + i}
                  disabled={!template.edit}
                  defaultValue={template.value[key]}
                />
              ) : key !== 'action' ? (
                <TextArea
                  rows={4}
                  ref={key + '_' + i}
                  disabled={!template.edit}
                  defaultValue={template.value[key]}
                />
              ) : (
                <div className="action-wrap">
                  <div className="field-key">Button Name : </div>
                  <Input
                    ref={key + '_' + i + 'title'}
                    disabled={!template.edit}
                    defaultValue={template.value[key].title}
                  />
                  <div className="field-key">Button Link : </div>
                  <Input
                    ref={key + '_' + i + 'link'}
                    disabled={!template.edit}
                    defaultValue={template.value[key].link}
                  />
                </div>
              )}
            </div>,
          );
        }
        if (!fields.length) fields = 'EMAIL TEMPLATE NOT AVAILABLE';
        let templates = this.state.templates;
        return (
          <tr key={i}>
            <td>{template.key}</td>
            <td>{fields}</td>
            <td>
              <Button
                type="primary"
                size="small"
                hidden={template.edit}
                onClick={e => {
                  templates[i].edit = true;
                  this.setState({
                    templates: templates,
                  });
                }}
              >
                Edit
              </Button>
              <Button
                type="primary"
                size="small"
                hidden={!template.edit}
                onClick={e => {
                  this.updateEmailTemplate(templates[i], i);
                }}
              >
                Save
              </Button>
            </td>
          </tr>
        );
      });

    return (
      <main className="main-container">
        <Card
          title="Platform Email Templates"
          bordered={false}
          className="admin-box-container"
        >
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>EMAIL TEMPLATE NAME</th>
                  <th className="align-right">EMAIL TEMPLATE CONTENT</th>
                </tr>
              </thead>
              <tbody>{email_template_list}</tbody>
            </table>
          </div>
        </Card>
      </main>
    );
  }
}

export default EmailTemplateLayout;
