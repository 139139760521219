import React, { Component } from 'react';
import {
  Form,
  Modal,
  Input,
  Select,
  Button,
  Checkbox,
  notification,
} from 'antd';

import CustomUpload from '@components/common/CustomUpload';
import commonApiService from '@apiService';

class ProspectForm extends Component {
  state = {
    files: [],
    loading: false,
  };

  componentWillMount() {
    this.setState({
      addProspect: this.props.addProspect,
      phone_number: null,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.addProspect !== null &&
      nextProps.addProspect !== this.state.addProspect
    ) {
      this.setState({
        addProspect: nextProps.addProspect,
      });
    }
  }

  changeContactNumber = (status, value, countryData, number, id) => {
    let phone_number = {
      status: status,
      country_code: countryData.dialCode,
      phone_number: value,
      country: countryData.iso2,
    };
    this.setState({
      phone_number: phone_number,
    });
  };

  handleCancel = () => {
    this.props.handleAddProspectCancel();
  };

  handleProspect = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let files = [];
        this.setState({
          loading: true,
        });

        const data = this.props.addProspectForm.form_fields.map(
          (field, index) => {
            if (field.type === 'file') {
              files.push({
                file: this.state.files[field.name],
                key: field.name,
              });

              return {
                type: field.type,
                key: field.name,
              };
            }
            var fieldName = field.name.match(/\[[^\]]*]/g);
            if (fieldName && fieldName.length > 0) {
              var filteredNameArr = field.name.split('[');
              var filteredName = filteredNameArr[0];
              var filteredKey = filteredNameArr.length > 1 ? filteredNameArr[1].replace(']', '') : '';
              return {
                type: field.type,
                key: field.name,
                value: values[filteredName][filteredKey],
              };
            } else {
              return {
                type: field.type,
                key: field.name,
                value: values[field.name],
              };
            }
          },
        );

        const params = {
          form_id: this.props.addProspectForm.id,
          name: this.props.addProspectForm.name,
          viaTitle: values.source,
          note: values.notes,
          source: 'webapp',
          domain_id: this.props.domainId,
          form_fields: data,
        };

        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
          if (files[i] && files[i].file !== undefined) {
            formData.append(
              'file',
              files[i].file[0].originFileObj,
              files[i].key,
            );
          }
        }
        formData.append('form_fields', JSON.stringify(params));

        commonApiService
          .postMultipart('/add_prospect', formData)
          .then(successResult => {
            console.log(successResult);
            notification.open({
              message: 'Successful!',
              description: 'Date Recorded',
              className: 'notification-success',
            });
            this.handleCancel();
            this.setState({
              loading: false,
            });
          })
          .catch(errorResult => {
            console.log(errorResult);
            this.setState({
              loading: false,
            });
            notification.open({
              message: 'Error!',
              description: 'Something went wrong. Please try again!',
              className: 'notification-error',
            });
          });
      }
    });
  };

  setUploadedFile = (name, file) => {
    let files = this.state.files;
    files[name] = file;
    this.setState({
      files: files,
    });
  };

  isRequired = attr => {
    if (attr && attr.hasOwnProperty('required')) {
      if (attr.required === 'false') {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const type = this.props.addProspectForm
      ? this.props.addProspectForm.form_fields
      : [];

    const fields = type.map((field, index) => {
      switch (field.type) {
        case 'file':
          return (
            <Form.Item>
              <CustomUpload
                setUploadedFile={this.setUploadedFile}
                label={field.label}
                name={field.name}
              />
            </Form.Item>
          );
        case 'select':
        case 'select-one':
          const options = field.options.map((option, index) => {
            return (
              <Select.Option value={option.key}>{option.value}</Select.Option>
            );
          });

          return (
            <Form.Item>
              {getFieldDecorator(field.name, {
                rules: [
                  {
                    required: this.isRequired(field.attributes),
                    message: 'Please insert missing Data!',
                  },
                ],
              })(<Select>{options}</Select>)}
              <div className="floating-label force-float">{field.label}</div>
            </Form.Item>
          );

        case 'checkbox':
          let checkboxOptions = [];
          if (field.options) {
            checkboxOptions = field.options.map((option, index) => {
              return {
                label: option.value,
                value: option.value,
              };
            });
          }

          return (
            <Form.Item>
              <div hidden={checkboxOptions.length === 0 || field.label.replace(/[^0-9a-z]/gi, ' ').toLowerCase() === field.name.replace(/[^0-9a-z]/gi, ' ').toLowerCase()}>{field.label}</div>
              {getFieldDecorator(field.name, {
                rules: [
                  {
                    required: this.isRequired(field.attributes),
                    message: 'Please insert missing Data!',
                  },
                ],
              })(
                field.options ? (
                  <Checkbox.Group options={checkboxOptions} />
                ) : (
                  <Checkbox>{field.label} </Checkbox>
                ),
              )}
            </Form.Item>
          );
        case 'textarea':
          return (
            <Form.Item>
              {getFieldDecorator(field.name, {
                rules: [
                  {
                    required: this.isRequired(field.attributes),
                    message: 'Please insert missing Data!',
                  },
                ],
              })(<Input.TextArea placeholder={' '} type="text" />)}
              <div className="floating-label">{field.label}</div>
            </Form.Item>
          );
        case 'name':
          return (
            <Form.Item>
              {getFieldDecorator(field.name, {
                rules: [
                  {
                    required: true,
                    message: 'Please insert missing Data!',
                  },
                ],
              })(<Input.TextArea placeholder={' '} type="text" />)}
              <div className="floating-label">{field.label}</div>
            </Form.Item>
          );
        case 'tel':
          return (
            <Form.Item>
              {getFieldDecorator(field.name, {
                rules: [
                  {
                    required: true,
                    message: 'Please insert missing Data!',
                  },
                ],
              })(<Input.TextArea placeholder={' '} type="text" />)}
              <div className="floating-label">{field.label}</div>
            </Form.Item>
          );
        default:
          return (
            <Form.Item>
              {getFieldDecorator(field.name, {
                rules: [
                  {
                    required: this.isRequired(field.attributes),
                    message: 'Please insert missing Data!',
                  },
                ],
              })(<Input placeholder={' '} type="text" />)}
              <div className="floating-label">{field.label}</div>
            </Form.Item>
          );
      }
    });

    const { addProspect } = this.state;

    return (
      <Modal
        title="Add Prospect"
        className="modal-custom"
        visible={addProspect}
        footer={null}
        onCancel={this.handleCancel}
      >
        <Form onSubmit={this.handleProspect}>
          {fields}
          <Form.Item>
            {getFieldDecorator('source', {
              rules: [
                {
                  required: true,
                  message: 'Please selct Source of Enquiry!',
                },
              ],
            })(
              <Select placeholder={'Source of Enquiry'}>
                <Select.Option value={'Via Email'}>Email</Select.Option>
                <Select.Option value={'Via Phone'}>Phone</Select.Option>
                <Select.Option value={'Via Walk-in'}>Walk in</Select.Option>
              </Select>,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('notes', {
              rules: [
                {
                  required: true,
                  message: 'Please add Notes!',
                },
              ],
            })(<Input.TextArea placeholder={' '} type="text" />)}
            <div className="floating-label">Notes</div>
          </Form.Item>
          <Button.Group>
            <Button
              type={'primary'}
              loading={this.state.loading}
              htmlType={'submit'}
            >
              Add Prospect
            </Button>
          </Button.Group>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(ProspectForm);
