// ================================================================================
// MainReducer - Reducer
// ================================================================================

const MainReducer = (state = {}, action) => {
  switch (action.type) {
    case 'NETWORK_DOWN':
      return {
        ...state,
        network_down: true,
      };
    case 'NETWORK_ONLINE':
      return {
        ...state,
        network_down: false,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        user_data: action.payload.data,
        login_error: null,
        logout_success: false,
      };
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        user_data: null,
        logout_success: true,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        login_error: action.err.errors,
      };
    case 'PASSWORD_RESET_LINK_SUCCESS':
      return {
        ...state,
        password_reset_link_success: action.payload.data.msg,
        password_reset_link_failure: null,
      };
    case 'PASSWORD_RESET_LINK_FAILURE':
      return {
        ...state,
        password_reset_link_failure: action.err.errors,
      };
    case 'PASSWORD_RESET_SUCCESS':
      return {
        ...state,
        password_reset_success: action.payload.data.msg,
        password_reset_failure: null,
      };
    case 'PASSWORD_RESET_FAILURE':
      return {
        ...state,
        password_reset_failure: action.err.errors
          ? action.err.errors
          : action.err,
      };
    case 'FETCH_USER_NOTIFICATION_SUCCESS':
      return {
        ...state,
        user_notification_data_success: action.payload.data,
        user_notification_data_error: null,
      };
    case 'FETCH_USER_NOTIFICATION_FAILURE':
      return {
        ...state,
        user_notification_data_error: action.err.errors,
        user_notification_data_success: null,
      };
    case 'REALTIME_UPDATES':
      let data = action.data;
      return {
        ...state,
        realtime_update: data,
      };
    default:
      return state;
  }
};

export default MainReducer;
