import React, { Component } from 'react';
import { Upload, Button } from 'antd';

class CustomUpload extends Component {
  state = {
    fileList: [],
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  beforeUpload = file => {
    this.setState(
      {
        fileList: file,
      },
      () => {
        this.props.setUploadedFile(this.props.name, this.state.fileList);
      },
    );
    return false;
  };

  render() {
    const { fileList } = this.state;
    const { label } = this.props;

    return (
      <Upload
        beforeUpload={this.beforeUpload}
        name="avatar"
        multiple={false}
        className="avatar-uploader"
        onChange={this.handleChange}
      >
        {fileList.length >= 1 ? null : (
          <Button type={'default'} icon={'upload'}>
            {label}{' '}
          </Button>
        )}
      </Upload>
    );
  }
}

export default CustomUpload;
