// ================================================================================
// SubscriptionReducer - Reducer
// ================================================================================

const SubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_SUBSCRIPTION_PRE':
      return {
        ...state,
        user_subscription_fetch_error: null,
      };
    case 'FETCH_SUBSCRIPTION_SUCCESS':
      return {
        ...state,
        user_subscription_fetch_success: action.payload.data,
        user_subscription_fetch_error: null,
        update_billing_success: null,
        update_account_plan_success: null,
      };
    case 'FETCH_SUBSCRIPTION_FAILURE':
      return {
        ...state,
        user_subscription_fetch_error: action.err.errors,
        update_billing_success: null,
      };
    case 'FETCH_PLAN_SUCCESS':
      return {
        ...state,
        plan_fetch_success: action.payload.data.subscriptions,
        plan_fetch_error: null,
      };
    case 'FETCH_PLAN_FAILURE':
      return {
        ...state,
        plan_fetch_success: null,
        plan_fetch_error: action.err.errors,
      };
    case 'REGISTER_SUBSCRIPTION_SUCCESS':
      return {
        ...state,
        subscription_register_success: action.payload.data.msg,
        subscription_register_error: null,
      };
    case 'REGISTER_SUBSCRIPTION_FAILURE':
      return {
        ...state,
        subscription_register_error: action.err.errors,
        subscription_register_success: null,
      };
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        subscription_register_success: null,
      };
    case 'UPDATE_BILLING_INFO_PRE':
      return {
        ...state,
        update_billinginfo_success: null,
        update_billinginfo_error: null,
      };
    case 'UPDATE_BILLING_INFO_SUCCESS':
      return {
        ...state,
        update_billinginfo_success: action.payload.data.msg,
        update_billinginfo_error: null,
      };
    case 'UPDATE_BILLING_SUCCESS':
      return {
        ...state,
        update_billing_success: action.payload.data.msg,
        update_billing_error: null,
      };
    case 'UPDATE_BILLING_FAILURE':
      return {
        ...state,
        update_billing_success: null,
        update_billing_error: action.err.errors,
      };
    case 'DEACTIVATE_ACCOUNT_SUCCESS':
      return {
        ...state,
        deactivate_account_success: action.payload.data.msg,
        deactivate_account_error: null,
        activate_account_success: null,
      };
    case 'DEACTIVATE_ACCOUNT_FAILURE':
      return {
        ...state,
        deactivate_account_success: null,
        deactivate_account_error: action.err.errors,
      };
    case 'ACTIVATE_ACCOUNT_SUCCESS':
      return {
        ...state,
        activate_account_success: action.payload.data.msg,
        activate_account_error: null,
        deactivate_account_success: null,
      };
    case 'ACTIVATE_ACCOUNT_FAILURE':
      return {
        ...state,
        activate_account_success: null,
        activate_account_error: action.err.errors,
      };
    case 'UPDATE_ACCOUNT_PLAN_SUCCESS':
      return {
        ...state,
        update_account_plan_success: action.payload.data.msg,
        update_account_plan_error: null,
      };
    case 'UPDATE_ACCOUNT_PLAN_FAILURE':
      return {
        ...state,
        update_account_plan_success: null,
        update_account_plan_error: action.err.errors,
      };
    case 'FETCH_INVOICES_SUCCESS':
      return {
        ...state,
        fetch_invoices_success: action.payload.data,
        fetch_invoices_error: null,
      };
    case 'FETCH_INVOICES_FAILURE':
      return {
        ...state,
        fetch_invoices_success: null,
        fetch_invoices_error: action.err.errors,
      };
    case 'FETCH_COUNTRY_LIST_SUCCESS':
      return {
        ...state,
        country_list_success: action.payload.data.countries,
        country_list_error: null,
      };
    case 'FETCH_COUNTRY_LIST_FAILURE':
      return {
        ...state,
        country_list_success: null,
        country_list_error: action.err.errors,
      };
    default:
      return state;
  }
};

export default SubscriptionReducer;
