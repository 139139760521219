import React, { Component } from 'react';
import moment from 'moment';
import { Button, Card, Table, Alert } from 'antd';

class InvoiceComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          title: 'Date',
          dataIndex: 'created_at',
          key: 'created_at',
          render: text => (
            <div>
              <span className="icon-calendar" />
              {moment.unix(text).format('DD - MMMM - YYYY')}
            </div>
          ),
        },
        {
          title: 'Invoice Number',
          dataIndex: 'number',
          key: 'number',
          render: text => <div>#{text}</div>,
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
          render: (text, record) => (
            <div>
              {text} {record.currency}
            </div>
          ),
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: text => <div className={text}>{text}</div>,
        },
        {
          title: '',
          dataIndex: 'download',
          key: 'download',
          render: (text, record) => (
            <div>
              <Button
                shape={'circle'}
                className="no-bg-strict download"
                onClick={e => {
                  this.downloadPdf(record.number);
                }}
              >
                <span className="icon-download" />
              </Button>
            </div>
          ),
        },
      ],
      data: [],
    };
  }

  componentDidMount() {
    this.props.fetchSubscriptionInvoiceList();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      if (
        nextProps.data !== null &&
        this.props.data !== nextProps.data &&
        nextProps.data !== this.state.data
      ) {
        this.setState({
          data: nextProps.data,
        });
      }
    }
  }
  downloadPdf = invoice_id => {
    this.props.fetchInvoicePdf({ id: invoice_id });
  };
  render() {
    return (
      <div className="payment-history" hidden={!this.state.data.length}>
        <Card title="Payment History" bordered={false}>
          <Table
            scroll={{ x: 768 }}
            rowKey={record => record.number}
            pagination={false}
            className="dashboard_table"
            columns={this.state.columns}
            dataSource={this.state.data}
          />
        </Card>

        <Alert
          message="Invoices are created monthly and emailed to the account owner."
          description={`Next invoice will be delivered on ${this.props
            .next_payment_date &&
            moment.unix(this.props.next_payment_date).format('MMM DD, YYYY')}`}
          type="warning"
        />
      </div>
    );
  }
}

export default InvoiceComponent;
