import React, { Component } from 'react';
import {
  Button,
  Modal,
  Table,
  Input,
  Avatar,
  Popconfirm,
  notification,
} from 'antd';
import commonApiService from '@apiService';

const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

class WebsiteUsers extends Component {
  state = {
    add_user_list: [],
    filtered_user_list: [],
    visible: false,
  };

  showUserList = () => {
    this.setState({
      visible: true,
    });
    const params = { id: this.props.domainId, fetch_other_user: true };
    commonApiService
      .list('/web/domains/users', params)
      .then(successResult => {
        console.log(successResult);

        let assignedUsers = [];
        if (this.props.assignedUsers) {
          assignedUsers = this.props.assignedUsers.map((user, index) => {
            user.selected = true;
            return user;
          });
        }

        const userList = [...assignedUsers, ...successResult.data.users];
        this.setState({
          add_user_list: userList,
          filtered_user_list: userList,
        });
      })
      .catch(errorResult => {
        console.log(errorResult);
      });
  };

  closeAddUserModal = () => {
    this.props.onClose();
    this.setState({
      visible: false,
    });
  };

  assignUserToDomain = key => {
    let filtered_user_list = this.state.filtered_user_list;

    filtered_user_list.forEach(function(user_entry) {
      if (user_entry['id'] === key) {
        if (user_entry['selected'] === undefined || !user_entry['selected']) {
          user_entry['selected'] = true;
        } else {
          user_entry['selected'] = false;
        }
      }
    });
    this.setState({
      filtered_user_list: filtered_user_list,
    });
  };

  handleSearch = e => {
    const { value } = e.target;
    const userList = this.state.add_user_list;
    const pattern = new RegExp(value, 'i');

    const filterList = userList.filter((user, index) => {
      return pattern.test(user.first_name) || pattern.test(user.last_name);
    });
    this.setState({
      filtered_user_list: filterList,
    });
  };

  addUser = id => {
    let name = '';

    let filtered_user_list = this.state.filtered_user_list;
    let user_list = this.state.add_user_list;

    for (let i = 0; i < filtered_user_list.length; i++) {
      const user_entry = filtered_user_list[i];
      if (user_entry['id'] === id) {
        user_entry['selected'] = true;
        break;
      }
    }
    for (let i = 0; i < user_list.length; i++) {
      const user_entry = user_list[i];
      if (user_entry['id'] === id) {
        name = user_entry.first_name;
        user_entry['selected'] = true;
        break;
      }
    }

    const params = {
      id: this.props.domainId,
      users_to_add: [id],
    };

    commonApiService
      .post('/web/domains/users', params)
      .then(successResult => {
        notification.open({
          message: 'User Added',
          description: `${name} has been assigned to domain!`,
          className: 'notification-success',
        });
        this.setState({
          filtered_user_list: filtered_user_list,
          add_user_list: user_list,
        });
      })
      .catch(errorResult => {});
  };

  removeUser = id => {
    let name = '';
    let filtered_user_list = this.state.filtered_user_list;
    let user_list = this.state.add_user_list;

    for (let i = 0; i < filtered_user_list.length; i++) {
      const user_entry = filtered_user_list[i];
      if (user_entry['id'] === id) {
        user_entry['selected'] = false;
        break;
      }
    }
    for (let i = 0; i < user_list.length; i++) {
      const user_entry = user_list[i];
      if (user_entry['id'] === id) {
        name = user_entry.first_name;
        user_entry['selected'] = false;
        break;
      }
    }

    const params = {
      id: this.props.domainId,
      remove_user_id: id,
    };

    commonApiService
      .put('/web/domains/users', params)
      .then(successResult => {
        notification.open({
          message: 'User Removed',
          description: `${name} has been removed from domain!`,
          className: 'notification-neutral',
        });
        this.setState({
          filtered_user_list: filtered_user_list,
          add_user_list: user_list,
        });
      })
      .catch(errorResult => {
        notification.open({
          message: 'Error',
          description: errorResult.errors[0],
          className: 'notification-error',
        });
      });
  };

  render() {
    const modalCols = [
      {
        dataIndex: 'profile_pic',
        key: 'profile_pic',
        // width: "50%",
        render: (text, record) => (
          <div className="user-profile">
            {record.avatar ? (
              <Avatar src={record.avatar} />
            ) : (
              <Avatar
                style={{
                  backgroundColor:
                    colorList[
                      (record.first_name[0].charCodeAt() +
                        record.last_name[0].charCodeAt()) %
                        4
                    ],
                  color: '#fff',
                }}
              >
                {record.first_name[0].toUpperCase() +
                  record.last_name[0].toUpperCase()}
              </Avatar>
            )}
          </div>
        ),
      },
      {
        dataIndex: 'first_name',
        className: 'user-title',
        key: 'first_name',
        width: '50%',
        render: (text, record) => (
          <div className="user-profile">
            <span>{record.first_name + ' ' + record.last_name || '-'}</span>
          </div>
        ),
      },
      {
        dataIndex: 'type',
        key: 'company',
        width: '40%',
        render: text => (
          <div className="user-type">
            {text === 'Admin' ? 'Admin' : 'User' || '-'}
          </div>
        ),
      },
      {
        dataIndex: 'id',
        key: 'id',
        width: '10%',
        className: 'add-user-button',
        render: (text, record) => {
          if (record.selected) {
            return (
              <Popconfirm
                title="Are you sure you want to remove user？"
                okText="Yes"
                okType="danger"
                cancelText="No"
                onConfirm={() => {
                  this.removeUser(record.id);
                }}
              >
                <Button type={'primary'} icon={'check'} shape="circle" />
              </Popconfirm>
            );
          } else {
            return (
              <Button
                type={'default'}
                icon={'plus'}
                shape="circle"
                onClick={e => {
                  this.addUser(record.id);
                }}
              />
            );
          }
        },
      },
    ];

    return (
      <Modal
        title="Assign Users"
        visible={this.state.visible}
        onCancel={this.closeAddUserModal}
        footer={null}
        className="add-user-modal"
      >
        <div className={'searchbox'}>
          <Input
            placeholder={'Search for Users ...'}
            onChange={this.handleSearch}
          />
        </div>
        <Table
          showHeader={false}
          scroll={{ x: 420 }}
          rowkey="id"
          className="user-list-table"
          locale={{ emptyText: <span>No Users</span> }}
          pagination={false}
          columns={modalCols}
          dataSource={this.state.filtered_user_list}
        />
      </Modal>
    );
  }
}

export default WebsiteUsers;
