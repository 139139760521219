import React from 'react';
import { Card} from "antd";

class  HelpExtractForm extends React.Component{
    render() {
        return (
            <Card className={'help-card'} title={'Help'} extra={<a href="#">hello@buzzz.io</a>} bordered={false}>
              <h4>What is the website service you’re using?</h4>
              <p>Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
              <img src="http://placehold.it/300x260" alt="img"/>
              <p>It has survived not only five centuries, but also the leap into electronic typesetting.</p>
              <img src="http://placehold.it/200x100" alt="img"/>
            </Card>
        )
    }
}

export default HelpExtractForm;