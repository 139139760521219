import React, {Component} from "react";
import {Form} from "antd";
import IntlTelInput from "react-intl-tel-input";

class MobileInput extends Component{

    state = {
        key: 'initialInput',
        phoneNumber: {
            status : true,
            country_code: null,
            phone_number: null,
            country : null
        }
    }

    changeContactNumber = (status, value, countryData, number, id) => {
        let phone_number = {
            status : status,
            country_code: countryData.dialCode,
            phone_number: value,
            country : countryData.iso2
        };
        this.setState({
            phoneNumber : phone_number
        });
    };
    changeCountryCode = (status, value, countryData, number, id) => {
        let phone_number = {
            status : false,
            country_code: countryData.dialCode,
            phone_number: value,
            country : countryData.iso2
        };
        this.setState({
            phoneNumber : phone_number
        });
    };

    getPhoneNumber = () => {
        if(this.state.phoneNumber.status){
            return {
                country_code: this.state.phoneNumber.country_code ? '+' + this.state.phoneNumber.country_code.replace(/\s/g,'') : '',
                phone_number: this.state.phoneNumber.phone_number ? this.state.phoneNumber.phone_number.replace(/\s/g,'') : '',
                country : this.state.phoneNumber.country ? this.state.phoneNumber.country.toUpperCase() : ''
            };
        }
        return false;
    }

    updateInput = (phoneNumber) => {
        this.setState({
            key: 'updatedInput'+ Math.random(),
            phoneNumber: {
                status: true,
                country_code: phoneNumber.country_code,
                phone_number: phoneNumber.phone_number,
                country: phoneNumber.country && phoneNumber.country.toLowerCase()
            }
        })
    }

    componentWillMount() {
        const { phoneNumber } = this.props;
        if(phoneNumber){
            this.setState({
                key: 'updatedInput'+ Math.random(),
                phoneNumber: {
                    status: true,
                    country_code: phoneNumber.country_code,
                    phone_number: phoneNumber.phone_number,
                    country: phoneNumber.country && phoneNumber.country.toLowerCase()
                }
            })
        }
    }

    render(){
        const { phoneNumber } = this.state;
        const country = localStorage.getItem('country');
        const props = Object.assign({}, phoneNumber && !phoneNumber.status && {help:"Invalid Phone Number",validateStatus: "error"});
        return (
            <Form.Item colon={false} {...props} >
                {
                    phoneNumber.country &&  phoneNumber.phone_number ? (
                        <IntlTelInput
                            key={this.state.key}
                            separateDialCode={true}
                            css={['intl-tel-input', 'form-control']}
                            defaultCountry={phoneNumber.country}
                            defaultValue={phoneNumber.phone_number}
                            onSelectFlag={this.changeCountryCode}
                            onPhoneNumberChange={this.changeContactNumber}/>
                    ) : (
                        <IntlTelInput
                            key={this.state.key}
                            defaultCountry={country && country.toLowerCase()}
                            separateDialCode={true}
                            css={['intl-tel-input', 'form-control']}
                            onSelectFlag={this.changeCountryCode}
                            onPhoneNumberChange={this.changeContactNumber}/>
                    )
                }

                <div className="floating-label force-float">Phone Number</div>
            </Form.Item>
        )
    }
}

export default MobileInput;