export function networkDown(){
    return {
        type : 'NETWORK_DOWN'
    }
}

export function networkOnline(){
    return {
        type : 'NETWORK_ONLINE'
    }
}