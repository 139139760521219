import React, { Component } from 'react';
import Script from 'react-load-script';
import moment from 'moment';
import {
  Card,
  Modal,
  notification,
  Button,
  Row,
  Col,
} from 'antd';

import WrappedUserCardDetailForm from './UserCardDetailForm.js';
import InvoiceComponent from './subcomponents/InvoiceComponent';
import AccHolderInfo from './subcomponents/AccHolderInfo';
import CardInput from './subcomponents/CardInput';
import NumericInput from './subcomponents/NumericInput';

import Plans from './Plan';

import './SubscriptionLayout.scss';
import ContactUsComponent from '@components/common/ContactUsLayout';
import StrictLoader from '@components/common/strictloader/StrictLoader';

class SubscriptionLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      subscription_change_loading: false,
      billing_loading: false,
      account_status_change: false,
      cvv_modal: false,
      cvv: null,
      card_verifying: false,
      card_verified: false,
      visible: false,
      subscriptions: [],
      card_details: null,
      payment_configuration: {},
      subscription_id: null,
      subscription_status: true,
      replaceCardTrigger: false,
      card_number: null,
      cvvTriggerFunction: null,
      showContactUsModal: false,
      phone_number: null,
      acc_holder_info_mode: 'VIEW',
    };
    document.title = 'Subscriptions | Buzzz.';
  }

  componentWillMount() {
    this.props.actions.fetchCountryList();
    this.props.actions.fetchSubscription();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      if (
        this.state.subscription_changed ||
        (nextProps.user_subscription_fetch_success !== null &&
          ((this.state.subscriptions !==
            nextProps.user_subscription_fetch_success.subscriptions ||
            this.state.card_details !==
              nextProps.user_subscription_fetch_success.card_details) &&
            this.state.loading))
      ) {
        this.setState({
          subscriptions:
            nextProps.user_subscription_fetch_success.subscriptions,
          card_details: nextProps.user_subscription_fetch_success.card_details,
          billing_details:
            nextProps.user_subscription_fetch_success.billing_details,
          payment_configuration:
            nextProps.user_subscription_fetch_success.payment_configuration,
          subscription_id:
            nextProps.user_subscription_fetch_success.subscription_id,
          subscription_status:
            nextProps.user_subscription_fetch_success.subscription_status,
          subscription_changed: false,
          loading: false,
        });
      } else if (
        nextProps.update_billing_success !== null &&
        nextProps.update_billing_success !== this.props.update_billing_success
      ) {
        this.setState({
          billing_loading: false,
          billing_visible: false,
          loading: true,
        });
        this.props.actions.fetchSubscription();
      } else if (
        nextProps.update_billing_error !== null &&
        nextProps.update_billing_error !== this.props.update_billing_error
      ) {
        this.setState({
          billing_loading: false,
          replaceCardTrigger: false,
        });
        notification.open({
          message: 'Error.Try Again in sometime',
          description: 'Billing Info Update Failed',
          className: 'notification-error',
        });
      } else if (
        nextProps.deactivate_account_success &&
        this.props.deactivate_account_success !==
          nextProps.deactivate_account_success
      ) {
        notification.open({
          message: nextProps.deactivate_account_success,
          description: 'Your Account has been deactivated',
          className: 'notification-neutral',
        });
        this.setState({
          subscription_status: false,
          subscriptionConfirmation: false,
        });
      } else if (
        nextProps.deactivate_account_error &&
        this.props.deactivate_account_error !==
          nextProps.deactivate_account_error
      ) {
        notification.open({
          message: 'Error',
          description: nextProps.deactivate_account_error,
          className: 'notification-error',
        });
      } else if (
        nextProps.activate_account_success &&
        this.props.activate_account_success !==
          nextProps.activate_account_success
      ) {
        notification.open({
          message: nextProps.activate_account_success,
          description: 'Your Account has been activated',
          className: 'notification-success',
        });
        this.setState({
          subscription_status: true,
          activate_account: null,
        });
      } else if (
        nextProps.activate_account_error &&
        this.props.activate_account_error !== nextProps.activate_account_error
      ) {
        notification.open({
          message: 'Error',
          description: nextProps.activate_account_error,
          className: 'notification-error',
        });
      } else if (
        nextProps.update_account_plan_success &&
        this.props.update_account_plan_success !==
          nextProps.update_account_plan_success
      ) {
        notification.open({
          message: nextProps.update_account_plan_success,
          description: 'Account Plan Changed',
          className: 'notification-success',
        });
        this.props.actions.fetchSubscriptionInvoiceList();

        this.state.subscriptions.forEach(plan => {
          plan.subscribed = false;
          if (plan.id === this.state.change_to_plan_id) {
            plan.subscribed = true;
          }
        });

        this.setState({
          subscription_changed: true,
          change_to_plan_id: null,
          subscription_change_loading: false,
          subscriptions: this.state.subscriptions,
        });
        this.props.actions.fetchSubscription();
      } else if (
        nextProps.update_account_plan_error &&
        this.props.update_account_plan_error !==
          nextProps.update_account_plan_error
      ) {
        this.setState({
          subscription_change_loading: false,
        });

        notification.open({
          message: 'Error',
          description: nextProps.update_account_plan_error,
          className: 'notification-error',
        });
      } else if (
        nextProps.update_billinginfo_success &&
        this.props.update_billinginfo_success !==
          nextProps.update_billinginfo_success
      ) {
        this.setState({ acc_holder_info_mode: 'VIEW' });
        notification.open({
          message: nextProps.update_billinginfo_success,
          description: 'Account Billing',
          className: 'notification-neutral',
        });
      }
    }
  }

  showBillingModal = () => {
    this.setState({
      billing_visible: true,
    });
  };

  updatedTokenRequest = (request_data, callback) => {
    var args = {
      sellerId: this.state.payment_configuration.seller_id,
      publishableKey: this.state.payment_configuration.publishable_key,
      ccNo: request_data.card_number,
      cvv: request_data.cvv,
      expMonth: request_data.expiry.split('/')[0],
      expYear: request_data.expiry.split('/')[1],
    };
    var successCallback = function(data) {
      callback(null, data.response.token.token);
    };
    //Called when token creation fails.
    var errorCallback = function(data) {
      // Retry the token request if ajax call fails
      if (data.errorCode === 200) {
        window.TCO.requestToken(successCallback, errorCallback, args);
      } else {
        callback(data.errorMsg);
      }
    };
    //Make the token request
    window.TCO.requestToken(successCallback, errorCallback, args);
  };

  linkUserCardInformation = card_data => {
    if (localStorage.getItem('trial_expires_in') === 'false') {
      let card_number = card_data['card_number'];
      let sliced_card_number = card_data['card_number'].slice(-4);
      this.setState({
        cvv_modal: true,
        card_details: card_data,
        card_number: card_number,
        replaceCardTrigger: token => {
          var new_billing_info = {
            subscription_id: this.state.subscription_id,
            card_details: card_data,
          };
          new_billing_info.card_details['token'] = token;
          new_billing_info.card_details['card_number'] = sliced_card_number;
          this.props.actions.changeBillingDetails(new_billing_info);
        },
      });
    } else {
      card_data['card_number'] = card_data['card_number'].slice(-4);
      var new_billing_info = {
        subscription_id: this.state.subscription_id,
        card_details: card_data,
      };
      this.props.actions.changeBillingDetails(new_billing_info);
    }

    this.setState({
      billing_loading: true,
    });
  };

  deactivateAccount = () => {
    this.props.actions.deactivateAccount({
      subscription_id: this.state.subscription_id,
    });
  };

  handleCvvCancel = () => {
    this.setState({
      cvv_modal: false,
      change_to_plan_id: null,
      activate_account: null,
      billing_loading: false,
    });
  };

  fetchCVV(value) {
    this.setState({
      cvv: value,
    });
  }

  handleCvvVerify = () => {
    //cardnumber fetch
    let card_number = this.refs.card_number.getCardNumber();
    if (!card_number) return;

    let value = this.state.cvv;
    if (!value || value.length < 3) return;

    this.setState(
      {
        disable_cvv: true,
        card_verifying: true,
        card_verified: false,
      },
      function() {
        let card_details = Object.assign({}, this.state.card_details);
        this.updatedTokenRequest(
          Object.assign(card_details, {
            cvv: value,
            card_number: card_number,
          }),
          (err, token) => {
            if (err) return;

            if (this.state.replaceCardTrigger) {
              this.state.replaceCardTrigger(token);
            } else {
              if (
                this.state.change_to_plan_id !== null &&
                this.state.change_to_plan_id !== undefined
              ) {
                var update_plan_data = {
                  subscription_id: this.state.subscription_id,
                  plan_id: this.state.change_to_plan_id,
                  token: token,
                };

                this.props.actions.updateAccountPlan(update_plan_data);
                this.setState({
                  subscription_change_loading: true,
                });
              } else if (
                this.state.activate_account !== null &&
                this.state.activate_account !== undefined
              ) {
                this.props.actions.activateAccount({
                  subscription_id: this.state.subscription_id,
                  token: token,
                });
              }
            }

            this.setState({
              cvv_modal: false,
              disable_cvv: false,
              card_verifying: false,
              card_verified: true,
              cvv: null,
            });
          },
        );
      },
    );
  };
  handleAccInfoCancel = () => {
    this.setState({ acc_holder_info_mode: 'VIEW' });
  };

  handleScriptLoad = () => {
    window.TCO.loadPubKey('sandbox', function() {});
  };


  cancelSubscription = () => {
    this.setState({
      subscriptionConfirmation: true,
    });
  };

  activateAccount = () => {
    this.setState({
      cvv_modal: true,
      change_to_plan_id: null,
      activate_account: true,
    });
  };

  upgradePlan = plan => {
    if (this.state.card_details) {
      this.setState({
        change_to_plan_id: plan.id,
        cvv_modal: true,
      });
    } else {
      this.setState(
        {
          billing_visible: true,
        },
        function() {
          notification.open({
            message: 'Card Required',
            description: 'Please add a card before Changing Subscription Plan',
            className: 'notification-error',
          });
        },
      );
    }
  };

  editAccHolderInfo = () => {
    this.setState(
      {
        acc_holder_info_mode: 'EDIT',
      },
      this.updateMobileInput,
    );
  };

  getInvoiceData = (invoice_data)=>{

    if(invoice_data===null ||invoice_data===undefined) return '';
    return invoice_data.isTrial
        ? 'Next Payment Date ' + moment.unix(invoice_data.next_payment_date).format('DD/MM/YYYY')
        : 'Last charged '+ invoice_data && invoice_data.invoices.length > 0 && moment.unix(invoice_data.invoices[0].created_at).format('DD/MM/YYYY')
  };

  getCurrentPlanEndDate = (invoice_data) => {
	  if(invoice_data===null ||invoice_data===undefined) return '';
	  return invoice_data.isTrial
		  ? ' Trial period i.e on '+moment.unix(invoice_data.next_payment_date).format('DD MMMM YYYY')+'.'
		  : ' current billing period on '+ invoice_data && invoice_data.invoices.length > 0 && moment(invoice_data.invoices[0].created_at).add(30, 'days').format('DD MMMM YYYY') + '.'
  };


  render() {
    let invoice_data = this.props.fetch_invoices_success;
    return (
      <main className="main-container">
        <div className="subscription-block">
          <Script
            url="https://www.2checkout.com/checkout/api/2co.min.js"
            onLoad={this.handleScriptLoad}
          />

          <div className="account-info">
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Card
                  className="account-holder"
                  title="Account Holder Info"
                  bordered={false}
                  extra={
                    <Button
                      size={'small'}
                      type="default"
                      onClick={this.editAccHolderInfo}
                    >
                      Edit
                    </Button>
                  }
                >
                  <div className="acc-holder-info">
                    <Row>
                      <Col xs={12}>
                        <label>First Name</label>
                        <div>
                          {this.state.billing_details &&
                            this.state.billing_details.first_name}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <label>Last Name</label>
                        <div>
                          {this.state.billing_details &&
                            this.state.billing_details.last_name}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <label>Phone number</label>
                        <div>
                          {this.state.billing_details &&
                            this.state.billing_details.contact_number &&
                            this.state.billing_details.contact_number
                              .country_code +
                              ' ' +
                              this.state.billing_details.contact_number
                                .phone_number}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <label>Email address</label>
                        <div>
                          {this.state.billing_details &&
                            this.state.billing_details.email_address}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <label>Address</label>
                        <div>
                          {this.state.billing_details &&
                            this.state.billing_details.address}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24}>
                        <label>Country</label>
                        <div>
                          {this.state.billing_details &&
                            this.state.billing_details.country}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
              <Col xs={24} sm={12}>
                <Card
                  className="account-card"
                  title="My Payment Details"
                  bordered={false}
                  extra={
                    <Button
                      size={'small'}
                      type="default"
                      onClick={this.showBillingModal}
                    >
                      {this.state.card_details ? 'Replace Payment Details' : 'Add Payment Details'}
                    </Button>
                  }
                >
                  <div className="user-card">
                    <div className="user-card-head">
                      <Row>
                        <Col xs={12}>
                          Visa ****
                          {this.state.card_details &&
                            this.state.card_details.card_number}
                        </Col>
                        <Col xs={12}>{this.getInvoiceData(invoice_data)}</Col>
                      </Row>
                    </div>
                    <div className="user-card-body">
                      <Row>
                        <Col xs={12}>
                          <label>Card Holder Name</label>
                          <div>
                            {this.state.card_details &&
                              this.state.card_details.card_name}
                          </div>
                        </Col>
                        <Col xs={12}>
                          <label>Expires</label>
                          <div>{this.state.card_details &&
                          this.state.card_details.expiry}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={12}>
                          <label>Email</label>
                          <div>
                            {this.state.card_details &&
                              this.state.card_details.email}
                          </div>
                        </Col>
                        <Col xs={24} sm={12}>
                          <label>Billing Information</label>
                          <div>
                            {this.state.card_details &&
                              this.state.card_details.addr_1}
                            ,{' '}
                            {this.state.card_details &&
                              this.state.card_details.city}
                            ,{' '}
                            {this.state.card_details &&
                              this.state.card_details.state}{' '}
                            {this.state.card_details &&
                              this.state.card_details.postal_code}{' '}
                            {this.state.card_details &&
                              this.state.card_details.country}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>

          <div className={'subscription-plans'}>
            <Card
              title="Subscription Plans"
              bordered={false}
              extra={
                <Button
                  type="default"
                  size="small"
                  onClick={
                    this.state.subscription_status
                      ? this.cancelSubscription
                      : this.activateAccount
                  }
                >
                  {this.state.subscription_status
                    ? 'Cancel Subscription'
                    : 'Activate Account'}
                </Button>
              }
            >
              <div className="plans">
                <Plans
                  plans={this.state.subscriptions}
                  nextStep={this.upgradePlan}
                />
              </div>
            </Card>
          </div>

          <InvoiceComponent
            fetchInvoicePdf={this.props.actions.fetchInvoicePdf}
            fetchSubscriptionInvoiceList={
              this.props.actions.fetchSubscriptionInvoiceList
            }
            next_payment_date={
              invoice_data &&
              invoice_data.next_payment_date
            }
            data={
              invoice_data &&
              invoice_data.invoices
            }
          />
          <Modal
            visible={this.state.cvv_modal}
            destroyOnClose={true}
            title="CONFIRMATION"
            onCancel={this.handleCvvCancel}
            footer={null}
            className="subscription-confirm-modal"
          >
            <p>
              Upon proceeding to update your{' '}
              {this.state.replaceCardTrigger ? 'credit card' : 'current plan'},
              please provide your card's cvv code that is located at the back of
              your card
            </p>

            <div className="user-card">
              <div className="user-card-head">
                <Row>
                  <Col xs={12}>
                    Visa ****
                    {this.state.card_details &&
                    this.state.card_details.card_number}
                  </Col>
                  <Col xs={12}>{
                     this.getInvoiceData(invoice_data)
                    }
                  </Col>
                </Row>
              </div>
              <div className="user-card-body">
                <Row>
                  <Col xs={12}>
                    <label>Card Holder Name</label>
                    <div>
                      {this.state.card_details &&
                      this.state.card_details.card_name}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <label>Expires</label>
                    <div>{this.state.card_details &&
                    this.state.card_details.expiry}</div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12}>
                    <label>Email</label>
                    <div>
                      {this.state.card_details && this.state.card_details.email}
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <label>Billing Information</label>
                    <div>
                      {this.state.card_details &&
                        this.state.card_details.addr_1}
                      ,{' '}
                      {this.state.card_details && this.state.card_details.city},{' '}
                      {this.state.card_details && this.state.card_details.state}{' '}
                      {this.state.card_details &&
                        this.state.card_details.postal_code}{' '}
                      {this.state.card_details &&
                        this.state.card_details.country}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="card-confirm-footer">
              <div className="ccard-container">
                <div className="ccard-label">Card Number*</div>
                <CardInput
                  cardNumber={this.state.card_number}
                  last4Digits={
                    this.state.card_details &&
                    this.state.card_details.card_number
                  }
                  ref="card_number"
                />
              </div>
              <div className="cvv-container">
                <div className="cvv-label">CVV Code*</div>
                <NumericInput
                  disabled={this.state.disable_cvv}
                  onChange={this.fetchCVV.bind(this)}
                />
                <div className="cvv-messgae" hidden={!this.state.card_verified}>
                  Your CVV Code has been confirmed
                </div>
              </div>
              <div className="proceed-container">
                <Button
                  type="primary"
                  size="large"
                  loading={this.state.card_verifying}
                  onClick={this.handleCvvVerify}
                >
                  Proceed
                </Button>
              </div>
            </div>
          </Modal>
          <Modal
            className="modal-custom"
            onCancel={e => {
              this.setState({ billing_visible: false });
            }}
            visible={this.state.billing_visible}
            footer={null}
            centered={true}
          >
            <WrappedUserCardDetailForm
              card_exists={this.state.card_details}
              linkUserCardInformation={this.linkUserCardInformation}
              button_loading={this.state.billing_loading}
              country_list_success={this.props.country_list_success}
            />
          </Modal>
          <Modal
            className="modal-confirm"
            onCancel={() => {
              this.setState({ subscriptionConfirmation: false });
            }}
            visible={this.state.subscriptionConfirmation}
            footer={false}
            centered={true}
          >
            <h2>Cancel Subscription</h2>
            <p>
              Cancellation will be effective at the end of your {this.getCurrentPlanEndDate(invoice_data)}
            </p>
            <Button.Group>
              <Button
                className="has-width"
                size="large"
                type="danger"
                onClick={this.deactivateAccount}
              >
                Cancel My Subscription
              </Button>
            </Button.Group>
          </Modal>

          <Modal
            className="modal-custom"
            onCancel={this.handleAccInfoCancel}
            visible={this.state.acc_holder_info_mode === 'EDIT'}
            footer={false}
          >
            <h2>Edit Account Holder Infornation</h2>
            <AccHolderInfo
              handleCancel={this.handleAccInfoCancel}
              updateBillingInfo={this.props.actions.updateBillingInfo}
              subscription_id={this.state.subscription_id}
              billing_details={this.state.billing_details}/>
          </Modal>

          <Modal
            onCancel={() => { this.setState({
              showContactUsModal: false,
            });}}
            visible={this.state.showContactUsModal}
            footer={null}
            title="Send us a message"
          >
            <ContactUsComponent handleCancel={this.hideContactUsModal} />
          </Modal>
          <StrictLoader
            show={this.state.subscription_change_loading}
            message="Your payment is being processed..."
          />
        </div>
      </main>
    );
  }
}

export default SubscriptionLayout;
