import React, { Component } from 'react';
import {
  Input,
  Button,
  Form,
  Radio,
  Checkbox,
  Card,
  notification,
  Avatar,
} from 'antd';
import Dropzone from 'react-dropzone';

import MobileInput from '@components/common/MobileNumberLayout';
import './AddEditUserLayout.scss';

const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;

class AddEditUserLayout extends Component {
  state = {
    confirmDirty: false,
    initial_forms_selected: {},
    current_forms_selected: {},
    user_type: 'User',
    user_detail: null,
    loading: true,
    domains: [],
    countries: [],
  };

  componentDidMount() {
    this.setState(
      {
        mode: this.props.location.pathname === '/users/add' ? 'add' : 'edit',
      },
      () => {
        if (this.state.mode !== 'add') {
          this.props.actions.fetchSubscriptionUserDetails({
            user_id: this.props.match.params.user_id,
          });
        }
      },
    );
    //Only fetch verified domains associated with the subscription
    this.props.domain_actions.fetchDomains({
      verified: true,
    });
  }

  componentWillReceiveProps(nextprops) {
    if (nextprops !== this.props) {
      if (
        nextprops.domain_data_success &&
        nextprops.domain_data_success !== this.props.domain_data_success
      ) {
        this.setState({
          domains: nextprops.domain_data_success.companies,
          subscription_id: localStorage.getItem('subscription_id'),
          loading: false,
        });
      }
      if (
        nextprops.user_detail_success &&
        this.props.user_detail_success !== nextprops.user_detail_success
      ) {
        let user_detail = nextprops.user_detail_success['user'],
          forms = {};
        if (user_detail === undefined) return;
        user_detail['domain_forms'] &&
          user_detail['domain_forms'].forEach(function(domain) {
            forms[domain.id] = [];
            domain['forms'].forEach(function(form) {
              forms[domain.id].push(form.id);
            });
          });

        this.props.form.setFieldsValue({
          uemail: user_detail['email'],
          first_name: user_detail['first_name'],
          last_name: user_detail['last_name'],
        });

        this.setState(
          {
            user_detail: user_detail,
            user_type: user_detail['type'],
            profile_image_url: user_detail['avatar'],
            current_forms_selected: Object.assign({}, forms),
            initial_forms_selected: Object.assign({}, forms),
            loading: false,
          },
          this.updateMobileInput,
        );
      } else if (
        nextprops.user_edit_success &&
        this.props.user_edit_success !== nextprops.user_edit_success
      ) {
        notification.open({
          message: 'Success',
          description: nextprops.user_edit_success,
          className: 'notification-success',
        });
        this.props.history.push('/users');
      } else if (
        nextprops.user_add_success &&
        this.props.user_add_success !== nextprops.user_add_success
      ) {
        notification.open({
          message: 'Success',
          description: nextprops.user_add_success,
          className: 'notification-success',
        });
        this.props.history.push('/users');
      } else if (
        nextprops.user_add_error &&
        this.props.user_add_error !== nextprops.user_add_error
      ) {
        notification.open({
          message: 'Error',
          description: nextprops.user_add_error,
          className: 'notification-error',
        });
      }
    }
  }

  generateSelectedFormList = () => {
    let forms = [];
    for (let domain in this.state.current_forms_selected) {
      this.state.current_forms_selected[domain].forEach(new_form_id => {
        forms.push(new_form_id);
      });
    }
    return forms;
  };

  generateDeSelectedFormList = () => {
    let current_forms = [],
      deselect_forms = [];

    for (let domain in this.state.current_forms_selected) {
      current_forms = current_forms.concat(
        this.state.current_forms_selected[domain],
      );
    }
    for (let domain in this.state.initial_forms_selected) {
      this.state.initial_forms_selected[domain].forEach(initial_form_id => {
        if (current_forms.indexOf(initial_form_id) === -1) {
          deselect_forms.push(initial_form_id);
        }
      });
    }

    return deselect_forms;
  };

  handleEditSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      const phoneNumberData = this.refs.mobileInput.getPhoneNumber();
      if (!err && phoneNumberData) {
        //Format the data received and fire a request to register the user...
        let forms_selected = this.generateSelectedFormList(),
          forms_deselected = this.generateDeSelectedFormList();
        var user_update_data = {
          first_name: values.first_name,
          last_name: values.last_name,
          user_type: this.state.user_type,
          contact: JSON.stringify(phoneNumberData),
          subscription_id: this.state.subscription_id,
          forms_selected: forms_selected,
          forms_deselected: forms_deselected,
          subscription_user: true,
        };
        if (this.state.profile_image_file) {
          user_update_data['avatar'] = this.state.profile_image_file;
        }
        this.props.actions.editSubscriptionUser(
          {
            id: this.props.match.params.user_id,
          },
          user_update_data,
        );
      }
    });
  };

  handleAddSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      const phoneNumberData = this.refs.mobileInput.getPhoneNumber();
      if (!err && phoneNumberData) {
        let invited_by_user = JSON.parse(localStorage.getItem('user'));
        let forms_selected = this.generateSelectedFormList();
        var user_registration_data = {
          email: values.uemail,
          first_name: values.first_name,
          last_name: values.last_name,
          user_type: this.state.user_type,
          contact: JSON.stringify(phoneNumberData),
          subscription_id: this.state.subscription_id,
          forms: forms_selected,
          invited_by:
            invited_by_user.first_name + ' ' + invited_by_user.last_name,
          subscription_user: true,
        };

        if (this.state.profile_image_file) {
          user_registration_data['avatar'] = this.state.profile_image_file;
        }
        this.props.actions.addSubscriptionUser(user_registration_data);
      }
    });
  };

  editCheckboxSelected = (domain_id, checkedValue) => {
    let current_forms_selected = Object.assign(
      this.state.current_forms_selected,
      {
        [domain_id]: checkedValue,
      },
    );
    this.setState({
      current_forms_selected: current_forms_selected,
    });
  };

  addCheckboxSelected = (domain_id, checkedValue) => {
    let current_forms_selected = Object.assign(
      this.state.current_forms_selected,
      {
        [domain_id]: checkedValue,
      },
    );
    this.setState({
      current_forms_selected: current_forms_selected,
    });
  };

  changeUserType = e => {
    this.setState({
      user_type: e.target.value,
    });
  };

  onFilesDrop = files => {
    if (files.length) {
      this.setState({
        profile_image_file: files[0],
        profile_image_url: files[0].preview,
        profile_image_error: false,
      });
    } else {
      this.setState({
        profile_image_error: true,
      });
    }
  };

  updateMobileInput = () => {
    if (this.state.user_detail && this.state.user_detail.contact) {
      this.refs.mobileInput &&
        this.refs.mobileInput.updateInput(this.state.user_detail.contact);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    let dropper_props = Object.assign(
      {},
      this.state.profile_image_error && {
        help: 'Please upload image having size less than 1MB!',
        validateStatus: 'error',
      },
    );

    let domains =
      this.state.domains &&
      (this.state.user_detail || this.state.mode === 'add') &&
      this.state.domains.map((domain, i) => {
        let checkboxes = domain.forms.map(form => {
          return {
            label: form.name,
            value: form.id,
          };
        });
        return (
          <div key={i} className="assigned-website-container">
            <div className="non-floating-label dark">{domain.title}</div>
            {domain.forms.length ? (
              <CheckboxGroup
                defaultValue={this.state.current_forms_selected[domain.id]}
                style={{ width: '100%' }}
                options={checkboxes}
                onChange={value => {
                  if (this.state.mode === 'add')
                    this.addCheckboxSelected(domain.id, value);
                  else this.editCheckboxSelected(domain.id, value);
                }}
              />
            ) : (
              <div className="non-floating-label">No Forms Available</div>
            )}
          </div>
        );
      });
    return (
      <main className="main-container">
        <div className="manage-edit-add-user">
          <Form
            name="add_user"
            onSubmit={
              this.state.mode === 'add'
                ? this.handleAddSubmit
                : this.handleEditSubmit
            }
          >
            <Card bordered={false} loading={this.state.loading}>
              <div className="profile-pic-container">
                <FormItem {...dropper_props}>
                  <div className="profile-pic">
                    <Avatar
                      size={100}
                      src={
                        this.state.profile_image_url
                          ? this.state.profile_image_url
                          : '/images/profile_placeholder.svg'
                      }
                    />
                  </div>
                  <Dropzone
                    className="dropzone-wrapper"
                    maxSize={1000000}
                    accept="image/*"
                    onDrop={this.onFilesDrop}
                  >
                    <Button type="default">{this.state.profile_image_url ? 'Change Profile Picture' : 'Add Profile Picture'}</Button>
                  </Dropzone>
                </FormItem>
              </div>

              <FormItem>
                {getFieldDecorator('first_name', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input first name!',
                      whitespace: true,
                    },
                  ],
                })(<Input placeholder=" " />)}
                <div className="floating-label">First Name</div>
              </FormItem>

              <FormItem>
                {getFieldDecorator('last_name', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input last name!',
                      whitespace: true,
                    },
                  ],
                })(<Input placeholder=" " />)}
                <div className="floating-label">Last Name</div>
              </FormItem>

              <MobileInput ref={'mobileInput'} />

              <FormItem colon={false}>
                {getFieldDecorator('uemail', {
                  rules: [
                    {
                      type: 'email',
                      message: 'The input is not valid Email address!',
                    },
                    {
                      required: true,
                      message: 'Please input Email address!',
                    },
                  ],
                })(
                  <Input
                    placeholder=" "
                    readOnly={this.state.mode !== 'add'}
                  />,
                )}
                <div className="floating-label">Email</div>
              </FormItem>
              <FormItem>
                <RadioGroup
                  value={this.state.user_type}
                  onChange={this.changeUserType}
                >
                  <Radio value="Admin">Admin</Radio>
                  <Radio value="User">User</Radio>
                </RadioGroup>
                <label className="floating-label force-float" htmlFor="">
                  User Type
                </label>
              </FormItem>
              <FormItem>
                <label
                  className="non-floating-label fodomain_data_successrce-float"
                  htmlFor=""
                >
                  Assigned Websites
                </label>
                <div className="assigned-websites-box">{domains}</div>
              </FormItem>

              <Button.Group>
                <Button
                  htmlType="button"
                  type="default"
                  size="large"
                  onClick={e => {
                    this.props.history.push('/users');
                  }}
                >
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary" size="large">
                  Save
                </Button>
              </Button.Group>
            </Card>
          </Form>
        </div>
      </main>
    );
  }
}

export default Form.create()(AddEditUserLayout);
