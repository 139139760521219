import React, { Component } from 'react';
import commonApiService from '@apiService';
import { Button, Card, Form, Input } from 'antd';
import { Link } from 'react-router-dom';

const FormItem = Form.Item;

class ResetLayout extends Component {
  constructor(props) {
    document.title = 'Reset Password | Buzzz.';
    super(props);
    this.state = {
      reset_password: false,
      loading: false,
    };
  }

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('new_password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };
  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm_password'], { force: true });
    }
    callback();
  };

  resetPasswordInitiate = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        var change_password_data = {
          validation_key: this.props.match.params.validation_key,
          password: values.new_password,
        };

        this.setState({
          loading: true,
        });

        commonApiService.put('/reset_password', change_password_data).then(
          successResult => {
            this.setState({
              loading: false,
              reset_password: true,
            });
          },
          errorResult => {
            console.log(errorResult);
            this.props.form.setFields({
              confirm_password: {
                value: this.props.form.getFieldValue('confirm_password'),
                errors: [new Error(errorResult.errors)],
              },
            });
            this.setState({
              loading: false,
            });
          },
        );
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="reset">
        <h2>
          Reset Password<span>.</span>
        </h2>
        <Card bordered={false}>
          <div hidden={this.state.reset_password}>
            <Form onSubmit={this.resetPasswordInitiate}>
              <FormItem>
                {getFieldDecorator('new_password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                  ],
                })(<Input type="password" placeholder=" " />)}
                <div className="floating-label">Enter your password</div>
              </FormItem>
              <FormItem>
                {getFieldDecorator('confirm_password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(
                  <Input
                    type="password"
                    placeholder=" "
                    onBlur={this.handleConfirmBlur}
                  />,
                )}
                <div className="floating-label">Confirm your password</div>
              </FormItem>
              <FormItem>
                <Button
                  className={'auth-cust'}
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  Reset Password
                </Button>
              </FormItem>
            </Form>

            <div className={'redirect'}>
              <Link
                to="/signin"
                onClick={e => {
                  this.setState(
                    {
                      reset_password: false,
                    },
                    function() {
                      this.props.form.resetFields();
                    },
                  );
                }}
              >
                ← Back to Login
              </Link>
            </div>
          </div>

          <div className={'post-block'} hidden={!this.state.reset_password}>
            <p className={'subtittle'}>
              Your password has been reset successfully<span>.</span>
            </p>
            <Link to="/signin">
              {' '}
              <Button
                type="primary"
                className={'auth-cust'}
                loading={this.state.loading}
              >
                Sign in to Continue
              </Button>
            </Link>
          </div>
        </Card>
      </div>
    );
  }
}
export default Form.create()(ResetLayout);
