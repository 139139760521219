import React, { Component } from 'react';
import { Button, Card, Form, Input, notification } from 'antd';
import { Link } from 'react-router-dom';
import commonApiService from '@apiService';

class ForgotLayout extends Component {
  constructor(props) {
    document.title = 'Forgot Password | Buzzz.';
    super(props);
    this.state = {
      reset_link_sent: false,
      display_message: null,
      loading: false,
    };
  }

  forgotPasswordInitiate = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });

        commonApiService.put('/forgot_password', { email: values.email }).then(
          successResult => {
            this.setState({
              reset_link_sent: true,
              display_message: successResult.data.msg,
              loading: false,
            });
            notification.open({
              message: 'Instructions Sent',
              description: successResult.data.msg,
              className: 'notification-success',
            });
            this.props.history.push('/signin');
          },
          errorResult => {
            this.props.form.setFields({
              email: {
                value: this.props.form.getFieldValue('forgot_password_email'),
                errors: [new Error(errorResult.errors)],
              },
            });
            this.setState({
              loading: false,
              display_message: null,
            });
          },
        );
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="forgot">
        <h2>
          Forgot Password<span>?</span>
        </h2>
        <Card bordered={false}>
          <Form onSubmit={this.forgotPasswordInitiate}>
            <Form.Item>
              {getFieldDecorator('email', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not valid Email address!',
                  },
                  {
                    required: true,
                    message: 'Please input your Email address!',
                  },
                ],
              })(<Input placeholder=" " />)}
              <div className="floating-label">Email address</div>
            </Form.Item>
            <Button
              type="primary"
              className={'auth-cust'}
              htmlType="submit"
              loading={this.state.loading}
            >
              Send reset instructions
            </Button>
          </Form>
          <div className={'redirect'}>
            <Link
              to="/signin"
              onClick={() => {
                this.props.form.resetFields();
              }}
            >
              ← Back to Login
            </Link>
          </div>
        </Card>
      </div>
    );
  }
}

export default Form.create()(ForgotLayout);
