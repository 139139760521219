import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Affix } from 'antd';

import './HeaderLayout.scss';

class HeaderLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
      domain_name: null,
      user_limit: 0,
    };
    this.is_plan_expired = localStorage.getItem('is_plan_expired') === 'true';
  }

  componentDidMount() {
    //check the props
  }
  componentWillReceiveProps(nextprops) {
    if (this.props !== nextprops) {
      if (
        nextprops.header_content !== null &&
        nextprops.header_content !== this.props.header_content
      ) {
        this.setState({
          content: nextprops.header_content,
        });
      }
    }
  }

  updateNotifications = (notification_data, type) => {
    this.refs.broadcast.updateNotification(notification_data, type);
  };

  updateUserAccess = user_limit => {
    this.setState({
      user_limit: user_limit,
    });
  };

  render() {
    let header_content = {
      title: '',
      subtitle: '',
    };

    switch (this.props.history.location.pathname) {
      case '/users':
        header_content = {
          subtitle: 'All Users',
          content: (
            <div
              className="header-block-all-users"
              hidden={this.is_plan_expired || this.state.user_limit <= 0}
            >
              <Link to="/users/add">
                <Button className={'no-bg'} size="large" type="default">
                  + Add User
                </Button>
              </Link>
            </div>
          ),
        };
        break;
      case '/users/add':
        header_content = {
          title: (
            <Link to="/users">
              <Icon type="left" /> Users
            </Link>
          ),
          subtitle: 'Add New User',
          content: null,
        };
        break;
      case this.props.history.location.pathname.match(/users\/edit/) &&
        this.props.history.location.pathname.match(/users\/edit/).input:
        header_content = {
          title: (
            <Link to="/users">
              <Icon type="left" /> Users
            </Link>
          ),
          subtitle: 'Edit User',
          content: null,
        };
        break;
      case '/subscription':
        header_content = {
          title: 'Subscription',
          subtitle: 'My Subscription',
          content: null,
        };
        break;
      case '/profile':
        header_content = {
          title: 'Profile',
          subtitle: 'User Profile',
          content: null,
        };
        break;
      case '/faq':
        header_content = {
          title: '',
          subtitle: 'Frequently asked Questions',
          content: null,
        };
        break;
      case this.props.history.location.pathname.match(/domains/) &&
        this.props.history.location.pathname.match(/domains/).input:
        header_content = {
          title: (
            <Link to="/domains">
              <Icon type="left" /> My Websites
            </Link>
          ),
          subtitle: this.state.content,
          content: null,
        };
        break;
      case '/admin/subscription-plans':
        header_content = {
          title: 'Admin Panel',
          subtitle: 'Subscription Plans',
          content: null,
        };
        break;
      case '/admin/configurations':
        header_content = {
          title: 'Admin Panel',
          subtitle: 'Configuration',
          content: null,
        };
        break;
      case '/admin/emailtemplates':
        header_content = {
          title: 'Admin Panel',
          subtitle: 'Email Templates',
          content: null,
        };
        break;
      default:
        header_content = {
          title: '',
          subtitle: '',
          content: null,
        };
    }

    return (
      <Affix offsetTop={localStorage.getItem('appInstalled') === "true" ? 0 : 48}>
        <div className="headerbar">
          <div className="headerbar-block breadcrumb left">
            {header_content.title ? (
              <div className="breadcrumb-title">{header_content.title}</div>
            ) : null}
            <div className="breadcrumb-subtitle">{header_content.subtitle}</div>
          </div>
          <div className="headerbar-block right">{header_content.content}</div>
          <div className="clear-fix" />
        </div>
      </Affix>
    );
  }
}
export default HeaderLayout;
