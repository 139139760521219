import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserLayoutActions from '@actions/UserLayoutAction';
import UserLayout from '@layouts/Users/UserLayout';

// ================================================================================
// USER - Container
// ================================================================================

const mapStateToProps = state => {
  const {
    user_list_success = null,
    user_list_error = null,
  } = state.UserReducer;
  return {
    network_down: state.MainReducer.network_down || false,
    user_list_success,
    user_list_error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(UserLayoutActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserLayout);

// ================================================================================
