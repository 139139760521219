// ================================================================================
// HomeReducer - Reducer
// ================================================================================

const DomainReducer = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_DOMAIN_PRE':
      return { ...state, domain_data_error: null };
    case 'FETCH_DOMAIN_SUCCESS':
      return {
        ...state,
        domain_data_success: action.payload.data,
        domain_data_error: null,
      };
    case 'FETCH_DOMAIN_FAILURE':
      return { ...state, domain_data_error: action.err.errors };
    case 'FETCH_VERIFIED_DOMAIN_SUCCESS':
      return {
        ...state,
        verified_domain_data_success: action.payload.data,
        verified_domain_data_error: null,
      };
    case 'FETCH_VERIFIED_DOMAIN_FAILURE':
      return { ...state, verified_domain_data_error: action.err.errors };
    case 'FETCH_DOMAIN_PREREGISTER_SUCCESS':
      return {
        ...state,
        domain_preregister_success: action.payload.data,
        domain_preregister_error: null,
      };
    case 'FETCH_DOMAIN_PREREGISTER_FAILURE':
      return { ...state, domain_preregister_error: action.err.errors };
    case 'FETCH_DOMAIN_VERIFY_SUCCESS':
      return {
        ...state,
        domain_verify_success: action.payload.data,
        domain_verify_error: null,
      };
    case 'FETCH_DOMAIN_VERIFY_FAILURE':
      return { ...state, domain_verify_error: action.err.errors };
    case 'FORM_EXTRACT_SUCCESS':
      return {
        ...state,
        form_extract_success: action.payload.data,
        form_extract_failure: null,
      };
    case 'FORM_EXTRACT_FAILURE':
      return {
        ...state,
        form_extract_failure: action.err.errors,
        form_extract_success: null,
      };
    case 'FETCH_DOMAIN_REGISTER_SUCCESS':
      return { ...state, domain_verified: false };
    case 'DOMAIN_REGISTER_SUCCESS':
      return {
        ...state,
        domain_register_success: action.payload.data,
        domain_register_error: null,
      };
    case 'DOMAIN_REGISTER_FAILURE':
      return {
        ...state,
        domain_register_error: {
          errors: action.err.errors,
          errorCode: action.err.errorCode,
        },
        domain_register_success: null,
      };
    case 'DOMAIN_FORM_REGISTER_SUCCESS':
      return {
        ...state,
        domain_form_register_success: action.payload.data,
        domain_form_register_error: null,
        form_extract_success: null,
      };
    case 'DOMAIN_FORM_REGISTER_FAILURE':
      return {
        ...state,
        domain_form_register_error: {
          errors: action.err.errors,
          errorCode: action.err.errorCode,
        },
        domain_form_register_success: null,
        retrieve_form_data: false,
      };
    case 'RETRIEVE_FORM_DATA':
      return {
        ...state,
        retrieve_form_data: true,
        expand_form_key: null,
        random: Math.random(),
      };
    case 'DISABLE_FORM_SUBMISSION':
      return {
        ...state,
        retrieve_form_data: false,
        expand_form_key: action.key ? action.key : null,
      };
    case 'RETRIEVE_DOMAIN_DATA_SUCCESS':
      return {
        ...state,
        domain_details_success: action.payload.data,
        domain_details_error: null,
        update_domain_form_success: null,
        header_content: action.payload.data.domain.display_name,
      };
    case 'RETRIEVE_DOMAIN_DATA_FAILURE':
      return {
        ...state,
        domain_details_success: null,
        domain_details_error:
          action.err !== undefined ? action.err.errors : action.err,
      };
    case 'PRE_DOMAIN_UPDATE':
      return {
        ...state,
        update_domain_success: null,
        update_domain_error: null,
      };
    case 'UPDATE_DOMAIN_SUCCESS':
      return {
        ...state,
        update_domain_success: action.payload.data.msg,
        update_domain_error: null,
      };
    case 'UPDATE_DOMAIN_FAILURE':
      return {
        ...state,
        update_domain_success: null,
        update_domain_error: action.err.errors,
      };
    case 'DOMAIN_FORM_UPDATE_SUCCESS':
      return {
        ...state,
        update_domain_form_success: action.payload.data.msg,
        update_domain_form_error: null,
        domain_details_success: null,
      };
    case 'DOMAIN_FORM_UPDATE_FAILURE':
      return {
        ...state,
        update_domain_form_success: null,
        update_domain_form_error: action.err.errors,
      };
    case 'DOMAIN_FORM_DELETE_SUCCESS':
      return {
        ...state,
        delete_domain_form_success: action.payload.data.msg,
        delete_domain_form_error: null,
        domain_details_success: null,
      };
    case 'DOMAIN_FORM_DELETE_FAILURE':
      return {
        ...state,
        delete_domain_form_success: null,
        delete_domain_form_error: action.err.errors,
      };

    case 'RETRIEVE_DOMAIN_USER_DATA_SUCCESS':
      return {
        ...state,
        domain_user_data_success: action.payload.data.users,
        domain_user_data_error: null,
        domain_user_add_success: null,
        domain_user_add_error: null,
      };
    case 'RETRIEVE_DOMAIN_USER_DATA_FAILURE':
      return {
        ...state,
        domain_user_data_success: null,
        domain_user_data_error: action.err.errors,
      };
    case 'RETRIEVE_DOMAIN_USER_ADD_DATA_SUCCESS':
      return {
        ...state,
        domain_user_add_data_success: action.payload.data.users,
        domain_user_add_data_error: null,
      };
    case 'RETRIEVE_DOMAIN_USER_ADD_DATA_FAILURE':
      return {
        ...state,
        domain_user_add_data_success: null,
        domain_user_add_data_error: action.err.errors,
      };
    case 'DOMAIN_USER_ADD_SUCCESS':
      return {
        ...state,
        domain_user_add_success: action.payload.data.msg,
        domain_user_add_error: null,
      };
    case 'DOMAIN_USER_ADD_FAILURE':
      return {
        ...state,
        domain_user_add_success: null,
        domain_user_add_error: action.err.errors,
      };
    case 'DOMAIN_USER_REMOVE_SUCCESS':
      return {
        ...state,
        domain_user_remove_success: action.payload.data.msg,
        domain_user_remove_error: null,
      };
    case 'DOMAIN_USER_REMOVE_FAILURE':
      return {
        ...state,
        domain_user_remove_success: null,
        domain_user_remove_error: action.err.errors,
      };
    default:
      return state;
  }
};

export default DomainReducer;
