const isProduction = process.env.NODE_ENV === 'production';

const development = {
  base_url: 'http://localhost:3000/',
};

const production = {
  base_url: 'https://api.buzzz.io/',
};

module.exports = isProduction ? production : development;
