import React, { Component } from 'react';
import './StrictLoader.scss';

class StrictLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      content: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      if (nextProps.show !== this.state.show) {
        this.setState({
          show: nextProps.show,
        });
      }
      if (nextProps.content !== this.state.content) {
        this.setState({
          content: nextProps.content,
        });
      }
    }
  }

  render() {
    return (
      <div className="loading-overlay" hidden={!this.state.show}>
        <div className="loading-overlay-content">
          {this.state.content ? (
            this.state.content
          ) : (
            <>
              <div className="loading-overlay-text">
                {this.props.title || 'Please wait! This may take few seconds'}
                <span className="dot-animator">
                  <span className="dot-1">.</span>
                  <span className="dot-2">.</span>
                  <span className="dot-3">.</span>
                </span>
              </div>
              <div className="loading-overlay-sub-text">
                {this.props.message}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default StrictLoader;
