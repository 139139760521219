import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  Checkbox,
  Card,
  Form,
  Input,
  message,
  Button,
  notification,
  Popover
} from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import commonApiService from '@apiService';
import HelpAddWebsite from '@components/common/Help/HelpAddWebsite';
import HelpTip from '@components/common/Help/HelpTip';

class Verification extends Component {
  state = {
    hideMailForm: true,
    verified: false,
    loading: false,
    help: false,
  };

  dataStore = {
    url: {
      prefix: null,
      link: null,
      script: null,
    },
  };

  componentWillReceiveProps(nextprops) {
    if (this.props !== nextprops) {
      if (  nextprops.realtime_update !== null ) {
        console.log(nextprops.realtime_update);
        this.setState({
          verified: true,
          loading: false,
        });
      }
    }
  }

  showMailForm = ev => {
    this.setState({
      hideMailForm: !ev.target.checked,
    });
  };

  handleMail = ev => {
    ev.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const params = {
          key: this.dataStore.key,
          email: values.email,
        };
        commonApiService
          .post('/web/email/developer_guide', params)
          .then(successResult => {
            notification.open({
              message: 'Email Sent',
              description: 'Email has been sent to developer!',
              className: 'notification-success',
            });
          })
          .catch(errorResult => {
            notification.open({
              message: 'Email Sending Failed',
              description: errorResult.errors[0].msg,
              className: 'notification-error',
            });
          });
      }
    });
  };

  checkInstallation = () => {
    this.setState({
      loading: true,
    });

    const params = {
      display_name: this.dataStore.display_name,
      url: this.dataStore.url.prefix + this.dataStore.url.link,
      is_ping_allowed: true,
      key: this.dataStore.key,
      email_recipient: this.props.form.getFieldValue('email'),
    };

    commonApiService
      .post('/web/domains/add', params)
      .then(successResult => {
        notification.open({
          message: 'Domain Verification Complete',
          description: 'Domain has been verified successfully',
          className: 'notification-success',
        });
        this.dataStore.domain_id = successResult.data.domain_id;
        this.setState({
          verified: true,
          loading: false,
        });
      })
      .catch(errorResult => {
        notification.open({
          message: 'Domain Verification Error',
          description: errorResult.errors[0].msg,
          className: 'notification-error',
        });

        this.dataStore.domain_id = 0;
        this.setState({
          loading: false,
        });
      });
  };

  nextStep = () => {
    this.setState({
      help: false
    });
    this.props.nextStep(this.dataStore);
  };

  prevStep = () => {
    this.setState({
      help: false
    });
    this.props.prevStep();
  }

  toggleHelp = () => {
    this.setState({
      help: !this.state.help
    })
  };

  componentWillMount() {
    const {dataStore} = this.props;

    if (dataStore) {
      this.dataStore = dataStore;
    }
    window.scrollTo(0, 0);
  }

  render() {
    const {getFieldDecorator} = this.props.form;

    const getPopupContainerProps = this.props.parentSetup ? {} : {
      getPopupContainer: () => document.getElementById('verification-card')
    };

    return (
      <div className={'verification'}>
        <h3>{this.state.verified ? 'Success!' : 'Copy Code'}</h3>

        <Popover
          content={<HelpAddWebsite/>}
          {...getPopupContainerProps}
          overlayClassName={'help-popover'}
          placement={'rightTop'}
          visible={this.state.help}
        >
          <Card bordered={false} hidden={this.state.verified} className={this.state.help ? 'move-aside' : ''} id={'verification-card'} >
            <p className={'light'}>Secondly we need to copy (and paste) the code below into your website's header.</p>
            <p className={'light'}>This is very easy to do, however if you get stuck you can click the help link below.</p>
            <p className={'light'}>Alternatively if you have a 'web guy or girl' in your team, you can forward this section to them.</p>

            <p className={'light large link'}>
              {this.dataStore.url.prefix}
              {this.dataStore.url.link}
            </p>

            <Form onSubmit={this.handleMail}>
              <p className={'label-badge'}>
                <div className={'left-badge'}>
                  <span className={'number'}>1</span>
                </div>
                {' '}
                Copy and paste below code to your website header {<HelpTip help={this.state.help} toggleHelp={this.toggleHelp}/>}
              </p>
              <div className="code-snippet" >
                <div className={'line-num'}>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                  <span>6</span>
                </div>

                <Input.TextArea
                  value={this.dataStore.script}
                  autosize={{minRows: 7, maxRows: 7}}
                />
                <CopyToClipboard
                  text={this.dataStore.script}
                  onCopy={() => {
                    message.success('copied!');
                  }}
                >
                  <Button className={'copy-code'} type="default">
                    Copy code
                  </Button>
                </CopyToClipboard>
              </div>
              <Form.Item>
                <div className="checkbox-wrap">
                  <div className={'checkbox-input'}>
                    <Checkbox onChange={this.showMailForm}/>
                  </div>
                  <p className={'checkbox-label light'}>
                    I don't understand coding. Send this code to my developer
                  </p>
                </div>
              </Form.Item>
              <div className="devmail-wrap" hidden={this.state.hideMailForm}>
                <div className="devmail-input">
                  <Form.Item>
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          required: true,
                          message: `Please input your Devloper's Email ID!`,
                        },
                      ],
                    })(<Input placeholder={' '}/>)}
                    <div className="floating-label">Enter a Email address</div>
                  </Form.Item>
                </div>
                <Button size={'large'} htmlType={'submit'} type={'default'}>
                  Send email
                </Button>
              </div>
            </Form>
            <div className={'btn-grp'}>
              <Button.Group>
                <Button
                  hidden={this.props.verifyFlag}
                  size={'large'}
                  type={'default'}
                  onClick={this.prevStep}
                >
                  Back
                </Button>
                <Button
                  loading={this.state.loading}
                  size={'large'}
                  type={'primary'}
                  onClick={this.checkInstallation}
                >
                  Check Installation
                </Button>
              </Button.Group>
            </div>
          </Card>
        </Popover>
        <Card
          className={'verified-card'}
          bordered={false}
          hidden={!this.state.verified}
        >
          <h4>Congratulations! your domain has been verified</h4>
          <Button
            size={'large'}
            className={'has-width'}
            type={'primary'}
            onClick={this.nextStep}
          >
            Next
          </Button>
        </Card>
      </div>
    );
  }
}

const VerificationForm = Form.create()(Verification);

// container

const mapStateToProps = state => {
  const {
    realtime_update = null,
  } = state.MainReducer;
  return {
    realtime_update: realtime_update,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerificationForm);