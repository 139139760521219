import React, { Component } from 'react';
import commonApiService from '@apiService';

import Plans from '@components/layouts/Subscription/Plan';

class PlanLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: [],
      fullFeatures: false,
      plans: [],
      inview: true,
    };
    window.scrollTo(0, 0);
  }

  toggleFullFeatures = () => {
    this.setState({
      fullFeatures: !this.state.fullFeatures,
    });
  };

  componentWillMount() {
    commonApiService
      .fetch('/plans', null)
      .then(successResult => {
        if (successResult.success) {
          this.setState({
            plans: successResult.data.subscriptions,
          });
        } else {
          console.log('error while fetching plans');
        }
      })
      .catch(errorResult => {
        console.log('error while fetching plans');
      });
  }

  setInView = status => {
    this.setState({
      inview: status,
    });
  };

  render() {
    const { plans, fullFeatures, inview } = this.state;

    return (
      <div className="plans">
        <div className="container">
          <h2>
            Pick a plan and start converting<span>.</span>
          </h2>
          <p>
            <span>14 days free trial.</span> Sign-up in 30 seconds. Change plans
            or cancel at any time.
          </p>
          <Plans
            plans={plans}
            fullFeature={this.toggleFullFeatures}
            nextStep={this.props.nextStep}
            setInView={this.setInView}
          />
        </div>
        {!inview ? (
          <div
            className={
              fullFeatures
                ? 'bottom-btn visible mobile-hide'
                : 'bottom-btn mobile-hide'
            }
          />
        ) : null}
      </div>
    );
  }
}

export default PlanLayout;
