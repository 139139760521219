// ================================================================================
// HomeReducer - Reducer
// ================================================================================

const initialState = {
  user_list_error: null,
  user_add_error: null,
  user_edit_error: null,
  user_list_success: null,
  user_add_success: null,
  user_edit_success: null,
  user_detail_success: null,
  user_detail_error: null,
  user_access_limit: null,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LIST_FETCH_PRE':
      return {
        ...state,
        user_list_error: null,
        user_add_error: null,
        user_edit_error: null,
      };
    case 'USER_LIST_FETCH_SUCCESS':
      return {
        ...state,
        user_list_success: action.payload.data,
        user_list_error: null,
        user_add_success: null,
        user_edit_success: null,
        user_detail_success: null,
      };
    case 'USER_LIST_FETCH_FAILURE':
      return {
        ...state,
        user_list_error: action.err.errors,
        user_add_success: null,
      };
    case 'USER_DETAIL_FETCH_SUCCESS':
      return {
        ...state,
        user_detail_success: action.payload.data,
        user_detail_error: null,
        user_add_success: null,
        user_list_success: null,
      };
    case 'USER_DETAIL_FETCH_FAILURE':
      return {
        ...state,
        user_detail_success: null,
        user_detail_error: action.err.errors,
        user_add_success: null,
      };
    case 'USER_ADD_SUCCESS':
      return {
        ...state,
        user_add_success: action.payload.data.msg,
        user_add_error: null,
      };
    case 'USER_ADD_FAILURE':
      return {
        ...state,
        user_add_success: null,
        user_add_error: action.err.errors,
      };
    case 'EDIT_USER_DETAIL_SUCCESS':
      return {
        ...state,
        user_edit_success: action.payload.data.msg,
        user_edit_error: null,
      };
    case 'EDIT_USER_DETAIL_FAILURE':
      return {
        ...state,
        user_edit_success: null,
        user_edit_error: action.err.errors,
      };
    case 'USER_USAGE_LIMIT':
      return {
        ...state,
        user_access_limit: action.limit,
      };
    default:
      return state;
  }
};

export default UserReducer;
