import React, {Component} from 'react';
import {Button, Form, Input, Select, Row, Col, Tooltip} from 'antd';

class SingleForm extends Component {
  state = {
    fields: [],
    flags: {
      name: true,
      tel: true,
    },
  };

  componentWillMount() {
    const {websiteForm} = this.props;

    let fields = websiteForm.filtered_content || websiteForm.form_fields;

    fields = fields.map(item => {
      const data = {
        key: item.key,
        label: item.label,
        type: item.type || null,
        content: item.content || null,
        detectedType: item.detectedType,
        isActive: true,
      };
      return data;
    });

    this.setState({
      fields: fields,
    });
  }

  setSelected = index => {
    const fields = this.state.fields;
    fields[index].isActive = !fields[index].isActive;
    this.setState({
      fields: fields,
    });
  };

  setFormFieldData = () => {
    let flags = {
      name: false,
      tel: false,
    };

    let fields = this.state.fields;
    let finalFields = fields.map((item, index) => {
      if (item.detectedType === 'tel') {
        flags.tel = true;
      }
      if (item.detectedType === 'name') {
        flags.name = true;
      }

      const data = {
        key: item.key,
        label: item.label,
        type: item.type,
        detectedType: item.detectedType,
        content: item.content || null,
        isActive: item.isActive
      };

      return data;
    });

    this.setState({
      flags: flags,
    });

    return {
      name: this.refs.formName.state.value,
      url: this.props.formUrl,
      form_fields: finalFields,
      warn: !(flags.name && flags.tel),
    };
  };

  setType = (ev, index) => {
    const fields = this.state.fields;

    fields[index].detectedType = ev;

    this.setState({
      fields: fields,
    });
  };

  render() {
    const {flags } = this.state;
    const {websiteForm, formKey} = this.props;

    const formFields = this.state.fields.map((field, index) => {
      return (
        <div className={field.isActive ? 'input-wrap' : 'input-wrap inactive' } key={index}>
          <Row>
            <Col xs={14}>
              <div className="text-input">
                <div className={'label-badge'}>
                  {
                    field.isActive ? (
                      <Tooltip title={'remove this form field, it will not show in your buzzz form submissions'}>
                        <Button
                          disabled={
                            field.detectedType === 'name' ||
                            field.detectedType === 'email' ||
                            field.detectedType === 'tel'
                          }
                          onClick={() => {
                            this.setSelected(index)
                          }}
                          className={'left-badge'}
                          type={'danger'}
                          icon={'minus'} shape="circle"/>
                      </Tooltip>
                    ) : (
                      <Button
                        disabled={
                          field.detectedType === 'name' ||
                          field.detectedType === 'email' ||
                          field.detectedType === 'tel'
                        }
                        onClick={() => {
                          this.setSelected(index)
                        }}
                        className={'left-badge'}
                        type={'primary'}
                        icon={'plus'} shape="circle"/>
                    )
                  }
                </div>
                <Input placeholder={' '} value={field.label} readOnly/>
              </div>
            </Col>
            <Col xs={10}>
              <div className="select-input">
                <Form.Item>
                  <Select
                    placeholder={'Select Type'}
                    dropdownClassName={'form-field-dropdown'}
                    defaultValue={field.detectedType}
                    onChange={ev => {
                      this.setType(ev, index);
                    }}
                  >
                    <Select.Option value={'name'}>Name</Select.Option>
                    <Select.Option value={'email'}>Email Address</Select.Option>
                    <Select.Option value={'tel'}>Telephone Number</Select.Option>
                    <Select.Option value={null}>Other</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
      );
    });
    return (
      <div>
        <div>
          <Input
            ref={'formName'}
            placeholder={' '}
            defaultValue={websiteForm.name || `Form ${formKey + 1}`}
          />
          <div className="floating-label force-float">Form Name</div>
        </div>

        {formFields}

        {flags.name ? null : (
          <p className={'danger-color'}>Please make sure to map 1 form field to 'name'</p>
        )}
        {flags.tel ? null : (
          <p className={'danger-color'}>please make sure to map 1 form field to 'Telephone Number'</p>
        )}
      </div>
    );
  }
}

export default SingleForm;
