import {Button, Card, Modal} from 'antd';
import React, {Component} from 'react';
import ContactUsComponent from '@components/common/ContactUsLayout';
import './ActionList.scss';

class SetupActionList extends Component{


  state = {
    showContactUsModal: false
  };

  hideContactUsModal = () => {
    this.setState({
      showContactUsModal: false,
    });
  };

  render() {
    const { setChoice } = this.props;
    return (
      <>
        <div>
          <Card>
            <img src={'/images/domains/form.svg'} alt="formImg" />
            <h4>I already have a form</h4>
            <h4>on my website</h4>
            <p>
              To get the best out of Buzzz, ensure your form has a phone number field for your new leads. That way you can call them back instantly.
            </p>
            <Button
              type={'primary'}
              size={'large'}
              className={'has-width'}
              onClick={() => {
                setChoice(1);
              }}
            >
              Go
            </Button>
          </Card>
          <Card className={'inactive'}>
            <img src={'/images/domains/code.svg'} alt="codeImg" />
            <h4>Don't have a form yet.</h4>
            <h4>Create one now!</h4>
            <p>So you know your html from your css, your jquery from your c#.</p>
            <Button type={'default'} size={'large'} className={'has-width'}>
              Go
            </Button>
            <p className={'available-soon'}>Available Soon</p>
          </Card>
          <Card className={'inactive'}>
            <img src={'/images/domains/nocode.svg'} alt="nocodeImg" />
            <h4 className={'hidden'}>Mi hablo no code</h4>
            <h4>Mi hablo no code</h4>
            <p>
              When you look at web code you start sweating. Don't panic - you can
              just auto forward your emails Buzzz.
            </p>
            <Button type={'default'} size={'large'} className={'has-width'}>
              Go
            </Button>
            <p className={'available-soon'}>Available Soon</p>
          </Card>
        </div>
        <p className={'help-call'}>
          Let us help you. <span onClick={(e) => { this.setState({showContactUsModal: true})}}>Click here</span> if you want us to call you.
        </p>
        <Modal
          onCancel={this.hideContactUsModal}
          visible={this.state.showContactUsModal}
          footer={null}
          title="Call back request form"
        >
          <ContactUsComponent callBack={true} handleCancel={this.hideContactUsModal} />
        </Modal>
      </>
    );
  }
}

export default SetupActionList;
