import commonApiService from '@apiService';

// --------------------------------------------------------------------------------
export function LogoutSuccess() {
  return {
    type: 'LOGOUT_SUCCESS',
  };
}
export function LoginSuccess(payload) {
  return {
    type: 'LOGIN_SUCCESS',
    payload,
  };
}

export function LoginFailure(err) {
  return {
    type: 'LOGIN_FAILURE',
    err,
  };
}

export function PasswordResetLinkSuccess(payload) {
  return {
    type: 'PASSWORD_RESET_LINK_SUCCESS',
    payload,
  };
}

export function PasswordResetLinkFailure(err) {
  return {
    type: 'PASSWORD_RESET_LINK_FAILURE',
    err,
  };
}

export function PasswordResetSuccess(payload) {
  return {
    type: 'PASSWORD_RESET_SUCCESS',
    payload,
  };
}

export function PasswordResetFailure(err) {
  return {
    type: 'PASSWORD_RESET_FAILURE',
    err,
  };
}

export function ProcessRealTimeData(data) {
  return {
    type: 'REALTIME_UPDATES',
    data,
  };
}

export function FetchUserNotificationSuccess(payload) {
  return {
    type: 'FETCH_USER_NOTIFICATION_SUCCESS',
    payload,
  };
}

export function FetchUserNotificationFailure(err) {
  return {
    type: 'FETCH_USER_NOTIFICATION_FAILURE',
    err,
  };
}

export function requestLogin(params) {
  return dispatch => {
    commonApiService
      .post('/login', params)
      .then(successResult => {
        dispatch(LoginSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(LoginFailure(errorResult));
      });
  };
}

export function logoutUser() {
  return dispatch => {
    dispatch(LogoutSuccess());
  };
}

export function requestPasswordResetLink(params) {
  return dispatch => {
    commonApiService
      .put('/forgot_password', params)
      .then(successResult => {
        dispatch(PasswordResetLinkSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(PasswordResetLinkFailure(errorResult));
      });
  };
}

export function changeUserPassword(params) {
  return dispatch => {
    commonApiService
      .put('/reset_password', params)
      .then(successResult => {
        dispatch(PasswordResetSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(PasswordResetFailure(errorResult));
      });
  };
}

export function processRealtimeData(data) {
  return dispatch => {
    dispatch(ProcessRealTimeData(data));
  };
}

export function fetchUserNotifications(params) {
  return dispatch => {
    commonApiService
      .list('/user/notifications', params)
      .then(successResult => {
        dispatch(FetchUserNotificationSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(FetchUserNotificationFailure(errorResult));
      });
  };
}

/*Functions end*/
