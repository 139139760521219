import React, { Component } from 'react';
import { Button, Card, Form, Input, Checkbox, Alert } from 'antd';
import './SignInLayout.scss';
import { Link } from 'react-router-dom';
import commonApiService from '@apiService';
// import { parseQuery } from '@queryHelper';
import moment from 'moment';
const AUTOFILLED = 'is-autofilled';
const onAutoFillStart = el => el.classList.add(AUTOFILLED);
const onAutoFillCancel = el => el.classList.remove(AUTOFILLED);

const onAnimationStart = ({ target, animationName }) => {
  switch (animationName) {
    case 'onAutoFillStart':
      return onAutoFillStart(target);
    case 'onAutoFillCancel':
      return onAutoFillCancel(target);
    default:
  }
};

class SignInLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      verified: false,
    };
  }

  componentWillMount() {
    console.log(this.props.location.search);
    if (this.props.location.search) {
      // const query = parseQuery(this.props.location.search);
      this.setState({
        verified: true,
      });
    }
  }

  componentDidMount() {
    document.title = 'Signin | Buzzz.';
    let inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.addEventListener('animationstart', onAnimationStart, false);
    });
  }

  setStatePostLoginSucess(successResult) {
    localStorage.setItem('token', successResult.data.token);
    localStorage.setItem('subscription_id', successResult.data.subscription_id);
    localStorage.setItem('user', JSON.stringify(successResult.data.user));
    localStorage.setItem('owner', successResult.data.owner);
    localStorage.setItem('super_user', successResult.data.super_user);
    localStorage.setItem('appInstalled', successResult.data.isUsingApp);

    if (!successResult.data.super_user) {
      if (successResult.data.usage_limit) {
        localStorage.setItem(
          'ping_my_domain',
          successResult.data.usage_limit.allowed,
        );
      }
      localStorage.setItem('trial_expires_in',  successResult.data.user.trial_expires_in );

      localStorage.setItem(
        'subscription_expiry_date',
        moment
          .unix(successResult.data.subscription.expiry_date)
          .format('YYYY-MM-DD'),
      );
      localStorage.setItem(
        'is_plan_expired',
        successResult.data.subscription.is_plan_expired,
      );
      //
      let customConf =
        successResult.data.custom_configurations[0]
          .notifications_followupStatus;
      localStorage.setItem(
        'follow_up_status_general',
        JSON.stringify(customConf['General']),
      );
      customConf = [
        ...customConf['Called'],
        ...customConf['General'],
        ...customConf['Not_reached'],
      ];
      let followUpStatus = {};
      for (let i = 0; i < customConf.length; i++) {
        followUpStatus[customConf[i].id] = customConf[i];
      }
      localStorage.setItem('follow_up_status', JSON.stringify(followUpStatus));

      document.getElementById('overlay').style.opacity = 1;
      document.getElementById('overlay').style.display = 'block';
      this.props.history.push('/dashboard');
    } else {
      this.props.history.push('/admin/subscription-plans');
    }
  }

  signInInitiate = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        commonApiService
          .post(
            '/login',
            Object.assign(values, {
              device_type: 'webapp',
              device_token: localStorage.getItem('fcm_token'), //Needs to be updated over time
            }),
          )
          .then(
            successResult => {
              this.setStatePostLoginSucess(successResult);
            },
            errorResult => {
              this.props.form.setFields({
                password: {
                  value: this.props.form.getFieldValue('password'),
                  errors: [new Error(errorResult.errors)],
                },
              });

              this.setState({
                loading: false,
              });
            },
          );
      }
    });
  };

  responseGoogle = resp => {
    this.setState({
      loading: true,
    });
    var profile = resp.profileObj;
    commonApiService
      .post(
        '/login/social',
        Object.assign(profile.email, {
          device_type: 'webapp',
          device_token: localStorage.getItem('fcm_token'), //Needs to be updated over time
        }),
      )
      .then(
        successResult => {
          this.setStatePostLoginSucess(successResult);
        },
        errorResult => {
          this.props.form.setFields({
            password: {
              value: this.props.form.getFieldValue('password'),
              errors: [new Error(errorResult.errors)],
            },
          });

          this.setState({
            loading: false,
          });
        },
      );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="login">
        {this.state.verified ? (
          <Alert
            message="Your email has been verified. Please sign in to Dashboard!"
            type="success"
            closable
          />
        ) : null}
        <h2>
          Sign in<span>.</span>
        </h2>
        <Card bordered={false}>
          <Form onSubmit={this.signInInitiate}>
            <Form.Item>
              {getFieldDecorator('email', {
                validateTrigger: 'onBlur',
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not valid Email address!',
                  },
                  {
                    required: true,
                    message: 'Please input your Email address!',
                  },
                ],
              })(<Input placeholder=" " />)}
              <div className="floating-label">Email address</div>
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ],
              })(<Input type="password" placeholder=" " />)}
              <div className="floating-label">Password</div>
            </Form.Item>

            <Form.Item>
              <Checkbox>Keep me signed in</Checkbox>
              <Link
                to="/forgot"
                onClick={() => {
                  this.props.form.resetFields();
                }}
              >
                Forgot your password?
              </Link>
            </Form.Item>
            <Button
              type="primary"
              className={'auth-cust'}
              htmlType="submit"
              loading={this.state.loading}
            >
              Sign in
            </Button>
          </Form>
        </Card>
      </div>
    );
  }
}

export default Form.create()(SignInLayout);
