import React, { Component } from 'react';
import { Modal } from 'antd';

import SetupActionList from '@components/layouts/AddWebsite/subcomponent/ActionList';

class AddFormModal extends Component {
  state = {
    visible: false,
  };

  showAddForm = () => {
    this.setState({
      visible: true,
    });
  };
  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  getChoice = choice => {
    switch (choice) {
      case 1:
        this.props.history.push(
          '/add-form/' +
            this.props.domainId +
            '?name=' +
            btoa(this.props.domainName),
        );
        break;
      default:
        console.log('no action');
    }
  };

  render() {
    return (
      <Modal
        visible={this.state.visible}
        onCancel={this.closeModal}
        className={'modal-large'}
        footer={null}
      >
        <h2>Add a Form {this.props.domainName ? `| ${this.props.domainName}` : ''}</h2>
        <div className={'setup-action'}>
          <SetupActionList setChoice={this.getChoice} />
        </div>
      </Modal>
    );
  }
}

export default AddFormModal;
