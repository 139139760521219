import React, { Component } from 'react';
import {Switch, Route, Link} from "react-router-dom";
import { Affix } from "antd";
import './Auth.scss'

import SignInLayout from './SignIn/SignInLayout';
import ForgotLayout from './Forgot/ForgotLayout';
import ResetLayout from './Reset/ResetLayout';
import ActivateLayout from './Activate/ActivateLayout';

class AuthLayout extends Component{


    render() {

        return (
            <div className={'auth'}>
                <Affix offsetTop={0}>
                    <div className="header">
                        <div className={'container'}>
                            <div className="logo-container">
                                <img src={'/images/buzzz_black.svg'} alt=""/>
                            </div>
                            <div className="extra">
                               <span className={'mobile-hide'}>Don't have an account? </span><Link to={'/signup'}>Sign up</Link>
                            </div>
                        </div>
                    </div>
                </Affix>
                <div className="main">
                    <Switch>
                        <Route path={'/activate/:activation_key'} component={ActivateLayout}/>
                        <Route path={'/signin'} component={SignInLayout}/>
                        <Route path={'/forgot'} component={ForgotLayout}/>
                        <Route path={'/resetpassword/:validation_key'} component={ResetLayout}/>
                    </Switch>
                </div>
            </div>
        )
    }

}

export default AuthLayout;