import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Row, Col, Card, Select, Button, Badge, Tooltip, Skeleton} from 'antd';
import moment from 'moment';
import commonApiService from '@apiService';

import arrowUpRed from './images/arrow_up_red.svg';
import arrowUpGreen from './images/arrow_up_green.svg';
import arrowDownRed from './images/arrow_down_red.svg';
import arrowDownGreen from './images/arrow_down_green.svg';
import arrowNA from './images/arrow_na.svg';

import Graph from './Graph';

const Option = Select.Option;

const loaderImage = (
  <div className={'svg-container'}>
    <svg width="217px" height="230px" viewBox="0 0 217 230" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Dashboard-preloader" transform="translate(-340.000000, -226.000000)" fill="#F5F5F5">
          <g id="Group-2-Copy-3" transform="translate(310.000000, 140.000000)">
            <g id="Group-6" transform="translate(30.000000, 86.000000)">
              <g id="Loader_graph">
                <rect id="Rectangle" x="0" y="0" width="217" height="200"></rect>
                <rect id="Rectangle" x="0" y="217" width="36.6621005" height="13"></rect>
                <rect id="Rectangle-Copy-2" x="44.5890411" y="217" width="36.6621005" height="13"></rect>
                <rect id="Rectangle-Copy-3" x="89.1780822" y="217" width="36.6621005" height="13"></rect>
                <rect id="Rectangle-Copy-4" x="134.757991" y="217" width="36.6621005" height="13"></rect>
                <rect id="Rectangle-Copy-5" x="180.3379" y="217" width="36.6621005" height="13"></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
)

class DomainCardLayout extends Component {
  constructor(props) {
    super(props);
    this.chartTooltipId = Math.floor(100 * Math.random());
    this.state = {
      date_picker_range: [moment().subtract(7, 'd'), moment()],
      website: {
        form_count: 0,
        notifications: 0,
      },
      enquiry: {
        labels: [],
        data: [],
        dataOld: [],
        count: 0,
        countOld: 0
      },
      response: {
        labels: [],
        data: [],
        dataOld: [],
        count: 0,
        countOld: 0
      },
      graph_loading: true,
    };

    this.fullAccess = false;
    if (localStorage && localStorage.user) {
      let user = JSON.parse(localStorage.user);
      let owner = localStorage.owner === 'true';
      this.fullAccess = owner ? true : user.access_level === 'FullAccess';
    }

    document.title = 'Dashboard | Buzzz.';
  }

  componentDidMount() {
    this.handleGraph(this.props.website);
  }

  onDateRangeSelected = selected => {
    let date = [moment().subtract(selected, 'd'), moment()];

    if (date[0] === undefined || date[1] === undefined) return;

    this.setState(
      {
        date_picker_range: date,
        graph_loading: true,
      },
      () => {
        commonApiService
          .fetch('web/dashboard_stats', {
            domain_id: this.state.website.domain_id,
            from_date: this.state.date_picker_range[0].unix(),
            to_date: this.state.date_picker_range[1].unix(),
          })
          .then(successResult => {
            this.handleGraph(successResult.data.stats[0]);
          })
          .catch(errorResult => {
            this.setState({
              website: {
                form_count: 0,
                notifications: 0,
                domain_id: null,
                domain_name: '',
              },
              graphs: {
                labels: [],
                enquiry: [],
                enquiry_old: [],
                response: [],
                response_old: [],
                enquiry_count: 0,
                response_count: 0,
                enquiry_old_count: 0,
                response_old_count: 0,
              },
              graph_loading: false,
            });
          });
      },
    );
  };

  handleGraph = data => {
    let current_enquiry = data.current_stats_enquiry;
    let current_response = data.current_stats_response;

    let graphData = {
      labels: [],
      enquiry: [],
      response: [],
      enquiry_count: 0,
      response_count: 0,
      enquiry_old_count: 0,
      response_old_count: 0,
    };

    if(current_enquiry && current_response){
      let length = current_enquiry.length === current_response.length ? current_enquiry.length  : 0;

      for (let i = 0; i < length; i++) {
        graphData.labels.push(moment(current_enquiry[i].date).format('DD MMMM'));

        graphData.enquiry.push(current_enquiry[i].value);
        graphData.response.push(current_response[i].value);
        // graphData.enquiry_old.push(old_enquiry[i].value);
        // graphData.response_old.push(old_response[i].value);
      }

      // for (let i = length - 1; i >= 0; i--) {
      //   graphData.enquiry_old.push(old_enquiry[i].value);
      //   graphData.response_old.push(old_response[i].value);
      // }

      graphData.enquiry_count = Number(data.total_current_enquiries);
      graphData.response_count = Number(data.total_current_response);
      graphData.enquiry_old_count = Number(data.total_prev_enquiries);
      graphData.response_old_count = Number(data.total_prev_response);

      this.setState({
        graph_loading: false,
        website: data,
        enquiry: {
          labels: graphData.labels,
          data: graphData.enquiry,
          count: graphData.enquiry_count,
          countOld: graphData.enquiry_old_count
        },
        response: {
          labels: graphData.labels,
          data: graphData.response,
          count: graphData.response_count,
          countOld: graphData.response_old_count
        },
      });
    }
  };

  render() {

    const { website } = this.state;
    let title = (
      <Skeleton active paragraph={false} title={{ width: 100}} loading={this.state.graph_loading}>
        {website.domain_name}{' '}
        <Badge count={website.notifications} />
      </Skeleton>
    );

    let extra = (
      <div>
        <Select
          className="ant-select-range"
          onChange={this.onDateRangeSelected}
          defaultValue={7}
        >
          <Option value={7}> Last 7 Days</Option>
          <Option value={14}> Last 14 Days</Option>
          <Option value={30}> Last 1 Month</Option>
        </Select>
        <Tooltip title={'Admin settings'}>
          {this.fullAccess ? (
            <Link to={'/view-website/' + website.domain_id}>
              <Button type="default" size="large" shape="circle">
                <span className="icon-settings" />
              </Button>
            </Link>
          ) : null}
        </Tooltip>
      </div>
    );

    let enquiryDOM = (
      <div className="no-enquiry">
        <div className="value-text na-color light">Enquiries</div>
        <div className="value-container">
          <div className="value">
            {website.total_current_enquiries}
          </div>
          <div className="sup">
            <img src={arrowNA} alt="arrow" />{' '}
            <span className={'na-bgcolor'}>NA</span>
          </div>
        </div>
      </div>
    );

    let enquiryDiff =
      Number(website.total_current_enquiries) -
      Number(website.total_prev_enquiries);
    let enquiryPercent =
      100 *
      (Math.abs(enquiryDiff) /
        (Number(website.total_current_enquiries) +
          Number(website.total_prev_enquiries)));

    if (enquiryDiff < 0) {
      enquiryDOM = (
        <div className="no-enquiry">
          <div className="value-text danger-color">Enquiries</div>
          <div className="value-container">
            <div className="value">
              {website.total_current_enquiries}
            </div>
            <div className="sup">
              <img src={arrowDownRed} alt="arrow" />{' '}
              <span className={'danger-bgcolor light'}>
                {Math.round(enquiryPercent)}%
              </span>
            </div>
          </div>
        </div>
      );
    } else if (enquiryDiff > 0) {
      enquiryDOM = (
        <div className="no-enquiry">
          <div className="value-text success-color">Enquiries</div>
          <div className="value-container">
            <div className="value">
              {website.total_current_enquiries}
            </div>
            {enquiryPercent === 100 ? (
              <div className="sup">
                <img src={arrowNA} alt="arrow" />{' '}
                <span className={'na-bgcolor'}>NA</span>
              </div>
            ) : (
              <div className="sup">
                <img src={arrowUpGreen} alt="arrow" />{' '}
                <span className={'success-bgcolor light'}>
                  {Math.round(enquiryPercent)}%
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }

    let responseDOM = (
      <div className="response-time">
        <div className="value-text na-color light">Response time</div>
        <div className="value-container">
          <div className="value">
            {Math.round(website.curr_average_response).toString()}
          </div>
          <div className="sup">
            <img src={arrowNA} alt="arrow" />{' '}
            <span className={'na-bgcolor'}>NA</span>
          </div>
        </div>
      </div>
    );

    let responseDiff =
      Number(website.curr_average_response) -
      Number(website.previous_averages_response);
    let responsePercent =
      100 *
      (Math.abs(responseDiff) /
        (Number(website.curr_average_response) +
          Number(website.previous_averages_response)));

    if (responseDiff < 0) {
      responseDOM = (
        <div className="response-time">
          <div className="value-text success-color">Response time</div>
          <div className="value-container">
            <div className="value">
              {Math.round(website.curr_average_response)}
            </div>
            <div className="sup">
              <img src={arrowDownGreen} alt="arrow" />{' '}
              <span className={'success-bgcolor light'}>
                {responsePercent}%
              </span>
            </div>
          </div>
        </div>
      );
    } else if (responseDiff > 0) {
      responseDOM = (
        <div className="response-time">
          <div className="value-text danger-color">Response time</div>
          <div className="value-container">
            <div className="value">
              {Math.round(website.curr_average_response)}
            </div>
            {responsePercent === 100 ? (
              <div className="sup">
                <img src={arrowNA} alt="arrow" />{' '}
                <span className={'na-bgcolor'}>NA</span>
              </div>
            ) : (
              <div className="sup">
                <img src={arrowUpRed} alt="arrow" />{' '}
                <span className={'danger-bgcolor light'}>
                  {responsePercent}%
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }
    return (
      <Card
        className={ website.domain_status === false
            ? 'mini-chart-card website-down'
            : 'mini-chart-card'
        }
        title={title}
        bordered={false}
        extra={extra}
        id={'home-graph-' + this.props.idIndex}
      >

        <div className={'graph-body'}>
          <Row>
            <Col xs={12}>
                {
                  this.state.graph_loading ? loaderImage : (
                    <div className="graph-cell">
                      {enquiryDOM}
                      <Graph
                        width={500}
                        height={400}
                        date_picker_range={this.state.date_picker_range}
                        graphs={this.state.enquiry}
                        type={'ENQUIRY'}
                      />
                    </div>
                  )
                }
            </Col>
            <Col xs={12}>
              {
                this.state.graph_loading ? loaderImage : (
                  <div className="graph-cell">
                    {responseDOM}
                    <Graph
                      width={500}
                      height={400}
                      date_picker_range={this.state.date_picker_range}
                      graphs={this.state.response}
                      type={'RESPONSE'}
                    />
                  </div>
                )
              }
            </Col>
          </Row>
        </div>

        <div className="graph-footer">
          <div className="view-form-btn">
            {
              this.state.graph_loading ? (
                <svg width="162px" height="40px" viewBox="0 0 162 40" version="1.1" xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink">
                  <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Dashboard-preloader" transform="translate(-492.000000, -509.000000)" fill="#F5F5F5">
                      <g id="Group-2-Copy-3" transform="translate(310.000000, 140.000000)">
                        <g id="Group-7" transform="translate(0.000000, 349.000000)">
                          <rect id="Rectangle-Copy" x="182" y="20" width="162" height="40"></rect>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              ) : (
                <Link to={'/dashboard/' + website.domain_id}>
                  <Button size="large" type="primary">
                    View Form ({website.form_count})
                  </Button>
                </Link>
              )
            }
          </div>
        </div>
      </Card>
    );
  }
}

export default DomainCardLayout;
