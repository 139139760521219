import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, Row, Col, Affix, Skeleton, notification } from 'antd';
import commonApiService from '@apiService';

import DomainCardLayout from './DomainCardLayout';
import moment from 'moment';
import StrictLoader from '@components/common/strictloader/StrictLoader';
import GuideLayout from '@components/common/Guide/GuideLayout';

import './DashboardHome.scss';

class DashboardHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      guide: false,
      show: true,
      dashboard_stats: [{}, {}],
      req_notification: false,
    };
    this.datePickerRange = [moment().subtract(7, 'd'), moment()];
    this.is_plan_expired = localStorage.getItem('is_plan_expired') === 'true';

    this.fullAccess = false;
    if (localStorage && localStorage.user) {
      let user = JSON.parse(localStorage.user);
      let owner = localStorage.owner === 'true';
      this.fullAccess = owner ? true : user.access_level === 'FullAccess';
    }
  }

  requestPermission = () => {
    this.setState({
      req_notification: true,
    });

    if (Notification.permission === 'denied') {
      // check
      this.setState({
        req_notification: false,
      });

      notification.open({
        message: 'Push Notification Permission',
        description: 'Notifications are blocked. Please allow Notifications!',
        duration: 0,
        key: 1,
        className: 'notification-error',
      });
    } else {
      try {
        Notification.requestPermission().then(result => {
          if (result === 'denied') {
            this.requestPermission();
          } else {
            this.setState({
              req_notification: false,
            });
          }
        });
      } catch (error) {
        // Safari doesn't return a promise for requestPermissions and it
        // throws a TypeError. It takes a callback as the first argument
        // instead.
      }
    }
  };

  componentWillMount() {
    commonApiService
      .fetch('web/dashboard_stats', {
        from_date: this.datePickerRange[0].unix(),
        to_date: this.datePickerRange[1].unix(),
      })
      .then(successResult => {
        setTimeout(() => {
          this.setState({
            dashboard_stats: successResult.data.stats,
            loading: false,
          });
          document.getElementById('overlay').style.opacity = 0;
          setTimeout(() => {
            document.getElementById('overlay').style.display = 'none';
            if (this.props.location.search) {
              this.setState({
                guide: true,
              });
            }
          }, 1000);
        }, 1000);
      })
      .catch(errorResult => {
        this.setState({
          dashboard_stats: [],
          loading: false,
        });
      });

    this.requestPermission();
  }

  closeGuide = () => {
    this.setState({
      guide: false
    })
  }

  render() {
    const dashboard_stats_count = this.state.dashboard_stats ?  this.state.dashboard_stats.length : 0;

    let dashboard_stats = dashboard_stats_count > 0 ?
      this.state.dashboard_stats.map((website, index) => {
        return (
          <Col key={index + Math.random()} sm={24} md={12}>
            <DomainCardLayout
              website={website}
              idIndex={index}
            />
          </Col>
        );
      }) : (
        <Col sm={24} md={24}>
          <Card
            hidden={this.state.loading}
            className={'add-website-box flexrow'}
            bordered={false}
          >
            <img src="/images/empty.svg" alt="add new website" />
            <div className="text">
              <h2>
                Website stats appear here!
              </h2>
              <p>
                Currently you are not assigned to any website by admin. You can check forms assigned to you
              </p>
            </div>
            <Link to="/faq">
              <Button size="large" type="primary">
                FAQs
              </Button>
            </Link>
          </Card>
        </Col>
      );

    return (
      <>
        <Affix offsetTop={localStorage.getItem('appInstalled') === "true" ? 0 : 48}>
          <div className="headerbar">
            <div className="headerbar-block breadcrumb left">
              <div className="breadcrumb-subtitle">
                My Websites
              </div>
            </div>
            <div className="headerbar-block right" />
            <div className="clear-fix" />
          </div>
        </Affix>
        <main className="main-container">
          <div className="dashboard">
            <div className="dashboard-home">
              <Row gutter={24}>
                {dashboard_stats}
                {!this.is_plan_expired && this.fullAccess && !this.state.loading && (
                  <Col sm={24} md={dashboard_stats_count % 2 === 0 ? 24 : 12}>
                    <Card
                      className={
                        dashboard_stats_count % 2 === 0
                          ? 'add-website-box flexrow'
                          : 'add-website-box'
                      }
                      bordered={false}
                    >
                      <img src="/images/empty.svg" alt="add new website" />
                      <div className="text">
                        <h2>
                          Add Another  Website & Form
                        </h2>
                        <p>
                          Now that you've seen how easy it is to set up a website and contact form on your Buzzz account, let's set up another (if you have another).
                        </p>
                      </div>
                      <Link to="/add-website">
                        <Button size="large" type="primary">
                          Add Website & Form
                        </Button>
                      </Link>
                    </Card>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </main>
        {this.state.req_notification ? (
          <StrictLoader
            show={true}
            content={
              <div className={'allow-notification'}>
                <div className="loading-overlay-text">
                  Get Push Notifications
                </div>
                <div className="loading-overlay-sub-text">
                  Click Allow to get alerts from Buzzz.!
                </div>
              </div>
            }
          />
        ) : null}

        { this.state.guide ? <GuideLayout closeGuide={this.closeGuide}/> : null}
      </>
    );
  }
}

export default DashboardHome;
