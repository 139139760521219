import React, { Component } from 'react';
import {
  Input,
  Button,
  Form,
  Card,
  Checkbox,
  Modal,
  notification,
  Avatar,
} from 'antd';

import Dropzone from 'react-dropzone';
import MobileInput from '@components/common/MobileNumberLayout';

import './ProfileLayout.scss';
import commonApiService from '@apiService';

import TimezonePicker from '@components/common/timezone';

const FormItem = Form.Item;

class ChangePasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      change_password: false,
      edit: false,
      loading: false,
    };
  }

  changePasswordInitiate = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        var change_password_data = {
          current_password: values.current_password,
          new_password: values.new_password,
          new_password_confirm: values.confirm_password,
        };

        commonApiService.put('/change_password', change_password_data).then(
          successResult => {
            notification.open({
              message: 'Success',
              description: successResult.data.msg,
              className: 'notification-success',
            });

            this.setState(
              {
                loading: false,
              },
              () => {
                this.props.closeCallback();
              },
            );
          },
          errorResult => {
            // show error
            notification.open({
              message: 'Error',
              description: errorResult.errors,
              className: 'notification-error',
            });
          },
        );
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Form
          hidden={this.state.change_password}
          onSubmit={this.changePasswordInitiate}
        >
          <FormItem>
            {getFieldDecorator('current_password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your current password!',
                },
              ],
            })(<Input type="password" placeholder=" " />)}
            <div className="floating-label">Current Password</div>
          </FormItem>
          <FormItem>
            {getFieldDecorator('new_password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your New Password!',
                },
              ],
            })(<Input type="password" placeholder=" " />)}
            <div className="floating-label">New Password</div>
          </FormItem>
          <FormItem>
            {getFieldDecorator('confirm_password', {
              rules: [
                {
                  required: true,
                  message: 'Please confirm your New Password!',
                },
              ],
            })(<Input type="password" placeholder=" " />)}
            <div className="floating-label">Confirm Password</div>
          </FormItem>
          <FormItem>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={this.state.loading}
            >
              Change Password
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}
const ChangePasswordComponentWrapper = Form.create()(ChangePasswordComponent);

class ProfileLayout extends Component {
  state = {
    user_detail: null,
    loading: true,
    change_password_visible: false,
    phone_number: null,
    edit: false,
  };

  componentWillMount() {
    this.getUserProfile();
    document.title = 'Profile | Buzzz.';
  }

  getUserProfile = cb => {
    commonApiService.fetch('web/user').then(
      successResult => {
        let user = successResult.data.user;

        try {
          let localStoredUser = JSON.parse(localStorage.getItem('user'));
          localStoredUser.avatar += parseInt(Math.random() * 10, 10);
          localStoredUser.first_name = user.first_name;
          localStoredUser.last_name = user.last_name;
          localStorage.setItem('user', JSON.stringify(localStoredUser));
        } catch (e) {}

        this.props.form.setFieldsValue({
          first_name: user.first_name,
          last_name: user.last_name,
          uemail: user.email,
          sendnewsletter: user.send_newsletter,
        });

        this.setState(
          {
            user_detail: user,
            profile_image_url: user.avatar,
            profile_image_url_old: user.avatar,
            edit: false,
            loading: false,
          },
          () => {
            this.updateMobileInput();
            if (cb) cb();
          },
        );
      },
      errorResult => {
        // show error
        this.setState(
          {
            loading: false,
          },
          () => {
            if (cb) cb();
          },
        );
      },
    );
  };

  updateMobileInput = () => {
    if (this.state.user_detail && this.state.user_detail.contact) {
      this.refs.mobileInput &&
        this.refs.mobileInput.updateInput(this.state.user_detail.contact);
    }
  };

  resetProfile = () => {
    this.setState({
      edit: false,
      profile_image_url: this.state.profile_image_url_old,
    });
  };

  handleSubmit = e => {
    this.setState({
      loading: true,
    });

    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      const phoneNumberData = this.refs.mobileInput.getPhoneNumber();
      if (!err && phoneNumberData) {
        //Format the data received and fire a request to register the user...
        //@todo : bug with split data logic
        this.setState({
          loading: true,
        });

        var user_update_data = {
          first_name: values.first_name,
          last_name: values.last_name,
          contact: JSON.stringify(phoneNumberData),
          send_newsletter: values.sendnewsletter,
          uTimeZone: this.state.user_detail.uTimeZone,
        };
        if (this.state.profile_image_file) {
          user_update_data['avatar'] = this.state.profile_image_file;
        }
        commonApiService.editFormData('web/user', null, user_update_data).then(
          successResult => {
            this.getUserProfile(() => {
              notification.open({
                message: 'Success',
                description: successResult.data.msg,
                className: 'notification-success',
              });
            });
          },
          errorResult => {
            // show error

            this.getUserProfile(() => {
              notification.open({
                message: 'Error',
                description: errorResult.errors,
                className: 'notification-error',
              });
            });
          },
        );
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  };

  onFilesDrop = files => {
    if (files.length) {
      this.setState({
        profile_image_file: files[0],
        profile_image_url: files[0].preview,
        profile_image_error: false,
      });
    } else {
      this.setState({
        profile_image_error: true,
      });
    }
  };

  setTimeZone = timezone => {
    var user_detail = this.state.user_detail;
    user_detail.uTimeZone = timezone;
    this.setState({
      user_detail,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    let dropper_props = Object.assign(
      {},
      this.state.profile_image_error && {
        help: 'Please upload image having size less than 1MB!',
        validateStatus: 'error',
      },
    );

    return (
      <main className="main-container">
        <div className="manage-user">
          <div className="view-user" hidden={this.state.edit}>
            <Card bordered={false} loading={this.state.loading}>
              <div className="profile-pic-container">
                <div className="profile-pic">
                  <Avatar
                    size={100}
                    src={
                      this.state.profile_image_url
                        ? this.state.profile_image_url
                        : '/images/profile_placeholder.svg'
                    }
                  />
                </div>
              </div>

              <div className="details-item">
                <label>Full Name</label>
                <div>
                  {this.state.user_detail &&
                    this.state.user_detail.first_name +
                      ' ' +
                      this.state.user_detail.last_name}
                </div>
              </div>

              <div className="details-item">
                <label>Phone Number</label>
                <div>
                  {this.state.user_detail &&
                    this.state.user_detail.contact.country_code +
                      ' ' +
                      this.state.user_detail.contact.phone_number}
                </div>
              </div>

              <div className="details-item">
                <label>Email Address</label>
                <div>
                  {this.state.user_detail && this.state.user_detail.email}
                </div>
              </div>

              <div className="details-item">
                <label>Timezone</label>
                <div>
                  {this.state.user_detail && this.state.user_detail.uTimeZone}
                </div>
              </div>

              <Button.Group>
                <Button
                  size="large"
                  htmlType="button"
                  className={'change-pass'}
                  type="default"
                  onClick={e => {
                    this.setState({ change_password_visible: true });
                  }}
                  hidden={this.state.edit}
                >
                  Change Password
                </Button>
              </Button.Group>

              <div className="details-item">
                <Checkbox
                  disabled={!this.state.edit}
                  checked={
                    this.state.user_detail &&
                    this.state.user_detail.send_newsletter
                  }
                >
                  Buzzz.io can send me newsletter over email
                </Checkbox>
              </div>
              <Button.Group>
                <Button
                  size="large"
                  htmlType="button"
                  type="primary"
                  hidden={this.state.edit}
                  onClick={e => {
                    this.setState({ edit: true });
                  }}
                >
                  Edit Profile
                </Button>
              </Button.Group>
            </Card>
          </div>

          <div className="edit-user" hidden={!this.state.edit}>
            <Card bordered={false}>
              <Form name="add_user" onSubmit={this.handleSubmit}>
                <div className="profile-pic-container">
                  <FormItem {...dropper_props}>
                    <div className="profile-pic">
                      <Avatar
                        size={100}
                        src={
                          this.state.profile_image_url
                            ? this.state.profile_image_url
                            : '/images/profile_placeholder.svg'
                        }
                      />
                      <Button
                        type="primary"
                        className="profile-pic-change-btn"
                        shape="circle"
                      >
                        <Dropzone
                          className="dropzone-wrapper"
                          maxSize={1000000}
                          accept="image/*"
                          onDrop={this.onFilesDrop}
                        >
                          <span className="icon-camera" />
                        </Dropzone>
                      </Button>
                    </div>
                  </FormItem>
                </div>
                <FormItem>
                  {getFieldDecorator('first_name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input first name!',
                        whitespace: true,
                      },
                    ],
                  })(<Input placeholder=" " />)}
                  <div className="floating-label">First Name</div>
                </FormItem>

                <FormItem>
                  {getFieldDecorator('last_name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input last name!',
                        whitespace: true,
                      },
                    ],
                  })(<Input placeholder=" " />)}
                  <div className="floating-label">Last Name</div>
                </FormItem>

                <MobileInput ref={'mobileInput'} />

                <FormItem colon={false}>
                  {getFieldDecorator('uemail', {
                    rules: [
                      {
                        type: 'email',
                        message: 'The input is not valid Email address!',
                      },
                      {
                        required: true,
                        message: 'Please input Email address!',
                      },
                    ],
                  })(<Input placeholder=" " readOnly={true} />)}
                  <div className="floating-label">Email Address</div>
                </FormItem>

                <FormItem colon={false}>
                  {getFieldDecorator('utimezone', {
                    initialValue:
                      this.state.user_detail &&
                      this.state.user_detail.uTimeZone,
                  })(
                    <TimezonePicker
                      className={'timezone-picker'}
                      onChange={timezone => this.setTimeZone(timezone)}
                    />,
                  )}
                  <div className="floating-label force-float">Timezone</div>
                </FormItem>

                <FormItem>
                  {getFieldDecorator('sendnewsletter', {
                    valuePropName: 'checked',
                  })(
                    <Checkbox disabled={!this.state.edit}>
                      Buzzz.io can send me newsletter over email
                    </Checkbox>,
                  )}
                </FormItem>
                <Button.Group>
                  <Button
                    size="large"
                    htmlType="submit"
                    type="primary"
                    loading={this.state.loading}
                  >
                    Update Profile
                  </Button>
                  <Button
                    size="large"
                    htmlType="button"
                    type="default"
                    onClick={this.resetProfile}
                  >
                    Cancel
                  </Button>
                </Button.Group>
              </Form>
            </Card>
          </div>

          <Modal
            title="CHANGE PASSWORD"
            visible={this.state.change_password_visible}
            onCancel={e => {
              this.setState({ change_password_visible: false });
            }}
            footer={null}
          >
            <ChangePasswordComponentWrapper
              closeCallback={e => {
                this.setState({ change_password_visible: false });
              }}
            />
          </Modal>
        </div>
      </main>
    );
  }
}

export default Form.create()(ProfileLayout);
