import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserLayoutActions from '../actions/UserLayoutAction.js';
import AddEditUserLayout from '@layouts/Users/AddEditUserLayout';
import * as DomainLayoutActions from '../actions/DomainLayoutAction.js';

// ================================================================================
// USER - Container
// ================================================================================

const mapStateToProps = state => {
  const {
    user_list_success = null,
    user_list_error = null,
    user_add_success = null,
    user_add_error = null,
    user_detail_success = null,
    user_detail_error = null,
    user_edit_success = null,
    user_edit_error = null,
  } = state.UserReducer;

  const {
    domain_data_success = null,
    domain_data_error = null,
  } = state.DomainReducer;

  // return {
  //   user_list_success: state.UserReducer.user_list_success || null,
  //   user_list_error: state.UserReducer.user_list_error || null,
  //   user_add_success: state.UserReducer.user_add_success || null,
  //   user_add_error: state.UserReducer.user_add_error || null,
  //   user_detail_success: state.UserReducer.user_detail_success || null,
  //   user_detail_error: state.UserReducer.user_detail_error || null,
  //   user_edit_success: state.UserReducer.user_edit_success || null,
  //   user_edit_error: state.UserReducer.user_edit_error || null,

  //   domain_data_success: state.DomainReducer.domain_data_success || null,
  //   domain_data_error: state.DomainReducer.domain_data_error || null,
  // };

  return {
    user_list_success,
    user_list_error,
    user_add_success,
    user_add_error,
    user_detail_success,
    user_detail_error,
    user_edit_success,
    user_edit_error,

    domain_data_success,
    domain_data_error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(UserLayoutActions, dispatch),
    domain_actions: bindActionCreators(DomainLayoutActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddEditUserLayout);

// ================================================================================
