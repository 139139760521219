import commonApiService from '@apiService';

// --------------------------------------------------------------------------------
export function FetchDomainPre() {
  return {
    type: 'FETCH_DOMAIN_PRE',
  };
}

export function FetchVerifiedDomainSuccess(payload) {
  return {
    type: 'FETCH_VERIFIED_DOMAIN_SUCCESS',
    payload,
  };
}

export function FetchVerifiedDomainFailure(err) {
  return {
    type: 'FETCH_VERIFIED_DOMAIN_FAILURE',
    err,
  };
}

export function FetchDomainSuccess(payload) {
  return {
    type: 'FETCH_DOMAIN_SUCCESS',
    payload,
  };
}

export function FetchDomainFailure(err) {
  return {
    type: 'FETCH_DOMAIN_FAILURE',
    err,
  };
}

export function FetchDomainPreRegisterCodeSuccess(payload) {
  return {
    type: 'FETCH_DOMAIN_PREREGISTER_SUCCESS',
    payload,
  };
}

export function FetchDomainPreRegisterCodeFailure(err) {
  return {
    type: 'FETCH_DOMAIN_PREREGISTER_FAILURE',
    err,
  };
}

export function FetchDomainVerifySuccess(payload) {
  return {
    type: 'FETCH_DOMAIN_VERIFY_SUCCESS',
    payload,
  };
}

export function FetchDomainVerifyFailure(err) {
  return {
    type: 'FETCH_DOMAIN_VERIFY_FAILURE',
    err,
  };
}

export function FetchformExtractSuccess(payload) {
  return {
    type: 'FORM_EXTRACT_SUCCESS',
    payload,
  };
}

export function FetchformExtractFailure(err) {
  return {
    type: 'FORM_EXTRACT_FAILURE',
    err,
  };
}

export function DomainRegisterSuccess(payload) {
  return {
    type: 'DOMAIN_REGISTER_SUCCESS',
    payload,
  };
}

export function DomainRegisterFailure(err) {
  return {
    type: 'DOMAIN_REGISTER_FAILURE',
    err,
  };
}

export function DomainFormRegisterSuccess(payload) {
  return {
    type: 'DOMAIN_FORM_REGISTER_SUCCESS',
    payload,
  };
}

export function DomainFormRegisterFailure(err) {
  return {
    type: 'DOMAIN_FORM_REGISTER_FAILURE',
    err,
  };
}

export function retrieveAllFormData() {
  return {
    type: 'RETRIEVE_FORM_DATA',
  };
}

export function disableSubmissionRequest(key) {
  return {
    type: 'DISABLE_FORM_SUBMISSION',
    key,
  };
}

export function retrieveDomainDataSuccess(payload) {
  return {
    type: 'RETRIEVE_DOMAIN_DATA_SUCCESS',
    payload,
  };
}

export function retrieveDomainDataFailure() {
  return {
    type: 'RETRIEVE_DOMAIN_DATA_FAILURE',
  };
}

/*DOMAIN DETAILS UPDATE ACTIONS START*/
export function preDomainUpdate() {
  return {
    type: 'PRE_DOMAIN_UPDATE',
  };
}

export function updateDomainSuccess(payload) {
  return {
    type: 'UPDATE_DOMAIN_SUCCESS',
    payload,
  };
}

export function updateDomainFailure(err) {
  return {
    type: 'UPDATE_DOMAIN_FAILURE',
    err,
  };
}
/*DOMAIN DETAILS UPDATE ACTIONS END*/

export function DomainFormUpdateSuccess(payload) {
  return {
    type: 'DOMAIN_FORM_UPDATE_SUCCESS',
    payload,
  };
}

export function DomainFormUpdateFailure(err) {
  return {
    type: 'DOMAIN_FORM_UPDATE_FAILURE',
    err,
  };
}

export function DomainFormDeleteSuccess(payload) {
  return {
    type: 'DOMAIN_FORM_DELETE_SUCCESS',
    payload,
  };
}

export function DomainFormDeleteFailure(err) {
  return {
    type: 'DOMAIN_FORM_DELETE_FAILURE',
    err,
  };
}

export function retrieveDomainUsersDataSuccess(payload) {
  return {
    type: 'RETRIEVE_DOMAIN_USER_DATA_SUCCESS',
    payload,
  };
}

export function retrieveDomainUsersDataFailure() {
  return {
    type: 'RETRIEVE_DOMAIN_USER_DATA_FAILURE',
  };
}

export function retrieveDomainUsersAddDataSuccess(payload) {
  return {
    type: 'RETRIEVE_DOMAIN_USER_ADD_DATA_SUCCESS',
    payload,
  };
}

export function retrieveDomainUsersAddDataFailure() {
  return {
    type: 'RETRIEVE_DOMAIN_USER_ADD_DATA_FAILURE',
  };
}

export function DomainUsersAddSuccess(payload) {
  return {
    type: 'DOMAIN_USER_ADD_SUCCESS',
    payload,
  };
}

export function DomainUsersAddFailure() {
  return {
    type: 'DOMAIN_USER_ADD_FAILURE',
  };
}

export function DomainUsersRemoveSuccess(payload) {
  return {
    type: 'DOMAIN_USER_REMOVE_SUCCESS',
    payload,
  };
}

export function DomainUsersRemoveFailure(err) {
  return {
    type: 'DOMAIN_USER_REMOVE_FAILURE',
    err,
  };
}

export function fetchDomains(params) {
  return dispatch => {
    dispatch(FetchDomainPre());
    commonApiService
      .list('/web/domains', params)
      .then(successResult => {
        dispatch(FetchDomainSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(FetchDomainFailure(errorResult));
      });
  };
}

export function fetchVerifiedDomains(params) {
  return dispatch => {
    dispatch(FetchDomainPre());
    commonApiService
      .list('/web/domains', params)
      .then(successResult => {
        dispatch(FetchVerifiedDomainSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(FetchVerifiedDomainFailure(errorResult));
      });
  };
}

export function FetchDomainPreRegister(params) {
  return dispatch => {
    commonApiService
      .list('/web/domains/prefetch', params)
      .then(successResult => {
        dispatch(FetchDomainPreRegisterCodeSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(FetchDomainPreRegisterCodeFailure(errorResult));
      });
  };
}

export function verifyDomain(params) {
  return dispatch => {
    commonApiService
      .post('/web/domains/verify', params)
      .then(successResult => {
        dispatch(FetchDomainVerifySuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(FetchDomainVerifyFailure(errorResult));
      });
  };
}

export function extractFormData(params) {
  return dispatch => {
    commonApiService
      .post('/web/domains/extract_forms', params)
      .then(successResult => {
        dispatch(FetchformExtractSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(FetchformExtractFailure(errorResult));
      });
  };
}

export function registerDomain(params) {
  return dispatch => {
    commonApiService
      .post('/web/domains/add', params)
      .then(successResult => {
        dispatch(DomainRegisterSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(DomainRegisterFailure(errorResult));
      });
  };
}

export function domainVerify(params) {
  return dispatch => {
    commonApiService
      .post('/web/domains/webverify', params)
      .then(successResult => {
        dispatch(DomainRegisterSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(DomainRegisterFailure(errorResult));
      });
  };
}

export function registerForms(params) {
  return dispatch => {
    commonApiService
      .post('/web/domains/forms', params)
      .then(successResult => {
        dispatch(DomainFormRegisterSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(DomainFormRegisterFailure(errorResult));
      });
  };
}

export function retrieveForms() {
  return dispatch => {
    dispatch(retrieveAllFormData());
  };
}

export function disableSubmission(key) {
  return dispatch => {
    dispatch(disableSubmissionRequest(key));
  };
}

export function fetchDomainDetails(params) {
  return dispatch => {
    commonApiService
      .list('/web/domains', params)
      .then(successResult => {
        dispatch(retrieveDomainDataSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(retrieveDomainDataFailure(errorResult));
      });
  };
}

export function fetchDomainUsers(params) {
  return dispatch => {
    commonApiService
      .list('/web/domains/users', params)
      .then(successResult => {
        dispatch(retrieveDomainUsersDataSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(retrieveDomainUsersDataFailure(errorResult));
      });
  };
}

/*API to be used to fetch a list of users that are not part of a particular domain*/
export function fetchDomainAddUsers(params) {
  return dispatch => {
    commonApiService
      .list('/web/domains/users', params)
      .then(successResult => {
        dispatch(retrieveDomainUsersAddDataSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(retrieveDomainUsersAddDataFailure(errorResult));
      });
  };
}

/*API to be used to fetch a list of users that are not part of a particular domain*/
export function DomainAddUsers(params) {
  return dispatch => {
    commonApiService
      .post('/web/domains/users', params)
      .then(successResult => {
        dispatch(DomainUsersAddSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(DomainUsersAddFailure(errorResult));
      });
  };
}

export function removeDomainUser(params) {
  return dispatch => {
    commonApiService
      .put('/web/domains/users', params)
      .then(successResult => {
        dispatch(DomainUsersRemoveSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(DomainUsersRemoveFailure(errorResult));
      });
  };
}

export function updateDomain(params) {
  return dispatch => {
    dispatch(preDomainUpdate());
    commonApiService
      .put('/web/domains/edit', params)
      .then(successResult => {
        dispatch(updateDomainSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(updateDomainFailure(errorResult));
      });
  };
}

export function editForm(params) {
  return dispatch => {
    commonApiService
      .put('/web/domains/forms', params)
      .then(successResult => {
        dispatch(DomainFormUpdateSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(DomainFormUpdateFailure(errorResult));
      });
  };
}

export function deleteForm(params) {
  return dispatch => {
    commonApiService
      .put('/web/domains/form_discard', params)
      .then(successResult => {
        dispatch(DomainFormDeleteSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(DomainFormDeleteFailure(errorResult));
      });
  };
}

/*Functions end*/
