import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as DomainLayoutActions from '../actions/SubscriptionLayoutAction.js';
import SubscriptionLayout from '@layouts/Subscription/SubscriptionLayout';

// ================================================================================
// SUBSCRIPTION - Container
// ================================================================================

const mapStateToProps = state => {
  const {
    user_subscription_fetch_success = null,
    user_subscription_fetch_error = null,
    update_billing_success = null,
    update_billing_error = null,
    deactivate_account_success = null,
    deactivate_account_error = null,
    activate_account_success = null,
    activate_account_error = null,
    update_account_plan_success = null,
    update_account_plan_error = null,
    fetch_invoices_success = null,
    fetch_invoices_error = null,
    update_billinginfo_success = null,
    country_list_success = null,
  } = state.SubscriptionReducer;
  //console.warn("The data received is : ", JSON.stringify(state.SubscriptionReducer));
  return {
    user_subscription_fetch_success,
    user_subscription_fetch_error,
    update_billing_success,
    update_billing_error,
    deactivate_account_success,
    deactivate_account_error,
    activate_account_success,
    activate_account_error,
    update_account_plan_success,
    update_account_plan_error,
    fetch_invoices_success,
    fetch_invoices_error,
    update_billinginfo_success,
    country_list_success,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(DomainLayoutActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionLayout);

// ================================================================================
