import commonApiService from '@apiService';

// --------------------------------------------------------------------------------
export function FetchUsersPre() {
  return {
    type: 'USER_LIST_FETCH_PRE',
  };
}

export function FetchUsersSuccess(payload) {
  return {
    type: 'USER_LIST_FETCH_SUCCESS',
    payload,
  };
}

export function FetchUsersFailure(err) {
  return {
    type: 'USER_LIST_FETCH_FAILURE',
    err,
  };
}

export function AddUsersSuccess(payload) {
  return {
    type: 'USER_ADD_SUCCESS',
    payload,
  };
}

export function AddUsersFailure(err) {
  return {
    type: 'USER_ADD_FAILURE',
    err,
  };
}

export function FetchUserDetailsSuccess(payload) {
  return {
    type: 'USER_DETAIL_FETCH_SUCCESS',
    payload,
  };
}

export function FetchUserDetailsFailure(err) {
  return {
    type: 'USER_DETAIL_FETCH_FAILURE',
    err,
  };
}

export function EditUserSuccess(payload) {
  return {
    type: 'EDIT_USER_DETAIL_SUCCESS',
    payload,
  };
}

export function EditUserFailure(err) {
  return {
    type: 'EDIT_USER_DETAIL_FAILURE',
    err,
  };
}

export function updateUserLimit(limit) {
  return {
    type: 'USER_USAGE_LIMIT',
    limit,
  };
}

export function fetchUsers(params) {
  return dispatch => {
    dispatch(FetchUsersPre());
    commonApiService
      .list('/web/user/subscription_member', params)
      .then(successResult => {
        dispatch(FetchUsersSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(FetchUsersFailure(errorResult));
      });
  };
}

export function fetchSubscriptionUserDetails(params) {
  return dispatch => {
    commonApiService
      .list('/web/user/subscription_member', params)
      .then(successResult => {
        dispatch(FetchUserDetailsSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(FetchUserDetailsFailure(errorResult));
      });
  };
}

/*API to be used to register a new User and link the user to the Subscription Involved*/
export function addSubscriptionUser(params) {
  return dispatch => {
    commonApiService
      .addFormData('/web/user/subscription_member', params)
      .then(successResult => {
        dispatch(AddUsersSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(AddUsersFailure(errorResult));
      });
  };
}

export function editSubscriptionUser(query, params) {
  return dispatch => {
    commonApiService
      .editFormData('/web/user/subscription_member', query, params)
      .then(successResult => {
        dispatch(EditUserSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(EditUserFailure(errorResult));
      });
  };
}

/*Functions end*/
