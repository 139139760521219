import React, { Component } from 'react';
import {
  Table,
  Button,
  Skeleton,
  Tooltip,
  Dropdown,
  Menu,
  Avatar,
  Popconfirm,
  notification,
} from 'antd';

import './UserLayout.scss';
import commonApiService from '@apiService';

const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

class UserLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_list: [
        {
          id: 1,
          user_name: null,
          email: null,
          type: null,
          first_name: null,
          last_name: null,
          contact: null,
          domains: null,
        },
        {
          id: 2,
          user_name: null,
          email: null,
          type: null,
          first_name: null,
          last_name: null,
          contact: null,
          domains: null,
        },
        {
          id: 3,
          user_name: null,
          email: null,
          type: null,
          first_name: null,
          last_name: null,
          contact: null,
          domains: null,
        },
      ],
      loading: true,
    };
    this.is_plan_expired = localStorage.getItem('is_plan_expired') === 'true';
  }

  componentDidMount() {
    this.props.actions.fetchUsers();
    document.title = 'Users | Buzzz.';
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      if (
        nextProps.user_list_success &&
        this.state.user_list !== nextProps.user_list_success.users
      ) {
        this.setState({
          loading: false,
          user_list: nextProps.user_list_success.users,
        });
        this.props.actions.updateUserLimit(
          nextProps.user_list_success.usage_limit.available_limit,
        );
      }
      if (nextProps.user_list_error && this.state.loading) {
        this.setState({
          loading: false,
        });
      }
    }
  }

  handleDeleteUser = userId => {
    const params = {
      user_id: userId,
    };
    commonApiService
      .post('/web/user/deactivate', params)
      .then(successResult => {
        notification.open({
          message: 'User Removed!',
          description: 'user has been deleted!',
          className: 'notification-success',
        });
        this.handleCancel();
      })
      .catch(errorResult => {
        notification.open({
          message: 'Error!',
          description: errorResult.errors[0].msg,
          className: 'notification-error',
        });
      });
  };

  render() {
    const columns = [
      {
        title: '',
        dataIndex: 'user_pic',
        key: 'user_pic',
        width: 32,
        render: (text, record) => {
          const color =
            colorList[
              ((record.first_name ? record.first_name[0].charCodeAt() : 0) +
                (record.first_name ? record.last_name[0].charCodeAt() : 0)) %
                4
            ];
          const initial =
            (record.first_name ? record.first_name[0].toUpperCase() : 'X') +
            (record.last_name ? record.last_name[0].toUpperCase() : 'X');

          return (
            <div className="user-profile">
              <Skeleton
                title={false}
                avatar={{ shape: 'circle' }}
                paragraph={false}
                active
                loading={this.state.loading}
              >
                {record.avatar ? (
                  <Avatar src={record.avatar} />
                ) : (
                  <Avatar style={{ backgroundColor: color, color: '#fff' }}>
                    {' '}
                    {initial}{' '}
                  </Avatar>
                )}
              </Skeleton>
            </div>
          );
        },
      },
      {
        title: 'Name',
        dataIndex: 'user_name',
        key: 'user_name',
        width: '20%',
        render: (text, record) => (
          <>
            <Skeleton paragraph={false} active loading={this.state.loading}>
              {record.first_name + ' ' + record.last_name || '-'}
            </Skeleton>
          </>
        ),
      },
      {
        title: 'Contact',
        dataIndex: 'contact',
        key: 'contact',
        width: '20%',
        render: text => {
          let phone = text !== null ? text.split(/\s/) : ['', '', ''];
          return (
            <>
              <Skeleton paragraph={false} active loading={this.state.loading}>
                {/*<div className={'flags iti-flag ' + phone[0].toLowerCase() }/>*/}
                {phone[1] + ' ' + phone[2] || '-'}
              </Skeleton>
            </>
          );
        },
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '30%',
        render: text => (
          <>
            <Skeleton paragraph={false} active loading={this.state.loading}>
              {text}
            </Skeleton>
          </>
        ),
      },
      {
        title: 'User Type',
        dataIndex: 'type',
        key: 'type',
        width: '10%',
        render: text => (
          <>
            <Skeleton paragraph={false} active loading={this.state.loading}>
              {text}
              {/*<Tooltip mouseLeaveDelay={0} title={text === 'Admin' ? adminTypeInfo: userTypeInfo}> <span className='icon-info-circle primary-color'/></Tooltip>*/}
            </Skeleton>
          </>
        ),
      },
      {
        title: 'Assigned Website & Forms',
        dataIndex: 'websites',
        key: 'websites',
        width: '30%',
        render: (text, record) => {
          let domain_list = record.domains ? (
            record.domains.map(function(item, i) {
              let form_list = item.forms.map(function(form, i) {
                return <li key={i}>{form.name}</li>;
              });

              let menu = <ul>{form_list}</ul>;
              return (
                <Tooltip
                  key={i}
                  mouseLeaveDelay={0}
                  trigger="click"
                  placement="top"
                  overlayClassName="user-domain-form"
                  title={menu}
                >
                  <div className="user-domain">
                    <span>{item.title}</span>
                    <span>{item.forms.length}</span>
                  </div>
                </Tooltip>
              );
            })
          ) : (
            <span style={{ opacity: 0.5 }}>No Domain Assigned</span>
          );

          return (
            <Skeleton paragraph={false} active loading={this.state.loading}>
              <ul className="websites">{domain_list}</ul>
            </Skeleton>
          );
        },
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: '5%',
        render: (text, record) => {
          const menu = (
            <Menu>
              <Menu.Item key="0">
                <a
                  onClick={() => {
                    this.props.history.push('/users/edit/' + record.id);
                  }}
                  href
                >
                  Edit
                </a>
              </Menu.Item>
              <Menu.Item key="1">
                <Popconfirm
                  placement="bottom"
                  title="Are you sure？"
                  okText="Yes"
                  okType="danger"
                  cancelText="No"
                  onConfirm={() => this.handleDeleteUser(record.id)}
                >
                  <a href>Delete</a>
                </Popconfirm>
              </Menu.Item>
            </Menu>
          );

          return (
            <Skeleton
              paragraph={false}
              size="small"
              active
              loading={this.state.loading}
            >
              {!this.is_plan_expired ? (
                <Dropdown
                  getPopupContainer={() =>
                    document.getElementById('dropdown-zone')
                  }
                  overlay={menu}
                  placement={'bottomRight'}
                  trigger={['click']}
                  overlayClassName="profile-dropdown"
                >
                  <Button size="large" shape="circle" icon={'more'} />
                </Dropdown>
              ) : null}
            </Skeleton>
          );
        },
      },
    ];

    return (
      <main className="main-container">
        <div className="user-list">
          <Table
            rowKey="id"
            locale={{
              emptyText: (
                <div className="error-notification-box">
                  No users are linked
                </div>
              ),
            }}
            pagination={false}
            className="user_table"
            columns={columns}
            dataSource={this.state.user_list}
            scroll={{ x: 768 }}
          />
        </div>
      </main>
    );
  }
}

export default UserLayout;
