import React, {Component} from 'react';
import {
  Button,
  Card,
  Form,
  Input,
  message,
  notification, Popover,
  Switch,
  Tooltip,
} from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import moment from 'moment';
import HelpTip from '@components/common/Help/HelpTip';
import Maintenance from './Maintenance'

import commonApiService from '@apiService';

const Instructions = () => (
  <Card className={'help-card'} title={'Help'} extra={<a href="#">hello@buzzz.io</a>} bordered={false}>
    <div className={'instructions'}>
      <div className={'label-badge text'}>
        <div className={'left-badge'}>
          <span className={'number'}>1</span>
        </div>
        {' '}
        Copy then paste above code to your website's header
      </div>
      <div className={'label-badge text'}>
        <div className={'left-badge'}>
          <span className={'number'}>2</span>
        </div>
        {' '}
        Upload the updated file to your domain
      </div>
      <div className={'label-badge text'}>
        <div className={'left-badge'}>
          <span className={'number'}>3</span>
        </div>
        {' '}
        Confirm successful upload by visiting your domain in your browser.
      </div>
      <div className={'label-badge text'}>
        <div className={'left-badge'}>
          <span className={'number'}>4</span>
        </div>
        {' '}
        Click Verify below.
      </div>
      <div className={'label-badge text'}>
        <div className={'left-badge'}>
          <span className={'number'}>5</span>
        </div>
        {' '}
        To stay verified, don't remove the script from file, even after
        verification succeeds.
      </div>
    </div>
  </Card>
);


class WebsiteInfo extends Component {
  phone_number = '';

  state = {
    domain: {},
    help: false,
    ping_my_domain: localStorage.getItem('ping_my_domain') === 'true',
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.domain !== this.state.domain) {
      this.setState(
        {
          domain: nextProps.domain,
        }
      );
    }
  }


  toggleHelp = () => {
    this.setState({
      help: !this.state.help
    })
  };

  togglePing = ping_status => {
    const update_domain_data = {
      is_ping_allowed: ping_status
    };
    this.handleSubmit(update_domain_data)
  };

  updateWebsiteName = () => {
    const display_name = this.refs.websiteName.state.value;
    console.log(display_name);
    if(!display_name || display_name === ''){
      return;
    }

    const update_domain_data = {
      domain_name: display_name
    };

    this.handleSubmit(update_domain_data)
  };

  handleSubmit = (domain_data) => {
    const {domain} = this.state;
    const update_domain_data = { ...domain_data, domain_id: domain.id};
    commonApiService
      .put('/web/domains/edit', update_domain_data)
      .then(successResult => {
        if( domain_data.display_name){
          domain['display_name'] = domain_data.display_name
        }
        if(typeof domain_data.is_ping_allowed === 'boolean'){
          domain['is_ping_allowed'] = domain_data.is_ping_allowed
        }

        this.setState({
          domain: domain
        });

        this.props.refresh();

        notification.open({
          message: successResult.data.msg,
          description: 'changes have been updated successfully',
          className: 'notification-success',
        });
      })
      .catch(errorResult => {
        notification.open({
          message: 'Domain Update Failed!',
          description: errorResult.errors,
          className: 'notification-error',
        });
      });
  };

  render() {
    const {domain, help} = this.state;

    const getPopupContainerProps = this.props.parentSetup ? {} : {
      getPopupContainer: () => document.getElementById('view_domain')
    };

    let verify_text = domain.verified ? (
      <>
        Verified <i className="icon-check-circle"/>
      </>
    ) : (
      <>
        Not Verified{' '}
        <Tooltip
          mouseLeaveDelay={0}
          title="You have not placed code snippet on website yet"
        >
          <i className="icon-exclamation-circle danger-color"/>
        </Tooltip>
      </>
    );

    let title = (
      <>
        <div className="domain-title">{domain && domain.display_name}</div>
        <div className="domain-status">{verify_text}</div>
      </>
    );

    const extra = (
      <div>
        Created on {moment.unix(domain && domain.created_at).format('DD MMM YYYY')}
      </div>
    );
    ;
    return (
      <>
        <Popover
          {...getPopupContainerProps}
          overlayClassName={'help-popover instructions-tooltip'}
          content={<Instructions/>}
          visible={help}
          placement={'right'}>
          <Card title={title} id="view_domain" className={'view-domain'} bordered={false} extra={extra}>

            <Form.Item>
              <Input readOnly={true} value={domain && domain.url}/>
              <div className="floating-label force-float">Domain</div>
            </Form.Item>

            {
              domain && domain.display_name && (
                <Form.Item>
                  <Input ref={'websiteName'} defaultValue={domain && domain.display_name}/>
                  <div className="floating-label force-float">Website Name</div>
                </Form.Item>
              )
            }
            <Form.Item>
              <Button type="primary" className={'has-width'} onClick={this.updateWebsiteName}>
                Save
              </Button>
            </Form.Item>

            <div className="code-snippet">
              <div className={'line-num'}>
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
                <span>5</span>
                <span>6</span>
              </div>

              <Input.TextArea
                value={domain && domain.code_snippet}
                autosize={{minRows: 7, maxRows: 7}}
              />
              <CopyToClipboard
                text={domain && domain.code_snippet}
                onCopy={() => {
                  message.success('copied!');
                }}
              >
                <Button className={'copy-code'} type="default">
                  Copy code
                </Button>
              </CopyToClipboard>
            </div>

            <div className={'align-right'}>

              <HelpTip help={this.state.help} toggleHelp={this.toggleHelp} helpText={'Where to paste the code?'}/>

            </div>
          </Card>
        </Popover>
        <Card className={'ping-domain'} title={'Ping My Domain'}
              extra={<Switch checked={domain && domain.is_ping_allowed} onChange={this.togglePing}/>}>
          <p>
            Donec a nisl purus. Maecenas nulla magna, ullamcorper sed ante in, venenatis imperdiet mauris. Morbi in arcu
            auctor, posuere metus suscipit, sodales lectus. Aenean accumsan volutpat leo volutpat congue.
          </p>
        </Card>

        <Card title={'Website Maintenance Contact'}>
          <Maintenance domain={domain}/>
        </Card>
      </>
    );
  }
}

export default WebsiteInfo;
