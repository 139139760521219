import React, { Component } from 'react';
import { TextInput, SaveButton, SelectInput } from '@components/FormElements';
import { Card, Form, notification, Button } from 'antd';
import commonApiService from '@apiService';

class AddWebsite extends Component {
  state = {
    loading: false,
  };

  handleAddWebsite = ev => {
    ev.preventDefault();
    console.log('submitting?');
    const { key, script } = this.props.dataStore;

    this.props.form.validateFields((err, values) => {
      if (!err && key) {
        this.setState({
          loading: true,
        });
        commonApiService
          .list('/web/domains/validate', {
            display_name: values.name,
            url: values.prefix + values.url,
          })
          .then(successResult => {
            this.setState({
              loading: false,
            });
            this.props.nextStep({
              display_name: values.name,
              url: {
                prefix: values.prefix,
                link: values.url,
              },
              key: key,
              script: script,
            });
          })
          .catch(errorResult => {
            notification.open({
              message: 'Domain Validation Failed!',
              description: errorResult.errors,
              className: 'notification-error',
            });
            this.setState({
              loading: false,
            });
          });
      }
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { loading } = this.state;
    const { form, dataStore } = this.props;
    const { display_name, url } = dataStore;

    const prefixSelector = (
      <SelectInput
        style={{ width: 94, backgroundColor: 'white', color: 'black' }}
        form={form}
        name="prefix"
        required={true}
        list={[{ val: 'http://' }, { val: 'https://' }]}
        className="add-website-prefix"
        formClassName="margin-0"
        initialValue={
          url && url.prefix && url.prefix.indexOf('undefined') === -1
            ? url.prefix
            : 'http://'
        }
        removeWrap={true}
      />
    );

    return (
      <>
        <h3>Lets start by adding your website</h3>
        <Card bordered={false}>
          <p>
            First up, let's start by adding your website name and website domain below just to make sure everything is active.
          </p>
          <Form onSubmit={this.handleAddWebsite}>
            <TextInput
              form={form}
              name="name"
              initialValue={display_name}
              required={true}
              label="Website Name"
              floatingExtra={
                <div className={'left-badge'}>
                  <span className={'number'}>1</span>
                </div>
              }
            />
            <TextInput
              form={form}
              name="url"
              initialValue={url && url.link ? url.link : ''}
              addonBefore={prefixSelector}
              normalize={value => value && value.replace(/(^\w+:|^)\/\//, '')}
              required={true}
              forceFloat={true}
              placeholder="Enter Domain Link"
              label="Domain Link"
              floatingExtra={
                <div className={'left-badge'}>
                  <span className={'number'}>2</span>
                </div>
              }
            />
            <Button.Group>
              <SaveButton
                loading={loading}
                form={form}
                className="has-width"
                size="large"
                saveLabel="Next"
                savingLabel="Saving"
              />
            </Button.Group>
            {/*
            <Button
              loading={this.state.loading}
              size={'large'}
              className={'has-width'}
              htmlType="submit"
              type="primary"
            >
              Next
            </Button>
          */}
          </Form>
        </Card>
      </>
    );
  }
}

export default Form.create()(AddWebsite);
