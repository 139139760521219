import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import './SideLayout.scss';

import { Tooltip, Button } from 'antd';

import ic_dashboard from './icons/ic_dashboard.svg';
import ic_subsription from './icons/ic_subscription.svg';
import ic_users from './icons/ic_users.svg';
import ic_website from './icons/ic_website.svg';
import ic_contact from './icons/ic_contact.svg';
import ic_faq from './icons/ic_faq.svg';
import ic_admin from './icons/ic_admin.svg';

import arrow_left from './icons/ic_arrow_left.svg';

import buzzz from './logo/buzzz.svg';
import b from './logo/b.svg';

const LiTag = ({ sidebarSmall, title, logo, hideSidebar }) => (
  <>
    {sidebarSmall ? (
      <Tooltip mouseLeaveDelay={0} placement="right" title={title}>
        <li className="list-item" onClick={hideSidebar}>
          <img src={logo} alt={title} />
        </li>
      </Tooltip>
    ) : (
      <li className="list-item" onClick={hideSidebar}>
        <img src={logo} alt={title} />
        <span>{title}</span>
      </li>
    )}
  </>
);

class SideLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domains: null,
      sidebarSmall: false,
    };
  }
  componentWillMount() {
    this.props.fetchVerifiedDomains({
      verified: true,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      if (
        nextProps.verified_domain_data_success !== null &&
        !this.state.domains
      ) {
        const websites = nextProps.verified_domain_data_success.companies;
        if (
          websites.length === 0 ||
          (websites.length === 1 && websites[0].forms.length === 0)
        ) {
          this.props.history.push('/setup');
        } else {
          this.setState({
            domains: websites,
          });
        }
      }
    }
  }

  handleSidebar = () => {
    this.setState({
      sidebarSmall: this.props.handleSidebarSize(),
    });
  };

  getSuperUserNavigations = () => {
    return (
      <div>
        <ul className="main-navigation">
          <NavLink to="/admin/emailtemplates">
            <LiTag
              title={'Email Templates'}
              logo={ic_contact}
              hideSidebar={this.props.hideSidebar}
              sidebarSmall={this.state.sidebarSmall}
            />
          </NavLink>
          <NavLink to="/admin/configurations">
            <LiTag
              title={'Configurations'}
              logo={ic_website}
              hideSidebar={this.props.hideSidebar}
              sidebarSmall={this.state.sidebarSmall}
            />
          </NavLink>
          <NavLink to="/admin/subscription-plans">
            <LiTag
              title={'Subscription Plans'}
              logo={ic_subsription}
              hideSidebar={this.props.hideSidebar}
              sidebarSmall={this.state.sidebarSmall}
            />
          </NavLink>
        </ul>
      </div>
    );
  };

  getMainNavigation = () => {
    let domains = null;

    if (this.state.domains && this.state.domains.length > 0) {
      domains =
        this.state.domains &&
        this.state.domains.map((domain, i) => {
          return domain.title && domain.title.length > 16 ? (
            <NavLink to={'/dashboard/' + domain.id} key={i}>
              <li
                className="list-group-item"
                key={domain.id}
                onClick={e => {
                  this.props.hideSidebar();
                }}
              >
                <Tooltip
                  mouseLeaveDelay={0}
                  placement="right"
                  title={domain.title}
                >
                  {domain.title}
                </Tooltip>
              </li>
            </NavLink>
          ) : (
            <NavLink
              to={'/dashboard/' + domain.id}
              activeClassName="active"
              key={i}
            >
              <li
                className="list-group-item"
                key={domain.id}
                onClick={e => {
                  this.props.hideSidebar();
                }}
              >
                {domain.title}
              </li>
            </NavLink>
          );
        });
    }

    return (
      <ul className="main-navigation">
        <NavLink
          to="/dashboard"
          activeClassName="active"
          onClick={this.props.hideSidebar}
        >
          <LiTag
            title={'My Websites'}
            logo={ic_dashboard}
            hideSidebar={this.props.hideSidebar}
            sidebarSmall={this.state.sidebarSmall}
          />
        </NavLink>
        <ul className="list-group">{domains}</ul>
        <NavLink
          hidden={!this.props.fullAccess}
          to="/users"
          activeClassName="active"
        >
          <LiTag
            title={'Users'}
            logo={ic_users}
            hideSidebar={this.props.hideSidebar}
            sidebarSmall={this.state.sidebarSmall}
          />
        </NavLink>
        <NavLink
          hidden={!this.props.owner}
          to="/subscription"
          activeClassName="active"
        >
          <LiTag
            title={'My Subscription'}
            logo={ic_subsription}
            hideSidebar={this.props.hideSidebar}
            sidebarSmall={this.state.sidebarSmall}
          />
        </NavLink>
      </ul>
    );
  };

  render() {
    const navigation = this.props.super_user
      ? this.getSuperUserNavigations()
      : this.getMainNavigation();
    const trial_expires_in = localStorage.getItem('trial_expires_in');

    return (
      <div className={ this.props.owner ? 'sidebar': 'sidebar subuser'}>
        <div className="sidebar-header">
          <div className="logo-container">
            <img
              src={this.state.sidebarSmall ? b : buzzz}
              className="main-logo"
              alt="buzzz.io"
            />
            <Button
              className={'sidebar-ctrl mobile-hide'}
              onClick={this.handleSidebar}
              shape="circle"
            >
              <img src={arrow_left} alt="arrow" />
            </Button>
          </div>
        </div>
        <div className="sidebar-body">{navigation}</div>

        <div className="sidebar-footer">
          {!this.props.super_user && !trial_expires_in && (
            <div className={'checkout-box mobile-hide'}>
              <p>
                {trial_expires_in <= 0
                  ? `Trial pack expired`
                  : `${trial_expires_in} days left in trial`}
              </p>
            </div>
          )}
          <ul className="main-navigation">
            {!this.props.super_user && (
              <>
                <NavLink to="/faq">
                  <LiTag
                    title={'FAQs'}
                    logo={ic_faq}
                    hideSidebar={this.props.hideSidebar}
                    sidebarSmall={this.state.sidebarSmall}
                  />
                </NavLink>
                <NavLink hidden={!this.props.owner} to="/view-website">
                  <LiTag
                    title={'Admin'}
                    logo={ic_admin}
                    hideSidebar={this.props.hideSidebar}
                    sidebarSmall={this.state.sidebarSmall}
                  />
                </NavLink>
              </>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default SideLayout;
