import React, { Component } from 'react';

import { Route, Switch, Redirect, Link } from 'react-router-dom';

import HeaderLayout from '@layouts/Header/HeaderLayout';
import SideLayout from '@layouts/Side/SideLayout';

import commonApiService from '@apiService';
import { Icon, Button, Dropdown, Menu, Modal, notification } from 'antd';

import SubscriptionContainer from '@containers/SubscriptionContainer';

import UserAddEditContainer from '@containers/UserAddEditContainer';
import UserContainer from '@containers/UserContainer';

import './MainLayout.scss';
import AlertsLayout from './AlertsLayout';
import AddFormLayout from '@layouts/AddForm/AddFormLayout';

import ProfileLayout from '@layouts/Profile/ProfileLayout';
import moment from 'moment';
import SubscriptionPlanLayout from '@layouts/Admin/SubscriptionPlanLayout';
import EmailTemplateLayout from '@layouts/Admin/EmailTemplateLayout';
import ConfigurationLayout from '@layouts/Admin/ConfigurationLayout';
import BroadCastLayout from './BroadCastLayout';
import DashboardLayout from '@layouts/Dashboard/DashboardLayout';
import ContactUsComponent from '@components/common/ContactUsLayout';
import FAQLayout from '@layouts/FAQ/FAQLayout';
import AddWebsiteLayout from '@layouts/AddWebsite/AddWebsiteLayout';
import WebsiteListLayout from '@layouts/Website/WebsiteListLayout';
import WebsiteLayout from '@layouts/Website/WebsiteLayout';

import { APP_IOS, APP_ANDROID } from '@constants/links';

const PrivateRoute = ({ component: Component, ...rest }) => {
  if (rest.path === '/subscription') {
    return (
      <Route
        {...rest}
        render={props =>
          localStorage.getItem('owner') === 'true' ? (
            <Component {...props} />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={props =>
          localStorage.getItem('user') &&
          (localStorage.getItem('owner') === 'true' ||
            JSON.parse(localStorage.getItem('user')).access_level ===
              'FullAccess') ? (
            <Component {...props} />
          ) : (
            <Redirect to="/dashboard" />
          )
        }
      />
    );
  }
};

class NotFound extends Component {
  render() {
    return <div>NOT FOUND</div>;
  }
}

// let notifications = {};

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged_in: false,
      user_data: null,
      show_sidebar: false,
      showContactUsModal: false,
      sidebarSmall: false,
      appInstalled: localStorage.getItem('appInstalled') === 'true',
    };
  }

  componentWillMount() {
    if (localStorage.getItem('token')) {
      commonApiService
        .list('/user/notifications', { type: 'all' })
        .then(successResult => {
          this.refs.broadcast.updateNotification(successResult.data);
        })
        .catch(errorResult => {});
    } else {
      this.props.history.push('/signin');
    }
  }

  componentDidMount() {
    //Make the necessary api calls here if required
    if (localStorage.getItem('token')) {
      this.setState(
        {
          logged_in: true,
          super_user: localStorage.getItem('super_user') === 'true',
        },
        () => {
          document.addEventListener('fcm_notification', event => {
            let fcmData = event.detail;
            console.log('fcmData:', event);
            if (fcmData) {
              notification.open({
                message: fcmData.title,
                description: fcmData.message,
                className: 'notification-neutral',
              });

              switch (fcmData.key) {
                case 'ENQUIRY_NOTIFICATION':
                  this.refs.broadcast &&
                    this.refs.broadcast.prependNotification(
                      {
                        custom: fcmData.custom,
                        priority: fcmData.priority,
                        read: fcmData.read,
                        type: fcmData.type,
                        details: {
                          key: fcmData.key,
                          title: fcmData.title,
                          message: fcmData.message,
                        },
                        created_at: fcmData.created_at,
                      },
                      'form',
                    );
                  this.props.actions.processRealtimeData(fcmData);
                  break;
                case 'DOMAIN_VERIFICATION':
                  this.refs.broadcast &&
                    this.refs.broadcast.prependNotification(
                      {
                        custom: {},
                        priority: '',
                        read: 1,
                        type: 'Domain',
                        details: {
                          title: fcmData.title,
                          message: fcmData.message,
                        },
                        created_at: moment().unix(),
                      },
                      'other',
                    );

                  this.props.actions.processRealtimeData(fcmData);
                  break;

                case 'SUBMISSION':
                  this.refs.broadcast &&
                    this.refs.broadcast.prependNotification(
                      {
                        custom: {},
                        priority: '',
                        read: 1,
                        type: fcmData.type,
                        details: {
                          title: fcmData.title,
                          message: fcmData.message,
                        },
                        created_at: moment().unix(),
                      },
                      'other',
                    );
                  break;

                case 'EMERGENCY':
                  this.refs.alertLayout && (fcmData.emergency
                    ? this.refs.alertLayout.domainDown(fcmData)
                    : this.refs.alertLayout.domainUp(fcmData));
                  break;

                case 'APP_INSTALLED':
                  localStorage.setItem('appInstalled', 'true');
                  if(!this.state.appInstalled){
                    this.setState({
                      appInstalled: true
                    });
                  }
                  break;
                default:
              }
            }
          });
        },
      );

      /*
       * The following check makes sure that on reloads, once on a specific route does not navigate the user to default landing page
       * If the user is logged in and the domain landing page url is hit, redirect the user to the landing page dashboard
       */
      if (this.props.location.pathname === '/') {
        this.props.history.push('/dashboard');
      }
    } else {
      this.props.history.push('/signin');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      if (nextProps.user_access_limit !== null) {
        this.refs.headerLayout &&
          this.refs.headerLayout.updateUserAccess(nextProps.user_access_limit);
      }

      if (
        nextProps.user_data !== null &&
        nextProps.user_data !== this.props.user_data &&
        nextProps.user_data.super_user
      ) {
        localStorage.setItem('token', nextProps.user_data.token);
        localStorage.setItem('user', JSON.stringify(nextProps.user_data.user));
        localStorage.setItem('super_user', nextProps.user_data.super_user);
        this.setState(
          {
            super_user: true,
          },
          () => {
            this.props.history.push('/admin/subscription-plans');
          },
        );
      } else if (
        this.state.user_data !== nextProps.user_data &&
        !this.state.logged_in
      ) {
        //By default the user is to be navigated to the dashboard landing page
        this.setState(
          {
            logged_in: true,
            user_data: nextProps.user_data,
          },
          () => {
            this.props.history.push('/dashboard');
          },
        );
      }
    }
  }

  logoutUser = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('subscription_id');
    localStorage.removeItem('owner');
    localStorage.removeItem('super_user');
    localStorage.removeItem('ping_my_domain');
    localStorage.removeItem('follow_up_status_general');
    localStorage.removeItem('follow_up_status');
    localStorage.removeItem('subscription_expiry_date');
    localStorage.removeItem('is_plan_expired');
    localStorage.removeItem('trial_expires_in');
    // localStorage.clear();

    this.props.actions.logoutUser();
    commonApiService
      .post('/logout', { device_token: localStorage.getItem('fcm_token') })
      .then(
        successResult => {
          localStorage.removeItem('fcm_token');
        },
        errorResult => {
          localStorage.removeItem('fcm_token');
        },
      );

    this.props.auth2 && this.props.auth2.signOut().then(function() {});
    //send a request to logout the user involved
    this.setState(
      {
        logged_in: false,
        super_user: false,
      },
      () => {
        window.messaging &&
          window.messaging.getToken().then(function(newtoken) {
            localStorage.setItem('fcm_token', newtoken);
          });
        this.props.history.push('/signin');
      },
    );
  };

  hideSidebar = () => {
    this.setState({
      show_sidebar: false,
    });
  };

  hideContactUsModal = () => {
    this.setState({
      showContactUsModal: false,
    });
  };

  handleSidebarSize = () => {
    const sidebarSmall = !this.state.sidebarSmall;
    this.setState({
      sidebarSmall: sidebarSmall,
    });
    return sidebarSmall;
  };
  // check

  render() {
    let network_down = this.props.network_down ? 'network-down' : '';
    let small_sidebar = this.state.sidebarSmall ? 'small-sidebar' : '';
    let show_sidebar = this.state.show_sidebar ? 'show-sidebar' : '';
    let app_installed = !this.state.appInstalled ? 'app-install' : '';

    const menu = (
      <Menu>
        {!this.state.super_user && (
          <Menu.Item>
            <Link to="/profile">Your Profile</Link>
          </Menu.Item>
        )}
        <Menu.Item>
          <a
            onClick={e => {
              this.setState({ showContactUsModal: true });
            }}
          >
            Contact Us
          </a>
        </Menu.Item>
        <Menu.Item className={'logout'}>
          <a href onClick={this.logoutUser}>
            Logout
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        {
          this.state.appInstalled ? null : (
            <div className={'app-install-alert'}>
              <p>
                Want to get rid of this annoying red bar? Download the mobile app now and start using Buzzz properly.
                <span>
                  <a href={APP_ANDROID} target={'_blank'}><img src="/images/ic_playstore.svg" alt="buzzz playstore app"/></a>
                  <a href={APP_IOS} target={'_blank'}><img src="/images/ic_appstore.svg" alt="buzzz appstore app"/></a>
                </span>
              </p>
            </div>
          )
        }
        <div className={`main-layout ${app_installed} ${network_down} ${small_sidebar} ${show_sidebar}`}>
          <div className="main-header">
            <Button.Group>
              {this.state.super_user ? null : <BroadCastLayout ref="broadcast" />}
              <Dropdown
                getPopupContainer={() => document.getElementById('dropdown-zone')}
                overlay={menu}
                placement={'bottomRight'}
                trigger={['click']}
                overlayClassName="profile-dropdown"
              >
                <Button
                  className="profile-btn"
                  id="profile-btn"
                  type="default"
                  size="large"
                  shape="circle"
                >
                  <span className="icon-user" />
                </Button>
              </Dropdown>
            </Button.Group>
          </div>
          <SideLayout
            ref="sidebarLayout"
            hideSidebar={this.hideSidebar}
            fetchVerifiedDomains={this.props.domain_actions.fetchVerifiedDomains}
            verified_domain_data_success={this.props.verified_domain_data_success}
            owner={this.props.owner}
            fullAccess={this.props.full_access}
            super_user={this.state.super_user}
            history={this.props.history}
            handleSidebarSize={this.handleSidebarSize}
          />
          <AlertsLayout ref="alertLayout" {...this.props} />

          {!/dashboard|add-form|website/.test(
            this.props.history.location.pathname,
          ) && <HeaderLayout ref="headerLayout" {...this.props} />}

          {this.state.super_user ? (
            <Switch>
              <Route
                exact
                path="/admin/subscription-plans"
                component={SubscriptionPlanLayout}
                title="Buzzz.io | Admin Panel"
              />
              <Route
                exact
                path="/admin/emailtemplates"
                component={EmailTemplateLayout}
                title="Buzzz.io | Admin Panel"
              />
              <Route
                exact
                path="/admin/configurations"
                component={ConfigurationLayout}
                title="Buzzz.io | Admin Panel"
              />
            </Switch>
          ) : (
            <Switch>
              <Route path="/dashboard" component={DashboardLayout} />
              <PrivateRoute
                path="/subscription"
                component={SubscriptionContainer}
              />
              <PrivateRoute
                exact
                path={['/add-website', '/add-website/:domain_id']}
                component={AddWebsiteLayout}
              />
              <Route exact path="/view-website" component={WebsiteListLayout} />
              <PrivateRoute
                exact
                path={'/view-website/:domain_id'}
                component={WebsiteLayout}
              />
              <PrivateRoute
                exact
                path="/add-form/:domain_id"
                component={AddFormLayout}
              />
              <PrivateRoute
                exact
                path="/users/edit/:user_id"
                component={UserAddEditContainer}
              />
              <PrivateRoute path="/users/add" component={UserAddEditContainer} />
              <PrivateRoute exact path="/users" component={UserContainer} />
              <Route exact path="/profile" component={ProfileLayout} />
              <Route exact path="/faq" component={FAQLayout} />
              <Route path="*" component={NotFound} />
            </Switch>
          )}

          <div
            className="sidebar-control"
            onClick={() => {
              this.setState({
                show_sidebar: true,
              });
            }}
          >
            <svg height="40" width="40">
              <polygon points="0,0 0,40 40,0" className="triangle" />
            </svg>
            <Icon type="arrow-right" />
          </div>

          <div
            className="sidebar-overlay"
            onClick={() => {
              this.setState({
                show_sidebar: false,
              });
            }}
          />
          <Modal
            onCancel={this.hideContactUsModal}
            visible={this.state.showContactUsModal}
            footer={null}
            title="Send us a message"
          >
            <ContactUsComponent handleCancel={this.hideContactUsModal} />
          </Modal>
        </div>
      </>
    );
  }
}

export default MainLayout;
