import React from 'react';
import { Input } from 'antd';

class CardInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
    };
  }

  onKeyUp = (e, index) => {
    const { value, maxLength } = e.target;

    if (index > 1 && index < 4) {
      if (
        this.refs['card-input-' + (index - 1)].input.value.length !== maxLength
      ) {
        this.refs['card-input-' + (index - 1)].focus();
        this.refs['card-input-' + index].input.value = '';
      }
    }
    if (value.length === maxLength) {
      this.refs['card-input-' + (index + 1)].focus();
    }
  };

  getCardNumber = () => {
    //@todo : Validation;
    let n =
      this.refs['card-input-1'].input.value +
      this.refs['card-input-2'].input.value +
      this.refs['card-input-3'].input.value +
      this.refs['card-input-4'].input.value;
    if (n && n.length === 16) {
      this.setState = {
        error: false,
      };
      return n;
    }

    this.setState({
      error: true,
    });

    return null;
  };

  render() {
    const { last4Digits, cardNumber } = this.props;

    return (
      <div>
        <div className="card-input-field">
          <Input
            ref="card-input-1"
            placeholder="XXXX"
            maxLength="4"
            defaultValue={cardNumber ? cardNumber.substr(0, 4) : ''}
            onKeyUp={e => {
              this.onKeyUp(e, 1);
            }}
          />
          <Input
            ref="card-input-2"
            placeholder="XXXX"
            maxLength="4"
            defaultValue={cardNumber ? cardNumber.substr(4, 4) : ''}
            onKeyUp={e => {
              this.onKeyUp(e, 2);
            }}
          />
          <Input
            ref="card-input-3"
            placeholder="XXXX"
            maxLength="4"
            defaultValue={cardNumber ? cardNumber.substr(8, 4) : ''}
            onKeyUp={e => {
              this.onKeyUp(e, 3);
            }}
          />
          <Input
            ref="card-input-4"
            placeholder="XXXX"
            maxLength="4"
            value={cardNumber ? cardNumber.substr(12, 4) : last4Digits}
            readOnly
          />
        </div>
        <div className="ccard-message" hidden={!this.state.error}>
          Invalid Card Number
        </div>
      </div>
    );
  }
}

export default CardInput;
