import React, { Component } from 'react';
import {
  Row,
  Col,
  Tabs,
  Table,
  Select,
  Badge,
  Skeleton,
  Affix,
  Button,
  Avatar,
  Tooltip,
  Menu,
  Dropdown,
  Icon,
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import commonApiService from '@apiService';
import AddFormModel from '../AddForm/subcomponents/AddFormModel';
import ProspectForm from './ProspectForm';

import Graph from './Graph';
import ModalContent, { ResponseDOM, EnquiryDOM } from './DashboardWebsiteSubComponents';

import WebsiteUsers from '../Website/subcomponents/WebsiteUsers';
import {connect} from "react-redux";

const TabPane = Tabs.TabPane;
const Option = Select.Option;

const graphReset = {
  labels: [],
  data: [],
  dataOld: [],
  count: 0,
  countOld: 0,
  diff: 0,
  percent: 0
}


class DashboardWebsite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user:
        localStorage && localStorage.user
          ? JSON.parse(localStorage.user)
          : { user_id: null },
      sort_order: null,
      sort_by: null,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      },
      notification_list_data: [
        {
          call_logs: [],
          company: null,
          created_at: null,
          email: '',
          first_name: '',
          form_type: '',
          id: 1,
          phone: '',
          response_time: null,
          status: '',
        },
        {
          call_logs: [],
          company: null,
          created_at: null,
          email: '',
          first_name: '',
          form_type: '',
          id: 2,
          phone: '',
          response_time: null,
          status: '',
        },
        {
          call_logs: [],
          company: null,
          created_at: null,
          email: '',
          first_name: '',
          form_type: '',
          id: 3,
          phone: '',
          response_time: null,
          status: '',
        },
      ],
      forms: [],
      users: [],
      domain_id: null,
      domain_name: '',
      domain_list: null,
      date_picker_range: [moment().subtract(7, 'd'), moment()],
      selectedForm: null,
      loading: true,
      graph_loading: true,
      enquiry: graphReset,
      response: graphReset,
      addProspect: false,
    };

    this.fullAccess = false;
    if (localStorage && localStorage.user) {
      let user = JSON.parse(localStorage.user);
      let owner = localStorage.owner === 'true';
      this.fullAccess = owner ? true : user.access_level === 'FullAccess';
    }
  }

  componentDidMount() {
    this.initialise();
  }

  initialise = (noupdate) => {
    this.fetchNotificationList({
      min_timestamp: this.state.date_picker_range[0].unix(),
      domain_id: this.props.match.params.domain_id,
    }, noupdate);
  };

  menu = () => {
    return (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => {
            this.exportData('csv');
          }}
        >
          Download CSV
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => {
            this.exportData('xls');
          }}
        >
          Download Excel
        </Menu.Item>
      </Menu>
    );
  };

  exportData = format => {
    const form = this.state.forms.find(
      form => form.id === parseInt(this.state.selectedForm),
    );
    const form_name = form ? form.name : '';

    var params = Object.assign(
      {
        form_id: this.state.selectedForm,
        max_timestamp: this.state.date_picker_range[1].unix(),
        min_timestamp: this.state.date_picker_range[0].unix(),
        export_to: format,
        form_name,
        domain_name: this.state.domain_name,
      },
      this.state.sort_by &&
        this.state.sort_order && {
          sort_by: this.state.sort_by,
          sort_order: this.state.sort_order,
        },
      this.state.search_keyword && {
        keyword: this.state.search_keyword,
      },
    );

    commonApiService
      .exportToFile('/web/export_notifications/', params)
      .then(successResult => {
        this.setState({
          loading: false,
        });
      })
      .catch(errorResult => {
        this.setState({
          loading: false,
        });
      });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match.params.domain_id !== this.props.match.params.domain_id ||
      (nextProps.realtime_update && nextProps.realtime_update.key === 'ENQUIRY_NOTIFICATION')
    ) {
      this.setState({
        loading: true,
        graph_loading: true,
      });
      this.fetchNotificationList({
        min_timestamp: this.state.date_picker_range[0].unix(),
        domain_id: nextProps.match.params.domain_id,
      });
    }
  }

  fetchNotificationList = (params, noupdate) => {
    commonApiService
      .list('/web/notifications', params)
      .then(successResult => {
        if(noupdate){
          this.setState(
            Object.assign(
              successResult.data.users && { users: successResult.data.users },
            ),
            this.handleGraphs,
          );
        }else{
          this.setState(
            Object.assign(
              {
                notification_list_data: successResult.data.notifications,
                loading: false,
                pagination: Object.assign(this.state.pagination, {
                  total: successResult.data.total_count,
                }),
              },
              successResult.data.domain_name && {
                domain_name: successResult.data.domain_name,
              },
              successResult.data.forms && { forms: successResult.data.forms },
              successResult.data.users && { users: successResult.data.users },
              successResult.data.forms &&
              successResult.data.forms.length > 0 && {
                selectedForm: successResult.data.forms[0].id.toString(),
              },
              params.domain_id && { domain_id: params.domain_id },
            ),
            this.handleGraphs,
          );
        }
      })
      .catch(errorResult => {
        if(errorResult.errorCode === 404){
          this.props.history.push('/dashboard');
        }
      });
  };

  handleGraphs = () => {
    if(!this.state.selectedForm){
      this.resetGraph();
      return;
    }

    commonApiService
      .fetch('web/graph_details', {
        form_id: this.state.selectedForm,
        from_date: this.state.date_picker_range[0].unix(),
        to_date: this.state.date_picker_range[1].unix(),
      })
      .then(
        successResult => {
          let {
            current_stats_enquiry ,
            current_stats_response,
            total_current_enquiries,
            total_current_response,
            total_prev_enquiries,
            total_prev_response,
          } = successResult.data;

          let graphData = {
            labels: [],
            enquiry: [],
            response: [],
            enquiry_count: Number(total_current_enquiries) || 0,
            response_count: Number(total_current_response)|| 0,
            enquiry_old_count: Number(total_prev_enquiries) || 0,
            response_old_count: Number(total_prev_response) || 0,
          };

          let length =
            current_stats_enquiry.length  === current_stats_response.length
              ? current_stats_enquiry.length
              : 0;

          for (let i = 0; i < length; i++) {
            graphData.labels.push(
              moment(current_stats_enquiry[i].date).format('DD MMMM'),
            );
            graphData.enquiry.push(current_stats_enquiry[i].value);
            graphData.response.push(current_stats_response[i].value);
          }

          let enquiryDiff =  Number(graphData.enquiry_count) - Number(graphData.enquiry_old_count);
          let enquiryAdd =  Number(graphData.enquiry_count) + Number(graphData.enquiry_old_count);
          let enquiryPercent =  100 * (Math.abs(enquiryDiff) / enquiryAdd);

          let responseDiff =  Number(graphData.response_count) - Number(graphData.response_old_count);
          let responseAdd =  Number(graphData.response_count) + Number(graphData.response_old_count);
          let responsePercent = 100 *  (Math.abs(responseDiff) / responseAdd);

          this.setState({
            graph_loading: false,
            enquiry: {
              labels: graphData.labels,
              data: graphData.enquiry,
              count: graphData.enquiry_count,
              countOld: graphData.enquiry_old_count,
              diff: enquiryDiff,
              percent: enquiryPercent,
            },
            response: {
              labels: graphData.labels,
              data: graphData.response,
              count: graphData.response_count,
              countOld: graphData.response_old_count,
              diff: responseDiff,
              percent: responsePercent,
            },
            loading: false,
          });
        },
        errorResult => {
          this.resetGraph();
        },
      );
  };

  resetGraph = () => {
    this.setState({
      enquiry: graphReset,
      response: graphReset,
      loading: false,
      graph_loading: false,
    });
  };

  tabChangeHandler = activeKey => {
    this.setState({
      selectedForm: activeKey,
      loading: true,
      graph_loading: true,
    });
    this.fetchNotificationList(
      Object.assign(
        {
          form_id: activeKey,
          pagesize: this.state.pagination.pageSize,
          nextpage: this.state.pagination.current,
          max_timestamp: this.state.date_picker_range[1].unix(),
          min_timestamp: this.state.date_picker_range[0].unix(),
        },
        this.state.sort_by &&
          this.state.sort_order && {
            sort_by: this.state.sort_by,
            sort_order: this.state.sort_order,
          },
        this.state.search_keyword && {
          keyword: this.state.search_keyword,
        },
      ),
    );
  };

  onDateRangeSelected = selected => {
    let date = [moment().subtract(selected, 'd'), moment()];

    if (date[0] === undefined || date[1] === undefined) return;

    this.setState(
      {
        date_picker_range: date,
        loading: true,
        graph_loading: true,
      },
      () => {
        this.fetchNotificationList(
          Object.assign(
            {
              form_id: this.state.selectedForm,
              pagesize: this.state.pagination.pageSize,
              nextpage: this.state.pagination.current,
              max_timestamp: this.state.date_picker_range[1].unix(),
              min_timestamp: this.state.date_picker_range[0].unix(),
            },
            this.state.sort_by &&
              this.state.sort_order && {
                sort_by: this.state.sort_by,
                sort_order: this.state.sort_order,
              },
            this.state.search_keyword && {
              keyword: this.state.search_keyword,
            },
          ),
        );
      },
    );
  };

  handleAddProspect = () => {
    for (let i = 0; i < this.state.forms.length; i++) {
      const form = this.state.forms[i];
      if (this.state.selectedForm === form.id.toString()) {
        this.setState({
          addProspect: true,
          addProspectForm: form,
        });
        return;
      }
    }
  };

  handleAddProspectCancel = () => {
    this.setState({
      addProspect: false,
      addProspectForm: null,
    });
  };

  onTableChange = (pagination, filters, sorter) => {
    this.setState({
      pagination: Object.assign(pagination, {
        current: pagination.current,
      }),
      loading: true,
      sort_by: sorter.field,
      sort_order: sorter.order,
    });
    this.fetchNotificationList(
      Object.assign(
        {
          form_id: this.state.selectedForm,
          pagesize: pagination.pageSize,
          max_timestamp: this.state.date_picker_range[1].unix(),
          min_timestamp: this.state.date_picker_range[0].unix(),
        },
        Object.keys(sorter).length > 0 && {
          sort_by: sorter.field,
          sort_order: sorter.order,
        },
        this.state.search_keyword && {
          keyword: this.state.search_keyword,
        },
        this.state.pagination.current !== pagination.current && {
          nextpage: pagination.current,
        },
      ),
    );

    window.scrollTo(0, 0);
  };

  handleShowAddForm = () => {
    this.refs.add_form_modal.showAddForm();
  };

  handleShowWebsiteUsers = () => {
    this.refs.assign_user_modal.showUserList();
  };

  getTableCols = () => {
    return [
      {
        title: 'Prospect Name',
        dataIndex: 'first_name',
        key: 'first_name',
        sorter: false,
        render: text => (
          <Skeleton paragraph={false} active loading={this.state.loading}>
            {' '}
            <span className="highlight fix-width">{text || '-'}</span>
          </Skeleton>
        ),
      },
      {
        title: 'Time',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        render: text => (
          <>
            <Skeleton paragraph={false} active loading={this.state.loading}>
              <span className="fix-width large">
                {moment.unix(text).fromNow()},{' '}
                {moment.unix(text).format('hh:mm A')}
              </span>
            </Skeleton>
          </>
        ),
      },
      {
        title: 'Responded in',
        dataIndex: 'response_time',
        key: 'response_time',
        sorter: true,
        render: text => (
          <Skeleton paragraph={false} active loading={this.state.loading}>
            {text ? text + ' hours' : '-'}
          </Skeleton>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: false,
        render: (text, record) => {
          let cell = null;
          if (record.call_logs.length) {
            switch (record.call_logs[0].status) {
              case 'Called':
                cell = (
                  <>
                    <div className="called">
                      <span className="status-icon icon-called" />
                      Called -{' '}
                      {moment
                        .unix(record.call_logs[0].created_at)
                        .format('hh:mm A')}
                    </div>
                    <div className="capsule">
                      {record.call_logs[0].user_details.first_name}
                    </div>
                  </>
                );
                break;
              case 'NotReachable':
                cell = (
                  <>
                    <div className="not-reachable">
                      <span className="status-icon icon-busy" />
                      Not Reachable -{' '}
                      {moment
                        .unix(record.call_logs[0].created_at)
                        .format('hh:mm A')}
                    </div>
                    <div className="capsule">
                      {record.call_logs[0].user_details.first_name}
                    </div>
                  </>
                );
                break;
              case 'Engaged':
                cell = (
                  <>
                    <div className="engaged">
                      <span className="status-icon icon-busy" />
                      Engaged -{' '}
                      {moment
                        .unix(record.call_logs[0].created_at)
                        .format('hh:mm A')}
                    </div>
                    <div className="capsule">
                      {record.call_logs[0].user_details.first_name}
                    </div>
                  </>
                );
                break;
              default:
                cell = null;
            }
          } else {
            cell = <div className="danger-color new-prospect">New prospect</div>;
          }
          return (
            <Skeleton paragraph={false} active loading={this.state.loading}>
              <div className={'status-wrap'}>{cell}</div>
            </Skeleton>
          );
        },
      },
      {
        title: (
          <Dropdown overlay={this.menu} placement={'bottomRight'}>
            <Button shape={'circle'} className="no-bg-strict download">
              <span className="icon-download" />
            </Button>
          </Dropdown>
        ),
        dataIndex: 'entry_type',
        key: 'entry_type',
        sorter: false,
        render: text => (
          <>
            <Skeleton paragraph={false} active loading={this.state.loading}>
              <p className={'entry-type'}>{text}</p>
            </Skeleton>
          </>
        ),
      },
    ]
  };

  getuserDOM = () => {
    const usersDOM = [];
    const { users } = this.state;
    if (users && users.length > 0) {
      const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
      const userCount =
        users.length < 4 ? users.length : 4;
      for (let j = 0; j < userCount; j++) {
        const user = users[j];
        if (user.avatar) {
          usersDOM.push(
            <div key={j} className={'user-profile-pic'}>
              <Avatar src={user.avatar} />
            </div>,
          );
        } else {
          const initials =
            user.first_name[0].toUpperCase() + user.last_name[0].toUpperCase();
          const color =
            colorList[
            (user.first_name[0].charCodeAt() +
              user.last_name[0].charCodeAt()) %
            4
              ];
          usersDOM.push(
            <div key={j} className={'user-profile-pic'}>
              <Avatar style={{ backgroundColor: color, color: '#fff' }}>
                {initials}
              </Avatar>
            </div>,
          );
        }
      }
      if (users.length > 4) {
        usersDOM.push(<p key={userCount+ 1}>+{users.length - 4}</p>);
      }
    }
    return usersDOM;
  }

  render() {

    const {
      addProspect,
      addProspectForm,
      domain_name,
      domain_id,
      loading,
      forms,
      selectedForm,
      users,
      pagination,
      date_picker_range,
      enquiry,
      response,
      notification_list_data = []
    } = this.state;

    const columns = this.getTableCols();

    let tabs = (
      <div hidden={loading} className="error-notification-box">
        You have no forms for us to track yet
      </div>
    );

    if (forms && forms.length > 0) {
      let tabPaneList = forms.map((form, i) => {
        return (
          <TabPane
            tab={
              <div className="tab_title">
                {form.name} <Badge count={form.notification_count} />
              </div>
            }
            key={form.id}
          />
        );
      });

      tabs = (
        <Tabs
          defaultActiveKey={selectedForm}
          activeKey={selectedForm}
          onChange={this.tabChangeHandler}
          tabBarExtraContent={
            <Select
              className="ant-select-range"
              onChange={this.onDateRangeSelected}
              defaultValue={7}
            >
              <Option value={7}> Last 7 Days</Option>
              <Option value={14}> Last 14 Days</Option>
              <Option value={30}> Last 1 Month</Option>
            </Select>
          }
        >
          {tabPaneList}
        </Tabs>
      );
    }

    let usersDOM = this.getuserDOM();

    return (
      <>
        <Affix offsetTop={localStorage.getItem('appInstalled') === "true" ? 0 : 48}>
          <div className="headerbar headerbar-dashboard">
            <div className="headerbar-block breadcrumb left">
              <div className="breadcrumb-title">
                <Link to="/dashboard">
                  <Icon type="left" /> My Websites
                </Link>
              </div>
              <div className="breadcrumb-subtitle">
                {this.state.domain_name}
              </div>
            </div>
            <div className="headerbar-block right">
              <Button.Group>
                <div className="user-images mobile-hide">{usersDOM}</div>
                <Button
                  className={'no-bg mobile-hide'}
                  size="large"
                  type="default"
                  hidden={!this.fullAccess}
                  onClick={this.handleShowWebsiteUsers}
                >
                  Assign User
                </Button>
                <Tooltip title={'Add new form to this website'}>
                  <Button
                    className="no-bg new-form-btn mobile-hide"
                    size="large"
                    type="default"
                    hidden={!this.fullAccess}
                    onClick={this.handleShowAddForm}
                  >
                    + New Form
                  </Button>
                </Tooltip>
                <Button
                  size="large"
                  className={'no-bg'}
                  type="default"
                  hidden={!this.state.forms}
                  onClick={this.handleAddProspect}
                >
                  + New Prospect
                </Button>
              </Button.Group>
            </div>
            <div className="clear-fix" />
          </div>
        </Affix>
        <main className="main-container">
          <div className="dashboard">
            {tabs}

            {forms && forms.length > 0 && (
              <>
                <div className="dashboard-charts">
                  <Row>
                    <Col sm={12} xs={24}>
                      <div className="graph-cell">
                        <EnquiryDOM {...enquiry}/>
                        <div className="graph-cell-inner">
                          <Graph
                            width={500}
                            height={400}
                            date_picker_range={date_picker_range}
                            graphs={enquiry}
                            type={'ENQUIRY'}
                          />
                        </div>
                      </div>

                    </Col>
                    <Col sm={12} xs={24}>
                      <div className="graph-cell">
                        <ResponseDOM {...response} />
                        <div className="graph-cell-inner">
                          <Graph
                            width={500}
                            height={400}
                            date_picker_range={date_picker_range}
                            graphs={response}
                            type={'RESPONSE'}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Table
                  scroll={{ x: 768 }}
                  columns={columns}
                  className="dashboard_table submission"
                  locale={{
                    emptyText: (
                      <div className="error-notification-box">
                        No enquiries found for the selected date
                      </div>
                    ),
                  }}
                  pagination={pagination}
                  onChange={this.onTableChange}
                  dataSource={notification_list_data}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        this.refs.submission.showModal(record);
                      },
                    }
                  }}
                />
              </>
            )}
          </div>
        </main>
        <ModalContent ref="submission" initialise={this.initialise} />
        <ProspectForm
          handleAddProspectCancel={this.handleAddProspectCancel}
          addProspect={addProspect}
          domainId={domain_id}
          addProspectForm={addProspectForm}
        />

        <AddFormModel
          history={this.props.history}
          ref="add_form_modal"
          domainId={domain_id}
          domainName={domain_name}
        />
        <WebsiteUsers
          ref={'assign_user_modal'}
          domainId={domain_id}
          assignedUsers={users}
          onClose={() => { this.initialise(true)}}
        />
      </>
    );
  }
}

// container

const mapStateToProps = state => {
  const {
    realtime_update = null,
  } = state.MainReducer;
  return {
    realtime_update: realtime_update,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardWebsite);