import React, { Component } from 'react';
import {Button, Form, Input, Select} from "antd";
import MobileInput from '@components/common/MobileNumberLayout';

class AccHolderInfo extends Component{

  state = {
    billing_details: {}
  }

  componentDidMount() {
    if(this.props.billing_details.contact_number){
      this.refs.mobileInput.updateInput(this.props.billing_details.contact_number)
    }
    this.setState({
      billing_details: this.props.billing_details
    });
  }

  handleBillingDetailsSubmit = e => {
    e.preventDefault();
    let billing_details = this.state.billing_details;
    const phoneNumberData = this.refs.mobileInput.getPhoneNumber();
    if (phoneNumberData) {
      billing_details.contact_number = phoneNumberData;
      billing_details['country'] = billing_details['country'].split('|')[1];
    this.props.updateBillingInfo({
      subscription_id: this.props.subscription_id,
      details: billing_details,
    });
    }
  };

  render() {

    const { billing_details } = this.state;
    const { country_list } = this.props;

    const country_option_list = country_list &&
      country_list.map((country, i) => {
        return (
          <Select.Option key={country.code + '|' + country.name}>
            {country.name}
          </Select.Option>
        );
      });

    return (
      <Form layout="vertical" onSubmit={this.handleBillingDetailsSubmit}>
        <Form.Item>
          <Input
            placeholder="Enter First Name"
            value={
              billing_details &&
              billing_details.first_name
            }
            onChange={e => {
              billing_details['first_name'] = e.target.value;
              this.setState({
                billing_details: billing_details,
              });
            }}
          />
          <div className="floating-label">First Name</div>
        </Form.Item>
        <Form.Item>
          <Input
            placeholder="Enter Last Name"
            value={
              billing_details &&
              billing_details.last_name
            }
            onChange={e => {
              billing_details['last_name'] = e.target.value;
              this.setState({
                billing_details: billing_details,
              });
            }}
          />
          <div className="floating-label">Last Name</div>
        </Form.Item>
        <Form.Item>
          <Input
            placeholder="Enter Email Address"
            value={
              billing_details &&
              billing_details.email_address
            }
            onChange={e => {
              billing_details['email_address'] = e.target.value;
              this.setState({
                billing_details: billing_details,
              });
            }}
          />
          <div className="floating-label">Email Address</div>
        </Form.Item>
        <MobileInput ref={'mobileInput'}/>
        <Form.Item>
          <Input
            placeholder="Enter Address"
            value={
              billing_details &&
              billing_details.address
            }
            onChange={e => {
              billing_details['address'] = e.target.value;
              this.setState({
                billing_details: billing_details,
              });
            }}
          />
          <div className="floating-label">Address</div>
        </Form.Item>
        <Form.Item label="Country">
          <Select
            value={
              billing_details &&
              billing_details.country
            }
            onChange={value => {
              billing_details['country'] = value;
              this.setState({
                billing_details: billing_details,
              });
            }}
            showSearch
          >
            {country_option_list}
          </Select>
        </Form.Item>
        <Button.Group>
          <Button
            type="primary"
            size="large"
            className="has-width"
            htmlType="submit"
          >
            {' '}
            Save
          </Button>
        </Button.Group>
      </Form>
    )
  }
}

export default AccHolderInfo;