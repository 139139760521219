import React, { Component } from 'react';
import { Skeleton, Affix } from 'antd';

import ActivateForm from './subcomponents/ActivateForm';
import ExtractedForms from './subcomponents/ExtractedForms';

import './AddFormLayout.scss';
import { parseQuery } from '@queryHelper';

class AddFormLayout extends Component {
  state = {
    formStep: -1,
    dataStore: {
      domain_id: null,
    },
  };

  setExtractedForms = dataStore => {
    this.setState({
      formStep: 1,
      dataStore: dataStore,
    });
  };

  componentWillMount() {
    const { domain_id } = this.props.match.params;
    const query = parseQuery(this.props.location.search);

    this.setState({
      formStep: 0,
      dataStore: {
        domain_id: domain_id,
        domain_name: query && atob(query.name),
      },
    });
  }

  goBack = () => {
    this.setState({
      formStep: 0
    })
  };

  nextStep = () => {
    const { domain_id } = this.props.match.params;

    this.props.history.push('/dashboard/' + domain_id);
  };

  render() {
    return (
      <>
        <Affix offsetTop={0}>
          <div className="headerbar">
            <div className="headerbar-block breadcrumb left">
              <Skeleton
                title={{ width: '140px' }}
                loading={this.state.loading}
                active
                paragraph={false}
              >
                <div className="breadcrumb-title">
                  {this.state.dataStore.domain_name}
                </div>
                <div className="breadcrumb-subtitle">Add New Form</div>
              </Skeleton>
            </div>
            <div className="headerbar-block right" />
            <div className="clear-fix" />
          </div>
        </Affix>
        <main className="main-container add-form typo">
          {this.state.formStep === 0 ? (
            <ActivateForm
              dataStore={this.state.dataStore}
              nextStep={this.setExtractedForms}
            />
          ) : null}
          {this.state.formStep === 1 ? (
            <ExtractedForms
              dataStore={this.state.dataStore}
              goBack={this.goBack}
              nextStep={this.nextStep}
            />
          ) : null}
        </main>
      </>
    );
  }
}

export default AddFormLayout;
