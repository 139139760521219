import React, { Component } from 'react';
import { Button, Card, Collapse } from 'antd';

import './FAQLayout.scss';

const Panel = Collapse.Panel;

class FAQLayout extends Component {
  componentWillMount() {
    document.title = 'FAQs | Buzzz.';
  }

  render() {
    const faq = [
      {
        title: 'Why can I not download the app from my app store?',
        description:
          'We have built Buzzz to be as up to date with phone OS as possible. As a result, we are only supporting iOS 11+ and Android Mashmallow+. Please update your phone OS to use the Buzzz app correctly.',
      },
      {
        title: 'Is it possible to assign a user to multiple forms?',
        description:
          'Yes, absolutely! And it’s really easy too. We will have a full video tutorial on this very soon.',
      },
      {
        title: 'Can I add a new prospect to my form manually?',
        description:
          'Easy peasy! If your contact form is misbehaving and you still want to add new prospect’s contact information to your Buzzz form, please follow the super simple instructions in our tutorial videos when we launch them very soon.',
      },
      {
        title: 'I’ve got tonnes of customers contact information in my Buzzz form, is it possible to export it?',
        description:
          'Yes. When on the dashboard, all you have to do is click the ‘Export CSV/XLS’ button on your form and the file will automatically download to your computer.',
      },
      {
        title: 'Can I recover a lead’s information once deleted?',
        description:
          'Nope! Once a lead is deleted it will be removed from your account. We have however programmed a 5 second ‘oopsie’ feature to reverse any accidental deletions at the time.',
      },
      {
        title: 'I do not have a contact form on my website. Can I still use Buzzz?',
        description:
          'Not currently, but we are working on an awesome DIY form builder on our dashboard with super simple integration steps for a whole range of content management systems like Squarespace, Wix and Wordpress. We will be releasing this feature in our next major build release.',
      },
      {
        title: 'Can I pop by your office for a coffee?',
        description:
          'Always! And just to make it public knowledge, we love doughnuts. Just saying.'
      },
    ];

    const panels = faq.map((item, index) => {
      return (
        <Panel
          header={
            <>
              <span className="panel-title">{item.title}</span>
              <span className="float-right">
                <Button shape="circle" type="default" icon={'down'} />
              </span>
            </>
          }
          showArrow={false}
          key={index}
        >
          {item.description}
        </Panel>
      );
    });

    return (
      <main className="main-container">
        <Card className="faq-box" bordered={false}>
          <Collapse bordered={false}>{panels}</Collapse>
        </Card>
      </main>
    );
  }
}

export default FAQLayout;
