import React, {Component} from 'react';
import {Button, Form, Input, Select, Row, Col, notification, Tooltip, Checkbox, Card, Icon, Modal} from 'antd';
import commonApiService from '@apiService';


const ToggleBtn = ({ field, setSelected, index }) => {
  return (
    <div className="text-input">
      <div className={'label-badge'}>
        {
          field.isActive ? (
            <Tooltip title={'remove this form field, it will not show in your buzzz form submissions'}>
              <Checkbox
                checked
                disabled={
                  field.detectedType === 'name' ||
                  field.detectedType === 'email' ||
                  field.detectedType === 'tel'
                }
                className={'left-badge'}
                onChange={(ev) => {setSelected(index); }}
              />
            </Tooltip>
          ) : (
            <Checkbox
              disabled={
                field.detectedType === 'name' ||
                field.detectedType === 'email' ||
                field.detectedType === 'tel'
              }
              className={'left-badge'}
              onChange={(ev) => { setSelected(index); }}
            />
          )
        }
      </div>
      <Input placeholder={' '} value={field.label} readOnly/>
    </div>
  )
}

class SingleForm extends Component {
  state = {
    loading: false,
    activate: false,
    complete: false,
    edit: false,
    fields: [],
    flags: {
      name: true,
      tel: true,
    },
    deleteForm: {
      visible: false,
      error: false
    }
  };

  componentWillMount() {
    const {websiteForm} = this.props;

    let fields = websiteForm.filtered_content || websiteForm.form_fields;

    fields = fields.map(item => {
      const data = {
        id: item.id,
        key: item.key,
        label: item.label,
        type: item.type || null,
        content: item.content || null,
        detectedType: item.detectedType,
        isActive: item.isActive,
      };
      return data;
    });

    this.setState({
      fields: fields,
    });
  }

  setSelected = index => {
    const fields = this.state.fields;
    fields[index].isActive = !fields[index].isActive;
    this.setState({
      fields: fields,
    });
  };

  setFormFieldData = () => {
    let flags = {
      name: false,
      tel: false,
    };

    let fields = this.state.fields;
    let finalFields = fields.map((item, index) => {
      if (item.detectedType === 'tel') {
        flags.tel = true;
      }
      if (item.detectedType === 'name') {
        flags.name = true;
      }

      const data = {
        id: item.id,
        key: item.key,
        label: item.label,
        type: item.type,
        detectedType: item.detectedType,
        content: item.content || null,
        isActive: item.isActive
      };

      return data;
    });

    this.setState({
      flags: flags,
    });

    return {
      name: this.refs.formName.state.value,
      url: this.props.formUrl,
      form_fields: finalFields,
      warn: !(flags.name && flags.tel),
    };
  };

  setType = (ev, index) => {
    const fields = this.state.fields;

    fields[index].detectedType = ev;

    this.setState({
      fields: fields,
    });
  };

  updateForm = () => {
    const {id, url} = this.props.websiteForm;

    const name = this.refs.formName.state.value;

    const params = {...this.setFormFieldData(), id, name, url};

    if (!params.warn) {
      this.setState({
        loading: true,
      })
      commonApiService
        .put('/web/domains/forms', params)
        .then(successResult => {
          this.setState({
            loading: false,
            edit: false
          });
          notification.open({
            message: 'Success!',
            description: "Form data updated!",
            className: 'notification-success'
          });
        })
        .catch(errorResult => {
          notification.open({
            message: 'Error!',
            description: "Something went wrong!",
            className: 'notification-error'
          });
        })
    }
  };

  confirmDelete = () => {
    const formName = this.refs.deleteConfirmInput && this.refs.deleteConfirmInput.state.value;
    console.log(formName);
    const deleteFormState = this.state.deleteForm;

    if(formName === this.props.websiteForm.name){
      deleteFormState.error = false;
      this.setState({
        loading: true,
        deleteForm: deleteFormState
      });
      commonApiService
        .put('/web/domains/form_discard', { id: this.props.websiteForm.id})
        .then(successResult => {
          notification.open({
            message: 'Form Deleted!',
            description: "Form Deleted",
            className: 'notification-neutral'
          });
          this.setState({
            loading: false
          });
          this.props.refresh();
        })
        .catch(errorResult => {
          notification.open({
            message: 'Error!',
            description: "Something went wrong!",
            className: 'notification-error'
          });
        })
    }else{
      deleteFormState.error = true;
      this.setState({
        deleteForm: deleteFormState
      })
    }
  }

  render() {
    const {flags, edit } = this.state;
    const {websiteForm, formKey} = this.props;

    const formFields = this.state.fields.map((field, index) => {
      return (
        <div className={field.isActive ? 'input-wrap' : edit ? 'input-wrap inactive' : 'input-wrap inactive view'} key={index}>
          <div>
            {
              edit ? (
                <Row>
                  <Col xs={14}>
                    <ToggleBtn field={field} setSelected={this.setSelected} index={index} />
                  </Col>
                  <Col xs={10}>
                    <div className="select-input">
                      <Form.Item>
                        <Select
                          placeholder={'Select Type'}
                          dropdownClassName={'form-field-dropdown'}
                          defaultValue={field.detectedType}
                          onChange={ev => {
                            this.setType(ev, index);
                          }}
                        >
                          <Select.Option value={'name'}>Name</Select.Option>
                          <Select.Option value={'email'}>Email Address</Select.Option>
                          <Select.Option value={'tel'}>Telephone Number</Select.Option>
                          <Select.Option value={null}>Other</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              ) : (
                <div className={'label-badge viewform text'}>
                  <div className={'left-badge'}>
                    <span className={'number'}>{index + 1}</span>
                  </div>
                  <div className={'viewform-field'}>{field.label} <span className={'capsule'}>{field.detectedType || 'Other'}</span></div>
                </div>
              )
            }
          </div>
        </div>
      );
    });
    return (
      <div>

        <div>
          <div hidden={!edit}>
            <Input
              ref={'formName'}
              placeholder={' '}
              defaultValue={websiteForm.name || `Form ${formKey + 1}`}
            />
            <div className="floating-label force-float">Form Name</div>
          </div>
          <div hidden={edit}><p>All input fields in the form</p></div>
        </div>

        {formFields}

        {flags.name ? null : (
          <p className={'danger-color'}>Please make sure to map 1 form field to 'name'</p>
        )}
        {flags.tel ? null : (
          <p className={'danger-color'}>please make sure to map 1 form field to 'Telephone Number'</p>
        )}

        <Row>
          {
            edit ? (
              <Col xs={18}>
                <Button
                  size={'large'}
                  type={'primary'}
                  className={'save-change'}
                  onClick={this.updateForm}
                  loading={this.state.loading}
                >
                  Save
                </Button>
                <Button
                  size={'large'}
                  type={'default'}
                  className={'save-change'}
                  onClick={() => { this.setState({edit: false})}}
                >
                  Cancel
                </Button>
              </Col>
            ) :(
              <Col xs={18}>
                <Button
                  size={'large'}
                  type={'default'}
                  className={'save-change'}
                  onClick={() => { this.setState({edit: true})}}
                >
                  Edit form
                </Button>
              </Col>
            )
          }
          <Col xs={6}>
            <p className={'delete-form'} onClick={() => { this.setState({deleteForm: { visible: true, error: false}})}}>Delete form</p>
          </Col>
        </Row>

        <Modal
          className="modal-confirm"
          onCancel={() => {
            this.setState({ deleteForm: { visible: false, error: false} });
          }}
          visible={this.state.deleteForm.visible}
          footer={false}
          centered={true}
        >
          <h2>Are you absolutely sure?</h2>

          <p>Once you delete a form, there is no going back.</p>
          <p>This will permanently delete forms, assigned users and prospect data</p>
          <p>Please type in the name of the form to confirm.</p>
          <Input ref={'deleteConfirmInput'} placeholder={'YOUR FORM NAME'} addonAfter={this.state.deleteForm.error && <Icon type="exclamation-circle" />}/>
          <Button.Group>
            <Button
              size="large"
              type="danger"
              onClick={this.confirmDelete}
              loading={this.state.loading}
            >
              Delete this Form
            </Button>
          </Button.Group>
        </Modal>
      </div>
    );
  }
}

export default SingleForm;
