import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as MainLayoutActions from '../actions/MainLayoutAction.js';
import * as DomainLayoutActions from '../actions/DomainLayoutAction.js';

import MainLayoutComponent from '@layouts/Main/MainLayout.js';

// ================================================================================
// DASHBOARD OVERVIEW MAP - Container
// ================================================================================

const mapStateToProps = state => {
  let fullAccess = false;
  let owner = false;
  if (localStorage && localStorage.user) {
    let user = JSON.parse(localStorage.user);
    owner = localStorage.owner === 'true';
    fullAccess = user.access_level === 'FullAccess';
  }
  const { MainReducer, DomainReducer, UserReducer } = state;
  const {
    network_down = false,
    user_data = null,
    logout_success = null,
    user_notification_data_success = null,
    user_notification_data_failure = null,
  } = MainReducer;
  const {
    header_content = null,
    verified_domain_data_success = null,
    verified_domain_data_error = null,
  } = DomainReducer;
  // return {
  //   network_down, state.MainReducer.network_down || false,
  //   user_data, state.MainReducer.user_data || null,
  //   logout_success, state.MainReducer.logout_success || null,
  //   header_content, state.DomainReducer.header_content || null,
  //   user_notification_data_success,
  //     state.MainReducer.user_notification_data_success || null,
  //   user_notification_data_failure,
  //     state.MainReducer.user_notification_data_failure || null,
  //   verified_domain_data_success,
  //     state.DomainReducer.verified_domain_data_success || null,
  //   verified_domain_data_error,
  //     state.DomainReducer.verified_domain_data_error || null,
  //   user_access_limit, state.UserReducer.user_access_limit || 0,
  //   full_access: fullAccess,
  //   owner: owner,
  // };
  return {
    network_down,
    user_data,
    logout_success,
    user_notification_data_success,
    user_notification_data_failure,

    header_content,
    verified_domain_data_success,
    verified_domain_data_error,

    user_access_limit: UserReducer.user_access_limit || 0,
    full_access: fullAccess,
    owner: owner,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(MainLayoutActions, dispatch),
    domain_actions: bindActionCreators(DomainLayoutActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainLayoutComponent);

// ================================================================================
