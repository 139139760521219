import React, { Component } from 'react';
import Script from 'react-load-script';
import { Form, Input, Button, Select } from 'antd';

import './UserCardDetailForm.scss';

const FormItem = Form.Item;
const Option = Select.Option;

function luhn_check(val) {
  let value = val.split("-").join("");
  return value.split('')
    .reverse()
    .map( (x) => parseInt(x, 10) )
    .map( (x,idx) => idx % 2 ? x * 2 : x )
    .map( (x) => x > 9 ? (x % 10) + 1 : x )
    .reduce( (accum, x) => accum += x ) % 10 === 0;
}


class UserCardDetailForm extends Component {
  state = {
    scriptLoaded: false,
    country: '',
    region: '',
    country_option_list: null,
    state_option_list: null,
    step: 1,
  };

  componentWillMount() {
    if (this.props.country_list_success) {
      this.setState({
        country_option_list: this.props.country_list_success,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      if (
        nextProps.country_list_success &&
        this.props.country_list_success !== nextProps.country_list_success
      ) {
        this.setState({
          country_option_list: nextProps.country_list_success,
        });
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        //Format the data received and fire a request to register the user...
        if (this.state.step === 1) {
          this.setState({
            step: 2,
          });
        } else {
          values.card_number = values.card_number.split("-").join("");
          values = Object.assign(values, {
            expiry: values.expiry_month + '/' + values.expiry_year,
            country: values.country.split('|')[1]
          });
          delete values['expiry_month'];
          delete values['expiry_year'];
          this.props.linkUserCardInformation(values);
        }
      }
    });
  };

  handleScriptLoad = () => {
    this.setState({
      scriptLoaded: true,
    });
    window.TCO.loadPubKey('sandbox', function() {
      //@todo: Catch Errors associated with PublishedKey Fetching if any here
    });
  };

  goBack = () => {
    this.setState({ step: 1 });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const expMonths = [];
    for (let expMonth = 1; expMonth <= 12; expMonth++) {
      expMonths.push(<Option value={expMonth}>{expMonth}</Option>);
    }

    const expYears = [];

    for (let expYear = 2018; expYear <= 2080; expYear++) {
      expYears.push(<Option value={expYear}>{expYear}</Option>);
    }

    const country_option_list =
      this.state.country_option_list &&
      this.state.country_option_list.map((country, i) => {
        return <Option key={country.code + '|' + country.name} >{country.name}</Option>;
      });
    return (
      <div className="card-form">
        <Script
          url="https://www.2checkout.com/checkout/api/2co.min.js"
          onLoad={this.handleScriptLoad.bind(this)}
        />
        <h2>Add Payment Method</h2>
        <p className="highlight">
          {this.state.step === 1 ? 'Billing Information' : 'Payment Details'}
        </p>

        <Form onSubmit={this.handleSubmit} layout="horizontal">
          <div className="card-form-row" hidden={this.state.step === 2}>
            <div className="col-full">
              <FormItem>
                {getFieldDecorator('fullName', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter Full Name',
                      whitespace: true,
                    },
                  ],
                })(<Input placeholder=" " />)}
                <div className="floating-label">Full Name</div>
              </FormItem>
            </div>
            <div className="col-half ">
              <FormItem>
                {getFieldDecorator('city', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your City',
                      whitespace: true,
                    },
                  ],
                })(<Input placeholder=" " />)}
                <div className="floating-label">City</div>
              </FormItem>
            </div>
            <div className="col-half">
              <FormItem>
                {getFieldDecorator('state', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your  State',
                      whitespace: true,
                    },
                  ],
                })(<Input placeholder=" " />)}
                <div className="floating-label">State</div>
              </FormItem>
            </div>
            <div className="col-full">
              <FormItem>
                {getFieldDecorator('addr_1', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your Address',
                      whitespace: true,
                    },
                  ],
                })(<Input placeholder=" " />)}
                <div className="floating-label">Address 1</div>
              </FormItem>
            </div>
            <div className="col-half">
              <FormItem>
                {getFieldDecorator('country', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select your Country',
                      whitespace: true,
                    },
                  ],
                })(
                  <Select showSearch>
                    {country_option_list}
                  </Select>,
                )}
                <div className="floating-label">Country</div>
              </FormItem>
            </div>

            <div className="col-half">
              <FormItem>
                {getFieldDecorator('postal_code', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your Postal/ Zip Code',
                      whitespace: true,
                    },
                  ],
                })(<Input placeholder=" " />)}
                <div className="floating-label">Postal/ Zip Code</div>
              </FormItem>
            </div>
          </div>
          {this.state.step === 2 ? (
            <div className="card-form-row" hidden={this.state.step === 1}>
              <div className="col-full">
                <FormItem>
                  {getFieldDecorator('card_number', {
                    normalize: (value) => {
                      if(value){
                        let val = value.split("-").join("");
                        val = val.substr(0, 16);
                        if (val.length > 0) {
                          val = val.match(new RegExp('.{1,4}', 'g')).join("-");
                        }
                        return val;
                      }
                    },
                    rules: [
                      {
                        required: true,
                        message: 'Please input your card number!',
                        whitespace: true,
                      },
                      {
                        validator: (rule, value, cb) => {
                          if(value && luhn_check(value)){
                            cb();
                            return true
                          }else{
                            cb(true);
                            return false;
                          }
                        },
                        message: 'Please input valid Card Number!',
                      }
                    ],
                  })(<Input placeholder=" " />)}
                  <div className="floating-label">
                    Insert your valid card number
                  </div>
                </FormItem>
              </div>
              <div className="col-full">
                <FormItem>
                  {getFieldDecorator('card_name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your cardholder name!',
                        whitespace: true,
                      },
                    ],
                  })(<Input placeholder=" " />)}
                  <div className="floating-label">Name on Card</div>
                </FormItem>
              </div>
              <div className="col-full inline-select">
                <span className="card-month">
                  <FormItem>
                    {getFieldDecorator('expiry_month', {
                      rules: [{ required: true, message: 'Expiry month' }],
                    })(
                      <Select placeholder=" " showSearch>
                        {expMonths}
                      </Select>,
                    )}
                    <div className="floating-label">MM</div>
                  </FormItem>
                </span>
                <span className="date-divider">/</span>
                <span className="card-year">
                  <FormItem>
                    {getFieldDecorator('expiry_year', {
                      rules: [{ required: true, message: 'Expiry year' }],
                    })(
                      <Select placeholder=" " showSearch>
                        {expYears}
                      </Select>,
                    )}
                    <div className="floating-label">YYYY</div>
                  </FormItem>
                </span>
              </div>
            </div>
          ) : null}

          <Button.Group>
            {this.state.step === 1 ? (
              <Button
                htmlType={'submit'}
                type="default"
                className="has-width"
                size="large"
              >
                Next
              </Button>
            ) : (
              <>
                <button
                  type="button"
                  className="ant-btn ant-btn-default ant-btn-lg has-width"
                  onClick={this.goBack}
                >
                  Go Back
                </button>
                <Button
                  size="large"
                  className="has-width"
                  type="primary"
                  htmlType="submit"
                  loading={this.props.button_loading}
                >
                  Save
                </Button>
              </>
            )}
          </Button.Group>
        </Form>
      </div>
    );
  }
}

const WrappedUserCardDetailForm = Form.create()(UserCardDetailForm);
export default WrappedUserCardDetailForm;
