import React, { Component } from 'react';
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Card,
  Tooltip,
  Radio,
  Icon,
} from 'antd';

import './admin.scss';
import commonApiService from '@apiService';

const FormItem = Form.Item;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class PlanForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  componentWillMount() {
    this.setState(
      {
        action_add: this.props.action_add,
      },
      function() {
        !this.state.action_add &&
          this.props.form.setFieldsValue({
            name: this.props.subscription.name,
            description: this.props.subscription.description,
            price: this.props.subscription.price,
            form_count: this.props.subscription.detail.forms,
            domain_count: this.props.subscription.detail.domain_count,
            user_count: this.props.subscription.detail.user_count,
            allowed_ping: this.props.subscription.detail.allowed_ping.toString(),
          });
      },
    );
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });
        values = {
          name: values.name,
          description: values.description,
          detail: {
            forms: values.form_count,
            domain_count: values.domain_count,
            allowed_ping: values.allowed_ping === 'true',
            user_count: values.user_count,
          },
          price: values.price,
        };
        if (!this.props.action_add) {
          values['id'] = this.props.subscription.id;
          commonApiService
            .put('/web/admin/plans', values)
            .then(
              response => {
                notification.open({
                  message: 'Success',
                  description: response.data.msg,
                  className: 'notification-success',
                });
              },
              error => {
                notification.open({
                  message: 'Error',
                  description: error.errors,
                  className: 'notification-error',
                });
              },
            )
            .then(() => {
              this.setState({
                loading: false,
              });
              this.props.form.resetFields();
              this.props.handlePlanFormSubmit();
            });
        } else {
          commonApiService
            .post('/web/admin/plans', values)
            .then(
              response => {
                notification.open({
                  message: 'Success',
                  description: response.data.msg,
                  className: 'notification-success',
                });
              },
              error => {
                notification.open({
                  message: 'Error',
                  description: error.errors,
                  className: 'notification-error',
                });
              },
            )
            .then(() => {
              this.setState({
                loading: false,
              });
              this.props.form.resetFields();
              this.props.handlePlanFormSubmit();
            });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;

    return (
      <Form layout="vertical" onSubmit={this.handleSubmit}>
        <FormItem>
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input plan name!' }],
          })(<Input placeholder="Enter Plan Name" />)}
          <div className="floating-label">Plan Name</div>
        </FormItem>
        <FormItem>
          {getFieldDecorator('user_count', {
            initialValue: 0,
            rules: [{ required: true, message: 'Please input user count!' }],
          })(<Input placeholder="Enter User count" />)}
          <div className="floating-label">User Count</div>
        </FormItem>
        <FormItem>
          {getFieldDecorator('form_count', {
            initialValue: 0,
            rules: [{ required: true, message: 'Please input form count!' }],
          })(<Input placeholder="Enter Form count" />)}
          <div className="floating-label">Form Count</div>
        </FormItem>
        <FormItem>
          {getFieldDecorator('domain_count', {
            initialValue: 0,
            rules: [{ required: true, message: 'Please input domain count!' }],
          })(<Input placeholder="Enter Domain count" />)}
          <div className="floating-label">Domain Count</div>
        </FormItem>
        <FormItem label="Ping">
          {getFieldDecorator('allowed_ping', {
            initialValue: 'true',
            rules: [{ required: true, message: 'Please select option!' }],
          })(
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="true">Yes</Radio.Button>
              <Radio.Button value="false">No</Radio.Button>
            </Radio.Group>,
          )}
        </FormItem>

        <FormItem>
          {getFieldDecorator('description', {
            rules: [{ required: true, message: 'Please input description!' }],
          })(<Input placeholder="Provide Description" />)}
          <div className="floating-label">Description</div>
        </FormItem>
        <FormItem>
          {getFieldDecorator('price', {
            rules: [{ required: true, message: 'Please input price!' }],
          })(<Input placeholder="Enter Price" />)}
          <div className="floating-label">Price</div>
        </FormItem>
        <FormItem>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError())}
          >
            {this.state.action_add ? 'Add Plan' : 'Update Plan'}
          </Button>
        </FormItem>
      </Form>
    );
  }
}
const PlanFormWrapper = Form.create()(PlanForm);

class SubscriptionPlanLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_modal: false,
      action_add: false,
      subscriptions: [],
      current_subscription: {},
    };
  }

  componentWillMount() {
    this.getSubscriptionList();
  }

  getSubscriptionList = () => {
    this.setState({
      loading: true,
    });
    commonApiService.list('/web/admin/plans').then(
      response => {
        this.setState({
          loading: false,
          show_modal: false,
          subscriptions: response.data.plans,
          current_subscription: {},
        });
      },
      error => {
        notification.open({
          message: 'Error',
          description: error.errors,
          className: 'notification-error',
        });
      },
    );
  };

  setPublishSubscriptoin = subscription => {
    this.setState({
      loading: true,
    });

    subscription.published = !subscription.published;
    commonApiService
      .put('/web/admin/plans', subscription)
      .then(
        response => {
          notification.open({
            message: 'Success',
            description: response.data.msg,
            className: 'notification-success',
          });
        },
        error => {
          notification.open({
            message: 'Error',
            description: error.errors,
            className: 'notification-error',
          });
        },
      )
      .then(() => {
        this.getSubscriptionList();
      });
  };

  removeSubscription = subscription => {
    this.setState({
      loading: true,
    });
    commonApiService
      .delete('/web/admin/plans', {
        id: subscription.id,
      })
      .then(
        response => {
          notification.open({
            message: 'Success',
            description: response.data.msg,
            className: 'notification-success',
          });
        },
        error => {
          notification.open({
            message: 'Error',
            description: error.errors,
            className: 'notification-error',
          });
        },
      )
      .then(() => {
        this.getSubscriptionList();
      });
  };

  handleModalCancel = () => {
    this.setState({
      show_modal: false,
    });
  };

  render() {
    let subscription_list =
      this.state.subscriptions &&
      this.state.subscriptions.map((subscription, i) => {
        return (
          <div
            key={i}
            className={
              subscription.published ? 'plan-card active' : 'plan-card'
            }
          >
            <Tooltip mouseLeaveDelay={0} title={subscription.name}>
              <div className="title">{subscription.name}</div>
            </Tooltip>
            <div className="desc">{subscription.description}</div>
            <div className="price">$ {subscription.price}</div>
            <div className="desc no-margin">
              {subscription.detail.allowed_ping ? 'Ping My Domain' : ''}
            </div>
            <div className="desc no-margin">
              {subscription.detail.domain_count} Domains
            </div>
            <div className="desc no-margin">
              {subscription.detail.forms} forms
            </div>
            <div className="desc no-margin">
              {subscription.detail.user_count} users
            </div>
            <div className="btn-container">
              <Button
                type="primary"
                size="small"
                onClick={e => {
                  this.setState({
                    show_modal: true,
                    action_add: false,
                    current_subscription: subscription,
                  });
                }}
              >
                Edit Plan
              </Button>
              <Popconfirm
                title="Are your sure?"
                onConfirm={e => {
                  this.removeSubscription(subscription);
                }}
                okText="Yes"
                okType="danger"
                cancelText="Cancel"
              >
                <Button type="danger" size="small">
                  Remove Plan
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Are your sure?"
                onConfirm={e => {
                  this.setPublishSubscriptoin(subscription);
                }}
                okText="Yes"
                okType={subscription.published ? 'danger' : 'primary'}
                cancelText="Cancel"
              >
                <Button type={subscription.published ? 'default' : 'primary'}>
                  {!subscription.published ? 'Publish Plan' : 'Unpublish'}
                </Button>
              </Popconfirm>
            </div>
          </div>
        );
      });

    return (
      <main className="main-container">
        <Card
          title="Account Plan"
          bordered={false}
          className="admin-subscription"
        >
          <div className="account-plan-info">
            {subscription_list}
            <div key={subscription_list.length} className="plan-card btn-add">
              <Button
                onClick={e => {
                  this.setState({
                    show_modal: true,
                    current_subscription: {},
                    action_add: true,
                  });
                }}
              >
                <Icon type="plus" />
              </Button>
            </div>
          </div>
        </Card>

        <Modal
          visible={this.state.show_modal}
          destroyOnClose={true}
          onCancel={this.handleModalCancel}
          title={this.state.action_add ? 'Add Plan ' : 'Edit Plan'}
          footer={null}
          className="subscription-plan-modal"
        >
          <PlanFormWrapper
            handlePlanFormSubmit={this.getSubscriptionList}
            subscription={this.state.current_subscription}
            action_add={this.state.action_add}
          />
        </Modal>
      </main>
    );
  }
}

export default SubscriptionPlanLayout;
