import React, { Component } from 'react';
import { Button, Card, Form, Input, notification, Select, Popover } from 'antd';
import commonApiService from '@apiService';
import HelpActivateForm from '@components/common/Help/HelpActivateForm';
import HelpTip from '@components/common/Help/HelpTip';

class ActivateForm extends Component {
  state = {
    loading: false,
  };
  dataStore = {
    domain_id: null,
    forms: [],
    help: false
  };

  handleActivateForm = ev => {
    ev.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
          help: false
        });
        const params = {
          form_url: values.prefix + values.url,
          domain_id: this.dataStore.domain_id,
        };

        commonApiService
          .post('/web/domains/extract_forms', params)
          .then(successResult => {
            this.setState({
              loading: false,
            });

            this.dataStore.form_url = values.prefix + values.url;
            this.dataStore.forms = successResult.data.forms;
            if (this.dataStore.forms.length >= 1) {
              this.props.nextStep(this.dataStore);
            } else {
              notification.open({
                message: 'No Forms Found',
                description:
                  'Looks like you dont have any forms on link provided!',
                className: 'notification-error',
              });
            }
          })
          .catch(errorResult => {
            notification.open({
              message: 'Error',
              description: errorResult.errors[0],
              className: 'notification-error',
            });
            this.setState({
              loading: false,
            });
          });
      }
    });
  };

  componentWillMount() {
    const { dataStore } = this.props;
    if (dataStore) {
      this.dataStore = dataStore;
    }
    window.scrollTo(0, 0);
  }

  nextStep = () => {
    this.setState({
      help: false
    });
    this.props.nextStep(this.dataStore);
  };

  toggleHelp = () => {
    this.setState({
      help: !this.state.help
    })
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { form_url } = this.props.dataStore;

    let url = {
      protocol: 'http:',
      host: '',
      path: ''
    };
    if(form_url){
      url = new URL(form_url);
    }

    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: (url.protocol + '//'),
      rules: [
        { required: true, message: 'Please input your Domain Url Prefix!' },
      ],
    })(
      <Select style={{ width: 94, backgroundColor: 'white', color: 'black' }}>
        <Select.Option value="http://">http://</Select.Option>
        <Select.Option value="https://">https://</Select.Option>
      </Select>,
    );

    const getPopupContainerProps = this.props.parentSetup ? {} : {
      getPopupContainer: () => document.getElementById('activate-form-card')
    };

    return (
      <Popover
        content={<HelpActivateForm/>}
        overlayClassName={'help-popover'}
        {...getPopupContainerProps}
        placement={'rightTop'}
        visible={this.state.help}
        onVisibleChange={this.handleVisibleChange}
      >

      <Card bordered={false} id={'activate-form-card'} className={this.state.help ? 'move-aside' : ''}>
        <h4>Add a form from the website {<HelpTip help={this.state.help} toggleHelp={this.toggleHelp}/>}</h4>
        <p className={'light'}>
          Now that we know where your website is on the internet, next up is telling us exactly what page on your site your contact form is on.
        </p>

        <Form onSubmit={this.handleActivateForm}>
          <Form.Item>
            {getFieldDecorator('url', {
              initialValue: (url.host || '') + (url.path || ''),
              rules: [
                {
                  required: true,
                  message: 'Please input your Website Url where form exists!',
                },
              ],
              normalize: value => value && value.replace(/(^\w+:|^)\/\//, ''),
            })(
              <Input
                placeholder={'yourwebsite.com/your-form-page'}
                addonBefore={prefixSelector}
              />,
            )}
            <div className="floating-label force-float">
              Enter Page URL which the form is on
            </div>
          </Form.Item>
          <Button.Group>
            <Button
              loading={this.state.loading}
              size={'large'}
              className={'has-width'}
              htmlType="submit"
              type="primary"
            >
              Extract form
            </Button>
          </Button.Group>
        </Form>
      </Card>
      </Popover>
    );
  }
}

export default Form.create()(ActivateForm);
