import React, { Component } from 'react';
import { Input, Button, Card, notification } from 'antd';
import './admin.scss';
import commonApiService from '@apiService';
class ConfigurationLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      configs: [],
    };
  }

  componentDidMount() {
    this.getConfigurations();
  }

  getConfigurations = () => {
    this.setState({
      loading: true,
    });
    commonApiService.list('/web/admin/platform_config').then(
      response => {
        this.setState({
          loading: false,
          configs: response.data.configurations,
        });
      },
      error => {
        notification.open({
          message: 'Error',
          description: error.errors,
          className: 'notification-error',
        });
      },
    );
  };

  updateConfiguration = conf => {
    for (let key in conf.value) {
      conf.value[key] = this.refs[key].input.value;
    }

    this.setState({
      loading: true,
    });
    commonApiService
      .put('/web/admin/platform_config', conf)
      .then(
        response => {
          notification.open({
            message: 'Success',
            description: response.data.msg,
            className: 'notification-success',
          });
        },
        error => {
          notification.open({
            message: 'Error',
            description: error.errors,
            className: 'notification-error',
          });
        },
      )
      .then(() => {
        this.getConfigurations();
      });
  };

  render() {
    let configs_list =
      this.state.configs &&
      this.state.configs.map((config, i) => {
        let fields = [];
        for (let key in config.value) {
          fields.push(
            <div key={key} className="config-row-grp">
              <div className="field-key">{key}:</div>
              <Input
                ref={key}
                disabled={!config.edit}
                defaultValue={config.value[key]}
              />
            </div>,
          );
        }
        let configs = this.state.configs;
        return (
          <tr key={i}>
            <td>{config.key}</td>
            <td>{fields}</td>
            <td>
              <Button
                type="primary"
                size="small"
                hidden={config.edit}
                onClick={e => {
                  configs[i].edit = true;
                  this.setState({
                    configs: configs,
                  });
                }}
              >
                Edit
              </Button>
              <Button
                type="primary"
                size="small"
                hidden={!config.edit}
                onClick={e => {
                  this.updateConfiguration(configs[i]);
                }}
              >
                Save
              </Button>
            </td>
          </tr>
        );
      });

    return (
      <main className="main-container">
        <Card
          title="Platform Configurations"
          bordered={false}
          className="admin-box-container"
        >
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Key</th>
                  <th className="align-right">Value</th>
                </tr>
              </thead>
              <tbody>{configs_list}</tbody>
            </table>
          </div>
        </Card>
      </main>
    );
  }
}

export default ConfigurationLayout;
