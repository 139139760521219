import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  notification,
  Button,
  Skeleton,
  Tooltip,
  Row,
  Col,
  Avatar,
  Affix,
} from 'antd';

import commonApiService from '@apiService';
import './WebsiteListLayout.scss';

class WebsiteListLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domain_list: [
        {
          id: 1,
        },
        {
          id: 2,
        },
      ],
      available_limit: 1,
      loading: true,
    };
    this.is_plan_expired = localStorage.getItem('is_plan_expired') === 'true';

    this.fullAccess = false;
    if (localStorage && localStorage.user) {
      let user = JSON.parse(localStorage.user);
      let owner = localStorage.owner === 'true';
      this.fullAccess = owner ? true : user.access_level === 'FullAccess';
    }
    document.title = 'Admin | Buzzz.';
  }

  componentDidMount() {
    commonApiService
      .list('/web/domains', {})
      .then(successResult => {
        if (
          successResult.data.companies &&
          successResult.data.companies.length === 1
        ) {
          this.props.history.push(
            '/view-website/' + successResult.data.companies[0].id,
          );
        } else {
          this.setState({
            loading: false,
            domain_list: successResult.data.companies,
            available_limit: successResult.data.usage_limit.available_limit,
          });
        }
      })
      .catch(errorResult => {
        this.setState(
          {
            loading: false,
          },
          function() {
            notification.open({
              message: 'Error',
              description: errorResult.errors[0].msg,
              className: 'notification-error',
            });
          },
        );
      });
  }

  render() {
    let domainList = this.state.domain_list.map((item, i) => {
      let formList =
        item.forms && item.forms.length ? (
          item.forms.map((form, k) => {
            return <li key={k}>{form.name}</li>;
          })
        ) : (
          <li>No forms linked yet</li>
        );

      let ping_text = 'Active';

      let link_url = item.verified
        ? {
            pathname: '/view-website/' + item.id,
          }
        : {
            pathname: '/add-website',
            search: `?id=${item.id}&key=${item.key}&url=${item.url}`,
          };

      let verify_text = item.verified ? (
        <>
          Verified <i className="icon-check-circle" />
        </>
      ) : (
        <>
          Not Verified{' '}
          <Tooltip
            mouseLeaveDelay={0}
            title="You have not placed code snippet on website yet"
          >
            <i className="icon-exclamation-circle danger-color" />
          </Tooltip>
        </>
      );

      let title = (
        <>
          <Skeleton paragraph={false} active loading={this.state.loading}>
            {item.title && item.title.length > 20 ? (
              <Tooltip mouseLeaveDelay={0} title={item.title}>
                <div className="domain-title">{item.title}</div>
              </Tooltip>
            ) : (
              <div className="domain-title">{item.title}</div>
            )}
            <div className="domain-status">{verify_text}</div>
          </Skeleton>
        </>
      );

      let usersDOM = [];

      if (item.users && item.users.length > 0) {
        const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
        const userCount = item.users.length < 4 ? item.users.length : 4;
        for (let j = 0; j < userCount; j++) {
          const user = item.users[j];
          if (user.profile_url) {
            usersDOM.push(
              <div className={'user-profile-pic'}>
                <Avatar src={user.profile_url} />
              </div>,
            );
          } else {
            const initials =
              user.first_name[0].toUpperCase() +
              user.last_name[0].toUpperCase();
            const color =
              colorList[
                (user.first_name[0].charCodeAt() +
                  user.last_name[0].charCodeAt()) %
                  4
              ];
            usersDOM.push(
              <div className={'user-profile-pic'}>
                <Avatar style={{ backgroundColor: color, color: '#fff' }}>
                  {initials}
                </Avatar>
              </div>,
            );
          }
        }
        if (item.users.length > 4) {
          usersDOM.push(<p>+{item.users.length - 4}</p>);
        }
      }

      if (!item.ping_allowed) {
        ping_text = 'InActive';
      }

      return (
        <Col xs={24} sm={12} md={12} lg={8} xxl={8} key={i + 1}>
          <div
            className={
              !item.verified && (!this.fullAccess || this.is_plan_expired)
                ? 'website-card no-access'
                : 'website-card full-access'
            }
          >
            <Link
              to={!item.verified && !this.fullAccess ? '/domains' : link_url}
            >
              <Card key={i} title={title} bordered={false}>
                <Skeleton
                  title={false}
                  paragraph={{ rows: 8 }}
                  active
                  loading={this.state.loading}
                >
                  <div className="card-body">
                    <div className="domain-item">
                      <div className="item-label">Domain</div>
                      {item && item.url && item.url.length > 40 ? (
                        <Tooltip mouseLeaveDelay={0} title={item.url}>
                          <div className="item-value">{item.url}</div>
                        </Tooltip>
                      ) : (
                        <div className="item-value">{item.url}</div>
                      )}
                    </div>
                    <div className="domain-item">
                      <div className="item-label">Ping my domain</div>
                      <div className="item-value">{ping_text}</div>
                    </div>

                    <div className="domain-item">
                      <div className="item-label">Form</div>
                      <ul className="item-value forms">{formList}</ul>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="domain-item">
                      <div className="item-label">Users</div>
                      <div className="item-value user-images">{usersDOM}</div>
                    </div>
                  </div>
                </Skeleton>
              </Card>
            </Link>
          </div>
        </Col>
      );
    });

    let domain_add_box = (
      <Col key={0} xs={24} sm={12} md={12} lg={8} xxl={8}>
        <div className="website-card">
          <Card
            key={this.state.domain_list.length}
            bordered={false}
            className="add-domain-box"
          >
            <div className="card-body">
              <img src="/images/empty.svg" alt="add new website" />
              <Link to="/add-website">
                <Button
                  size="large"
                  disabled={
                    this.state.available_limit <= 0 || this.props.network_down
                  }
                  type="primary"
                >
                  Add New Website and Form
                </Button>
              </Link>
              <div hidden={this.state.available_limit > 0}>
                <Tooltip
                  mouseLeaveDelay={0}
                  title="Please upgrade plan to add more domains!"
                >
                  <div className="custom-alert danger-color">
                    Add Domain limit reached!
                  </div>
                </Tooltip>
              </div>
            </div>
          </Card>
        </div>
      </Col>
    );
    if (!this.is_plan_expired) {
      if (domainList.length > 3) {
        domainList.unshift(domain_add_box);
      } else {
        domainList.push(domain_add_box);
      }
    }
    //Adds the default Add Domain and form Card to be displayed along with domains

    return (
      <>
        <Affix offsetTop={localStorage.getItem('appInstalled') === "true" ? 0 : 48}>
          <div className="headerbar headerbar-dashboard">
            <div className="headerbar-block breadcrumb left">
              <div className="breadcrumb-title">My Websites</div>
              <div className="breadcrumb-subtitle">Admin</div>
            </div>
            <div className="headerbar-block right" />
            <div className="clear-fix" />
          </div>
        </Affix>
        <main className="main-container">
          <div className="domain-container">
            <Row gutter={{ xs: 20, sm: 20, md: 30 }}>{domainList}</Row>
          </div>
        </main>
      </>
    );
  }
}

export default WebsiteListLayout;
