import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Affix, Button, Card, notification } from 'antd';
import './SignUp.scss';

import PlanLayout from './PlanLayout';
import CreateAccountLayout from './CreateAccountLayout';
import StartTrialLayout from './StartTrialLayout';
import commonApiService from '@apiService';

import icEmail from './ic_email.svg';

class AuthLayout extends Component {
  state = {
    step: 1,
    selectedPlan: null,
    user_details: null,
    verificationEmailSent: false,
    errors: null,
  };

  goToCreateAccount = selectedPlan => {
    this.setState({
      step: 2,
      selectedPlan: selectedPlan,
    });
  };

  goToStartTrialLayout = user_details => {
    this.setState({
      step: 3,
      user_details: user_details,
    });
  };

  changePlan = () => {
    this.setState({
      step: 1,
    });
  };

  goBack = () => {
    this.setState({
      step: this.state.step - 1,
    });
  };

  signUpInitiate = (formData, cb) => {
    var user_details = this.state.user_details;
    var plan_details = this.state.selectedPlan;
    const user_registration_data = {
      email: user_details.email,
      first_name: user_details.first_name,
      last_name: user_details.last_name,
      password: user_details.password,
      confirm_password: user_details.confirm_password,
      user_type: 'Admin', //Temporarily settings users as admin users
      contact: user_details.phone_number,
      companyName: user_details.companyName,
      companySize: user_details.companySize,
      sendEmailUpdates: user_details.sendEmailUpdates,
    };

    commonApiService
      .post('web/create_subscription', {
        plan_id: plan_details.id,
        user_details: user_registration_data,
        card_details: formData.card_details,
        couponCode: formData.couponCode,
        isTrial: this.state.selectedPlan.pIsTrialAvailable,
      })
      .then(
        successResult => {
          if (cb) cb();
          this.setState({
            step: 4,
          });
        },
        errorResult => {
          if (cb) cb();
          notification.open({
            message: 'Error',
            description: errorResult.errors[0],
            className: 'notification-error',
          });
        },
      );
  };

  resendEmail = () => {
    console.log(this.state.user_details && this.state.user_details.email);
    commonApiService
      .post('/reverify_user', {
        emailId: this.state.user_details && this.state.user_details.email
      }).then(
      successResult => {
        notification.open({
          message: 'Email Sent',
          description: 'Email has been resent',
          className: 'notification-neutral',
        });
      },
      errorResult => {
        notification.open({
          message: 'Error',
          description: errorResult.errors[0],
          className: 'notification-error',
        });
      },
    );
  }

  componentDidMount() {
    document.title = 'SignUp | Buzzz.';
    window.scrollTo(0, 0);
  }

  render() {
    const { step, selectedPlan, user_details } = this.state;
    return (
      <div className={'signup auth'}>
        <Affix offsetTop={0}>
          <div className="header">
            <div className={'container'}>
              <div className="logo-container">
                <img src={'/images/buzzz_black.svg'} alt="" />
                <div className={'steps mobile-hide'} hidden={step === 4}>
                  <span
                    className={step === 1 ? 'active' : step > 1 ? 'done' : ''}
                  >
                    1. Choose Plan
                  </span>
                  <span
                    className={step === 2 ? 'active' : step > 2 ? 'done' : ''}
                  >
                    2. Create Account
                  </span>
                  <span
                    className={step === 3 ? 'active' : step > 3 ? 'done' : ''}
                  >
                    3. Start Trial
                  </span>
                </div>
              </div>
              <div className="extra">
                <span className={'mobile-hide'}>Already have an account? </span>{' '}
                <Link to={'/signin'}>Sign in</Link>
              </div>
            </div>
          </div>
        </Affix>
        <div className="main" hidden={this.state.verificationEmailSent}>
          {
            {
              1: (
                <PlanLayout nextStep={this.goToCreateAccount} />
              ),
              2: (
                <CreateAccountLayout
                  user_details={this.state.user_details}
                  plan={selectedPlan}
                  nextStep={this.goToStartTrialLayout}
                  changePlan={this.changePlan}
                  goBack={this.goBack}
                />
              ),
              3: (
                <StartTrialLayout
                  plan={selectedPlan}
                  userData={user_details}
                  changePlan={this.changePlan}
                  goBack={this.goBack}
                  submitForm={this.signUpInitiate}
                  errors={this.state.errors}
                />
              ),
              4: (
                <div className={'post-signup'}>
                  <Card bordered={false}>
                    <img src={icEmail} alt="email"/>
                    <h3>
                      Verification email sent to
                    </h3>
                    <p className={'highlight'}>{this.state.user_details && this.state.user_details.email}</p>
                    <p className={'large'}>
                      Check your inbox as we have just sent you an account verification email. Please click on the link to verify your account and sign in. You can then start linking your website and forms to Buzzz.
                    </p>
                    <p>
                      Thanks for joining Buzzz.
                    </p>
                  </Card>
                  <p className={'bottomtext'}>Don’t see the email? Check your spam folder or click <a onClick={this.resendEmail}>here</a> to resend the verification email.</p>
                </div>
              )
            }[step]
          }
        </div>
      </div>
    );
  }
}

export default AuthLayout;
