import axios from 'axios'
import fileDownload from 'js-file-download';


class CommonApiService{

    //Make sure we generate a singleton
    constructor(){
        if(!CommonApiService.instance)
            CommonApiService.instance = this;
        return CommonApiService.instance;
    }

    handleError (error, reject) {

        if(error && Object.keys(error).length < 1){
            console.log("The error detected is : ", error)
            reject({
                errors : "We are under maintenance.Please visit us later"
            })
        }
        else {
            console.log("The error object being fetched is : ", error);
            reject(error !== undefined && error.errors!==undefined ? error : {
                errors : "We are facing difficulty processing your request"
            });
        }

    }
    
        
    list(url, params){

        var promise = new Promise((resolve, reject) => {

            axios({
                method : 'get',
                url : url,
                params : params
            })
                .then((response) => {

                    if(response && response.data && !response.data.success){
                        this.handleError(response.data, reject)
                    }
                    else {
                        resolve(response.data);
                    }

                })
                .catch((error) => {
                    this.handleError(error, reject)
                });
        });
        return promise;
    }

    add(url, payload){

        var promise = new Promise((resolve, reject) => {
            axios({
                method : 'post',
                url : url,
                data : payload
            })
            .then((response) =>{
                if(response && response.data && !response.data.success){
                    this.handleError(response.data, reject)
                }
                else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
               this.handleError(error, reject)
            });
        });
        return promise;
    }

    addFormData(url, payload){

       /*Formats the payload involved as formdata and sends out the request to the server*/
       var form_data = new FormData();
       for(var key in payload){
         form_data.append(key, payload[key]);
       }

       var promise = new Promise((resolve, reject) => {
           axios({
               method : 'post',
               url : url,
               data : form_data,
               headers: { 'Content-Type': 'application/json' }
           })
           .then((response) =>{
               if(response && response.data && !response.data.success){
                   this.handleError(response.data, reject)
               }
               else {
                   resolve(response.data);
               }
           })
           .catch((error) => {
              this.handleError(error, reject)
           });
       });
       return promise;

    }

    editFormData(url, query, payload){

        /*Formats the payload involved as formdata and sends out the request to the server*/
        var form_data = new FormData();
        for(var key in payload){
            form_data.append(key, payload[key]);
        }

        var promise = new Promise((resolve, reject) => {
            axios({
                method : 'put',
                url : url,
                data : form_data,
                params : query,
                headers: { 'Content-Type': 'application/json' }
            })
             .then((response) => {
                if(response && response.data && !response.data.success){
                    this.handleError(response.data, reject)
                }
                else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
               this.handleError(error, reject)
            });
        });
        return promise;

    }

    post(url, payload){

        var promise = new Promise((resolve, reject) => {
            axios({
                method : 'post',
                url : url,
                data : payload
            })
            .then((response) => {
                if(response && response.data && !response.data.success){
                    this.handleError(response.data, reject)
                }
                else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
               this.handleError(error, reject)
            });
        });
        return promise;

    }

    postMultipart(url, payload){
        var promise = new Promise((resolve, reject) => {
            axios({
                method : 'post',
                url : url,
                data : payload,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
                .then((response) => {
                    if(response && response.data && !response.data.success){
                        this.handleError(response.data, reject)
                    }
                    else {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    this.handleError(error, reject)
                });
        });
        return promise;
    }

    put(url, payload){

            var promise = new Promise((resolve, reject) => {
                axios({
                    method : 'put',
                    url : url,
                    data : payload
                })
                .then((response) => {
                    if(response && response.data && !response.data.success){
                        this.handleError(response.data, reject)
                    }
                    else {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                   this.handleError(error, reject)
                });
            });
            return promise;
    }

    update(url, params, payload){

        var promise = new Promise((resolve, reject) => {
            axios({
                method : 'put',
                url : url,
                data : payload,
                params : params
            })
            .then((response) => {
                if(response && response.data && !response.data.success){
                    this.handleError(response.data, reject)
                }
                else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
               this.handleError(error, reject)
            });
        });
        return promise;

    }

    fetch(url, params){

        var promise = new Promise((resolve, reject) => {
            axios({
                method : 'get',
                url : url,
                params : params
            })
            .then((response) => {
                if(response && response.data && !response.data.success){
                    this.handleError(response.data, reject)
                }
                else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
               this.handleError(error, reject)
            });
        });
        return promise;

    }

    pdffetch(url, params){

        var promise = new Promise((resolve, reject) => {

            axios({
                method : 'get',
                url : url,
                params : params,
                responseType: 'arraybuffer' //Force to receive data in a Blob Format
            })
                .then((response) => {
	                fileDownload(response.data, params.id+'.pdf');
                })
                .catch((error) => {
                    this.handleError(error, reject)
                });
        });
        return promise;

    }

	exportToFile(url, params){

		var promise = new Promise((resolve, reject) => {

			axios({
				method : 'get',
				url : url,
				params : params,
				responseType: 'arraybuffer' //Force to receive data in a Blob Format
			})
				.then((response) => {
                    console.log(response);
					//Create a Blob from the xls Stream
					// const file = new Blob(
					// 	[response.data],
					// 	{type: 'application/vnd.openxmlformats'});
					// //Build a URL from the file
					// const fileURL = URL.createObjectURL(file);

					fileDownload(response.data, response.headers.file_name);
					//Open the URL on new Window
					// window.open(fileURL);
				})
				.catch((error) => {
					this.handleError(error, reject)
				});
		});
		return promise;

	}

    delete(url, params, data) {
        let promise = new Promise((resolve, reject) => {
            axios({
                method: 'delete',
                url: url,
                params: params,
                data: data
            })
                .then((response) => {
                    if (response && response.data && !response.data.success) {
                        reject(response.data);
                    }
                    else resolve(response.data);
                })
                .catch((error) => {

                    if (error && Object.keys(error).length < 1) {
                        reject({
                            errors: "We are under maintenance.Please visit us later"
                        })
                    }
                    else reject(error.response && error.response.data);

                });
        });
        return promise;
    }
}

//Create a object of the class and freeze the properties of the object
const commonApiService = new CommonApiService();
Object.freeze(commonApiService);

export default commonApiService

