import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import { APP_IOS, APP_ANDROID } from '@constants/links';
const AllSet = () => (
  <div className={'all-set'}>
    <div className="all-set-block">
      <img src={'/images/domains/set.svg'} alt="set" />
      <h3 className={'all-set-h3'}>You're all set!</h3>
      <p className={'all-set-p'}>Your website and form have been successfully set up with Buzzz. Your life has just changed for the better.</p>
      <Link to={'/dashboard?guide=true'}>
        <Button
          type={'primary'}
          size={'large'}
          onClick={() => {
            document.getElementById('overlay').style.opacity = 1;
            document.getElementById('overlay').style.display = 'block';
          }}
        >
          Continue to your dashboard
        </Button>
      </Link>
    </div>

    <div className="app-block">
      <Row>
        <Col sm={10} xs={24}>
          <div
            className={'app-image'}
            style={{ backgroundImage: `url('/images/domains/appview.jpg')` }}
          />
        </Col>
        <Col sm={14} xs={24}>
          <div className={'rightside'}>
            <p>
              Time to download the app onto your mobile device using the links below, or alternatively check your email as we've sent you direct download links straight into your inbox.
            </p>
            <p className={'tag'}>
              It's time to Buzzz!
            </p>
            <div className={'links'}>
              <a
                target={'_blank'}
                href={APP_IOS}
              >
                <img
                  className={'appstore'}
                  src="/images/domains/app_store.jpg"
                  alt="app store"
                />
              </a>
              <a
                target={'_blank'}
                href={APP_ANDROID}
              >
                <img
                  className={'googleplay'}
                  src="/images/domains/google_play.jpg"
                  alt="google play"
                />
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default AllSet;
