import React, {Component} from 'react';
import {Switch, Route} from "react-router-dom";
import './DashboardLayout.scss'


import DashboardHome from './DashboardHome';
import DashboardWebsite from './DashboardWebsite';


class DashboardLayout extends Component {

    componentDidMount() {
        //Make the necessary api calls here if required
        if (!localStorage.getItem('token')) {
            this.props.history.push('/')
        }

    }

    render() {

        return (
            <Switch>
                <Route exact path="/dashboard" component={DashboardHome}/>
                <Route path="/dashboard/:domain_id" component={DashboardWebsite}/>
            </Switch>
        )
    }
}

export default DashboardLayout;
