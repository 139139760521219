import React, { Component } from 'react';

import { Button, Badge, Popover, Icon, Tooltip } from 'antd';
import moment from 'moment';
import commonApiService from '@apiService';

const messageLimit = 50;
const formatNotification = (details) => {
  const len = details.message.length;
  const message = len > messageLimit ? details.message.substr(0, messageLimit) + ' ... ' + details.message.substr(len - 10): details.message;
  return (
    <>
      <b>{details.customer_name}</b>{' from '}<b>{details.domain_name}</b>{' (' + details.form_name + ') has contacted with message "' + message + '"'}
    </>
  )
}

class BroadCastLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      hidden: false,
      visible: false,
      content: [],
      form: null,
      other: null,
      form_loading: true,
      other_loading: true,
    };
  }

  updateNotification = notifications => {
    this.setState({
      form: notifications.form,
      other: notifications.other,
      form_loading: false,
      other_loading: false,
      count: notifications.form.unread_count + notifications.other.unread_count,
      original_count:
        notifications.form.unread_count + notifications.other.unread_count,
    });
  };

  prependNotification = (notification, type) => {
    if (type === 'form') {
      let form_notification = this.state.form
        ? this.state.form.notifications
        : [];
      form_notification.unshift(notification);
      this.setState({
        form: {
          notifications: form_notification,
          total_count: this.state.form.total_count,
          unread_count: this.state.form.unread_count,
        },
        count: this.state.original_count + 1,
        original_count: this.state.original_count + 1,
      });
    } else if (type === 'other') {
      let other_notification = this.state.other
        ? this.state.other.notifications
        : [];
      other_notification.unshift(notification);
      this.setState({
        other: {
          notifications: other_notification,
          total_count: this.state.other.total_count,
          unread_count: this.state.other.unread_count,
        },
        count: this.state.original_count + 1,
        original_count: this.state.original_count + 1,
      });
    }
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  fetchFormNotifications = () => {
    this.setState({
      form_loading: true,
    });
    commonApiService
      .list('/user/notifications', {
        type: 'form',
        timestamp: this.state.form.notifications[
          this.state.form.notifications.length - 1
        ].created_at,
      })
      .then(successResult => {
        let form = {
          notifications: [
            ...this.state.form.notifications,
            ...successResult.data.form.notifications,
          ],
          total_count: successResult.data.form.total_count,
          unread_count: successResult.data.form.unread_count,
        };
        this.setState({
          form: form,
          form_loading: false,
        });
      })
      .catch(errorResult => {});
  };

  fetchOtherNotifications = () => {
    commonApiService
      .list('/user/notifications', {
        type: 'other',
        timestamp: this.state.other.notifications[
          this.state.other.notifications.length - 1
        ].created_at,
      })
      .then(successResult => {
        let other = {
          notifications: [
            ...this.state.other.notifications,
            ...successResult.data.other.notifications,
          ],
          total_count: successResult.data.other.total_count,
          unread_count: successResult.data.other.unread_count,
        };
        this.setState({
          form: other,
          form_loading: false,
        });
      })
      .catch(errorResult => {});
  };

  markAsReadForm = (notification, index) => {
    let form_notification = this.state.form.notifications;
    form_notification[index].read = 1;

    let form = {
      notifications: form_notification,
      total_count: this.state.form.total_count,
      unread_count: this.state.form.unread_count,
    };
    this.setState({
      form: form,
    });
    this.markAsReadApi(notification);
  };

  markAsReadOther = (notification, index) => {
    let other_notification = this.state.other.notifications;
    other_notification[index].read = 1;

    let other = {
      notifications: other_notification,
      total_count: this.state.other.total_count,
      unread_count: this.state.other.unread_count,
    };
    this.setState({
      other: other,
    });
    this.markAsReadApi(notification);
  };

  markAsReadApi = notification => {
    commonApiService
      .put('/web/user/notification/mark_as_read', {
        timestamp: notification.created_at,
      })
      .then(successResult => {})
      .catch(errorResult => {});
  };

  render() {
    let form_notification = this.state.form
      ? this.state.form.notifications
      : [];
    let other_notification = this.state.other
      ? this.state.other.notifications
      : [];

    const menu = (
      <div className="notification-container">
        <div className="notification-type">
          <div className="notification-header">App Notifications </div>
          <div className="notification-body">
            <ul>
              {form_notification && form_notification.length ? (
                form_notification.map((notification, index) => {
                  return (
                    <li key={index} hidden={notification.read}>
                      <div>
                        <p className={'notification-title'}>
                          {formatNotification(notification.details)}
                        </p>
                        <p className="notification-read">
                          <span
                            className="read-dot"
                            onClick={e => {
                              this.markAsReadForm(notification, index);
                            }}
                          >&times;</span>
                        </p>
                      </div>
                      <p className="notification-time">
                        <span>
                          {moment.unix(notification.created_at).fromNow()}{' '}
                        </span>
                      </p>
                    </li>
                  );
                })
              ) : (
                <li>
                  <p className="notification-title align-center">
                    You have 0 Notifications
                  </p>
                </li>
              )}
              {form_notification && form_notification.length > 0 && (
                <li
                  hidden={
                    form_notification.length === this.state.form.total_count
                  }
                  onClick={this.fetchFormNotifications}
                >
                  <p className="notification-loadmore ">
                    {this.state.form_loading ? (
                      <Icon type="loading" />
                    ) : (
                      'Load more'
                    )}
                  </p>
                </li>
              )}
            </ul>
          </div>
        </div>
        {other_notification.length > 0 && (
          <div className="notification-type">
            <div className="notification-header">Others</div>
            <div className="notification-body">
              <ul>
                {other_notification.map((notification, index) => {
                  return (
                    <li key={index} hidden={notification.read}>
                      <div>
                        <p
                          className={
                            notification.read
                              ? 'notification-title'
                              : 'notification-title new'
                          }
                        >
                          {notification.details.title} |{' '}
                          {notification.details.message}{' '}
                        </p>
                        <p
                          className="notification-read"
                        >
                          <span
                            className="read-dot"
                            onClick={e => {
                              this.markAsReadForm(notification, index);
                            }}
                          >&times;</span>
                        </p>
                      </div>
                      <p className="notification-time">
                        {moment.unix(notification.created_at).fromNow()}
                      </p>
                    </li>
                  );
                })}
                <li
                  hidden={
                    other_notification.length === this.state.other.total_count
                  }
                  onClick={this.fetchOtherNotifications}
                >
                  <p className="notification-loadmore ">
                    {this.state.other_loading ? (
                      <Icon type="loading" />
                    ) : (
                      'Load more'
                    )}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    );

    return (
      <Popover
        getPopupContainer={() => document.getElementById('dropdown-zone')}
        content={menu}
        placement="bottomRight"
        trigger={['click']}
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
      >
        <Button
          type="default"
          className="notification"
          id={'notification-btn'}
          size="large"
          shape="circle"
          onClick={() => {
            this.setState({
              count: 0,
            });
          }}
        >
          <Badge count={this.state.count}>
            <span className="icon-notification" />
          </Badge>
        </Button>
      </Popover>
    );
  }
}
export default BroadCastLayout;
