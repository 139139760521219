import React, {Component} from "react";
import {Button, Form, Input, notification} from "antd";
import MobileInput from '@components/common/MobileNumberLayout';
import commonApiService from '@apiService';
class Maintenance extends Component{

  state = {
    domain: {}
  }

  updateMobileInput = () => {
    const { domain } = this.state;
    if (domain && domain.emergency_contact) {
      this.refs.mobileInput &&
      this.refs.mobileInput.updateInput(
        domain.emergency_contact.contact_number,
      );
    }
  };


  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const phoneNumberData = this.refs.mobileInput.getPhoneNumber();
      if (!err && phoneNumberData) {
        const { domain } = this.state;
        const update_domain_data = {
          domain_id: domain.id,
          emergency_contact: {
            id: domain.emergency_contact.id,
            display_name: values.name,
            company: values.company,
            contact_number: phoneNumberData,
            email: values.emailId,
          },
        };
        commonApiService
          .put('/web/domains/edit', update_domain_data)
          .then(successResult => {
            notification.open({
              message: successResult.data.msg,
              description: 'All changes have been updated successfully',
              className: 'notification-success',
            });
          })
          .catch(errorResult => {
            notification.open({
              message: 'Domain Update Failed!',
              description: errorResult.errors,
              className: 'notification-error',
            });
          });
      }
    });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      domain: nextProps.domain
    }, this.updateMobileInput)
  }

  render() {

    const { getFieldDecorator } = this.props.form;
    const { domain } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item>
          {getFieldDecorator('name', {
            initialValue:
              domain &&
              domain.emergency_contact &&
              domain.emergency_contact.display_name,
            rules: [
              { required: true, message: 'Please input your Name!' },
            ],
          })(<Input placeholder=" " />)}
          <div className="floating-label">Name</div>
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('company', {
            initialValue:
              domain &&
              domain.emergency_contact &&
              domain.emergency_contact.company,
            rules: [
              {
                required: true,
                message: 'Please input your Company Name!',
              },
            ],
          })(<Input placeholder=" " />)}
          <div className="floating-label">Company</div>
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('emailId', {
            initialValue:
              domain &&
              domain.emergency_contact &&
              domain.emergency_contact.email,
            rules: [
              { required: true, message: 'Please input your Email!' },
            ],
          })(<Input placeholder=" " />)}
          <div className="floating-label">Email</div>
        </Form.Item>
        <MobileInput ref={'mobileInput'} />
        <Form.Item>
          <Button type="primary" className={'has-width'} htmlType={'submit'}>
            Save
          </Button>
        </Form.Item>

      </Form>
    )
  }
}

export default Form.create()(Maintenance);