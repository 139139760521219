import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from 'antd';

const TextInput = ({
  form,
  name,
  label,
  required,
  placeholder,
  initialValue,
  formItemLayout,
  max,
  min,
  rows,
  extra,
  help,
  validateStatus,
  hasFeedback,
  error,
  onChange,
  isEmail,
  disabled,
  size,
  className,
  type,
  visibilityToggle,
  floating,
  floatingExtra,
  forceFloat,
  addonBefore,
  addonAfter,
  normalize,
}) => {
  const maxLength = max || (name === '_id' ? 30 : 1000);
  const status = error ? 'error' : validateStatus;
  const helpMessage = error || help;
  const email = isEmail
    ? {
        type: 'email',
        message: 'Invalid Email',
      }
    : {};
  return (
    <Form.Item
      label={floating ? null : label}
      className={className}
      validateStatus={status}
      help={helpMessage}
      extra={extra}
      hasFeedback={hasFeedback}
      {...formItemLayout}
    >
      {form.getFieldDecorator(name, {
        initialValue,
        rules: [
          {
            required,
            message: `${label} is required`,
          },
          {
            max: maxLength,
            message: `${
              label ? label : 'Field'
            } cannot exceed ${maxLength} characters`,
          },
          {
            min,
            message: `${
              label ? label : 'Field'
            } must be at least ${min} characters`,
          },
          { ...email },
        ],
        normalize,
      })(
        !rows ? (
          type === 'password' ? (
            <Input.Password
              placeholder={placeholder ? placeholder : ' '}
              maxLength={maxLength}
              className={className}
              onChange={onChange}
              disabled={disabled}
              size={size}
              type={type}
              visibilityToggle={visibilityToggle}
            />
          ) : (
            <Input
              placeholder={placeholder ? placeholder : ' '}
              maxLength={maxLength}
              onChange={onChange}
              disabled={disabled}
              size={size}
              type={type}
              addonBefore={addonBefore}
              addonAfter={addonAfter}
            />
          )
        ) : (
          <Input.TextArea
            placeholder={placeholder ? placeholder : ' '}
            maxLength={maxLength}
            rows={rows}
            onChange={onChange}
            disabled={disabled}
            size={size}
          />
        ),
      )}
      {floating ? (
        <div className={`floating-label ${forceFloat ? 'force-float' : ''}`}>
          {label}
          {floatingExtra ? floatingExtra : null}
        </div>
      ) : null}
    </Form.Item>
  );
};

TextInput.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  help: PropTypes.string,
  extra: PropTypes.string,
  validateStatus: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  whitespace: PropTypes.bool,
  hasFeedback: PropTypes.bool,
  isEmail: PropTypes.bool,
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  rows: PropTypes.number,
  formItemLayout: PropTypes.object,
  addonBefore: PropTypes.node,
  floating: PropTypes.bool,
};

TextInput.defaultProps = {
  visibilityToggle: true,
  floating: true,
  forceFloat: false,
  suffix: null,
  prefix: null,
  normalize: null,
};

export default TextInput;
