import React, { Component } from 'react';
import {
  Button,
  Card,
  Form,
  Input,
  notification,
  Select,
  Row,
  Col,
} from 'antd';
import commonApiService from '@apiService';
import { TERMS_CONDITION, PRIVACY_POLICY } from '@constants/links';
import moment from 'moment';
import Script from 'react-load-script';
import country_state_city_list from '@components/common/countryStateCity';


const AUTOFILLED = 'is-autofilled';
const onAutoFillStart = el => el.classList.add(AUTOFILLED);
const onAutoFillCancel = el => el.classList.remove(AUTOFILLED);

const onAnimationStart = ({ target, animationName }) => {
  switch (animationName) {
    case 'onAutoFillStart':
      return onAutoFillStart(target);
    case 'onAutoFillCancel':
      return onAutoFillCancel(target);
    default:
  }
};


function luhn_check(val) {
  let value = val.split("-").join("");
  return value.split('')
    .reverse()
    .map( (x) => parseInt(x, 10) )
    .map( (x,idx) => idx % 2 ? x * 2 : x )
    .map( (x) => x > 9 ? (x % 10) + 1 : x )
    .reduce( (accum, x) => accum += x ) % 10 === 0;
}



class StartTrialLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      subscriptions: [],
      fullFeatures: false,
      countries: [],
      states: [],
      cities: [],
      selectedPlan: props.plan,
      user_details: props.userData,
      discountApplied: false,
      couponCode: '',
      trialDays: 14,
      endDate: moment()
        .add(14, 'days')
        .format('DD MMM YYYY'),
      billingInfo: {},
      scriptLoaded: false,
      selectedCountry: {},
      selectedState: {},
      country: ""
    };
    window.scrollTo(0, 0);
  }

  componentDidMount() {

    let countries = country_state_city_list.getAllCountries();
    let country = this.props.userData.phone_number.country.toUpperCase();

    var selectedCountry =  countries.filter(function(singleCountry) {
        return singleCountry.sortname === country;
    });

    if(selectedCountry.length === 0){
	    selectedCountry = [{
		    "id": "231",
		    "sortname": "US",
		    "name": "United States",
		    "phonecode": "1"
	    }]
    }
    const states = country_state_city_list.getStatesOfCountry(selectedCountry[0].id);
    this.setState({
        countries,
        states,
        country: selectedCountry[0].name
    }, () => {
        this.props.form.setFieldsValue({
            country: selectedCountry[0].name
        });
    });

    commonApiService
      .list('/billinginfo', {})
      .then(successResult => {
        if (successResult.success) {
          this.setState({
            billingInfo: successResult.data.payment_configuration,
          });
        } else {
          console.log('error while fetching countries');
        }
      })
      .catch(errorResult => {
        console.log('error while fetching countries');
      });

    if (this.props.errors) {
      var errors = this.props.errors;
      errors.confirm_password.value = this.props.form.getFieldValue(
        errors.confirm_password.value,
      );
      this.props.form.setFields(errors);
    }

    let inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.addEventListener('animationstart', onAnimationStart, false);
    });
  }

  toggleDiscountForm = () => {
    this.setState({
      showDiscountForm: !this.state.showDiscountForm,
    });
  };

  applyDiscountCode = () => {
    const form = this.props.form;

    if(this.state.discountApplied){
	    form.setFieldsValue({
		    couponCode: ''
	    });
	    this.setState({
		    discountApplied: false,
		    trialDays: 14,
		    endDate: moment()
			    .add(14, 'days')
			    .format('DD MMM YYYY')
	    });
	    return;
    }

    const couponCode = form.getFieldValue('couponCode');
    if (couponCode && this.state.user_details.email) {
      const bodyParams = {
        couponCode,
        email: this.state.user_details.email,
        plan_id: this.state.selectedPlan.pID,
      };

      commonApiService
        .post('/web/coupon/validate', bodyParams)
        .then(successResult => {
          if (successResult.success) {
            var discountDetails = successResult.data;
            var trialDays = this.state.trialDays;
            var selectedPlan = this.state.selectedPlan;
            var endDate = this.state.endDate;
            if (discountDetails.isDays) {
              trialDays = discountDetails.discount;
              endDate = moment()
                .add(trialDays, 'days')
                .format('DD MMM YYYY')
            }

            if (discountDetails.isPercentage) {
              selectedPlan.price =
                this.state.selectedPlan * (discountDetails.discount / 100);
            }

            if (!discountDetails.isDays && !discountDetails.isPercentage) {
              if (discountDetails.discount > 0) {
                selectedPlan.price =
                  selectedPlan.price - discountDetails.discount;
              }
            }

            this.setState({
              couponCode,
              discountApplied: true,
              trialDays,
              selectedPlan,
              endDate
            });
          } else {
            notification.open({
              message: 'Error',
              description: 'Invalid Coupon',
              className: 'notification-error',
            });
            this.setState({
              couponCode: '',
              discountApplied: false,
            });
          }
        })
        .catch(errorResult => {
          notification.open({
            message: 'Error',
            description: 'Invalid Coupon',
            className: 'notification-error',
          });
          this.setState({
            couponCode: '',
            discountApplied: false,
          });
        });
    } else {
      notification.open({
        message: 'Error!',
        description: 'Invalid Coupon',
        className: 'notification-error',
      });
    }
  };

  updatedTokenRequest = (request_data, callback) => {
    var args = {
      sellerId: this.state.billingInfo.seller_id,
      publishableKey: this.state.billingInfo.publishable_key,
      ccNo: request_data.cardNumber,
      cvv: request_data.cvv,
      expMonth: request_data.expiry_month,
      expYear: request_data.expiry_year,
    };
    var successCallback = function(data) {
      console.log('success');
      callback(null, data.response.token.token);
    };
    //Called when token creation fails.
    var errorCallback = function(data) {
      // Retry the token request if ajax call fails
      if (data.errorCode === 200) {
        window.TCO.requestToken(successCallback, errorCallback, args);
      } else {
        callback(data.errorMsg);
      }
    };
    //Make the token request
    window.TCO.requestToken(successCallback, errorCallback, args);
  };

  submitCardDetails = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });

        var args = {
          sellerId: this.state.billingInfo.seller_id,
          publishableKey: this.state.billingInfo.publishable_key,
          ccNo: values.cardNumber.split("-").join(""),
          cvv: values.cvv,
          expMonth: values.expiry_month,
          expYear: values.expiry_year,
        };

        var selectedCountryArr = values.country.split('|');
        var selectedCountry = selectedCountryArr && selectedCountryArr.length === 2 ? selectedCountryArr[1]:this.state.country;

        var successCallback = data => {
          var formData = {
            card_details: {
              cardName: values.cardName,
              street_address: values.street_address,
              city: values.city,
              state: values.state,
              postal_code: values.postal_code,
              country: selectedCountry,
              token: data.response.token.token,
              card_number: values.cardNumber.slice(-4),
              expiry: values.expiry_month + '/' + values.expiry_year,
            },
            couponCode: this.state.couponCode,
          };
          this.props.submitForm(formData, this.setLoading);
        };
        //Called when token creation fails.
        var errorCallback = data => {
          // Retry the token request if ajax call fails
          if (data.errorCode === 200) {
            window.TCO.requestToken(successCallback, errorCallback, args);
          } else {
            notification.open({
              message: 'Error',
              description:
                'Issue while authorising the card for recurring payment',
              className: 'notification-error',
            });
          }
          this.setState({
            loading: false,
          });
        };
        //Make the token request
        window.TCO.requestToken(successCallback, errorCallback, args);
      }
    });
  };

  setLoading = () => {
    this.setState({
      loading: false,
    });
  };

  handleScriptLoad = () => {
    this.setState({
      scriptLoaded: true,
    });
    window.TCO.loadPubKey('sandbox', function() {
      //@todo: Catch Errors associated with PublishedKey Fetching if any here
    });
  };

  handleCountryChange = (country) => {
    const selectedCountryCode = country.split('|');
    const selectedCountry = this.state.countries.filter(function(singleCountry) {
	    return singleCountry.sortname === selectedCountryCode[0];
    });
    this.setState({
        states: country_state_city_list.getStatesOfCountry(selectedCountry[0].id)
    });
  };

  handleStateChange = (selectedStateName) => {
      console.log(selectedStateName);
	  const selectedState = this.state.states.filter(function(state) {
		  return state.name === selectedStateName;
	  });
	  this.setState({
		  cities: country_state_city_list.getCitiesOfState(selectedState[0].id)
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const expMonths = [];
    for (let expMonth = 1; expMonth <= 12; expMonth++) {
      expMonths.push(
        <Select.Option key={expMonth} value={expMonth}>
          {expMonth}
        </Select.Option>,
      );
    }

    const expYears = [];

    var date = new Date();
    for (let expYear = date.getFullYear(); expYear <= 2080; expYear++) {
      expYears.push(
        <Select.Option key={expYear} value={expYear}>
          {expYear}
        </Select.Option>,
      );
    }

    const {
      details,
      pIsCustom,
      // pIsTrialAvailable,
      price,
    } = this.state.selectedPlan;

    return (
      <div className="start-trial">
        <Script
          url="https://www.2checkout.com/checkout/api/2co.min.js"
          onLoad={this.handleScriptLoad.bind(this)}
        />
        <div className="container">
          <div className="side-box">
            <h2>
              Start your free trial<span>.</span>
            </h2>
            <Card bordered={false}>
              <h4>Credit card information</h4>
              <Form onSubmit={this.submitCardDetails}>
                <Form.Item>
                  {getFieldDecorator('cardNumber', {
                    validateTrigger: 'onBlur',
                    normalize: (value) => {
                      if(value){
                        let val = value.split("-").join("");
                        val = val.substr(0, 16);
                        if (val.length > 0) {
                          val = val.match(new RegExp('.{1,4}', 'g')).join("-");
                        }
                        return val;
                      }
                    },
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Card Number!',
                      },
                      {
                        validator: (rule, value, cb) => {
                          if(value && luhn_check(value)){
                            cb();
                            return true
                          }else{
                            cb(true);
                            return false;
                          }
                        },
                        message: 'Please input valid Card Number!',
                      }
                    ],
                  })(<Input placeholder=" " />)}
                  <div className="floating-label required">Card Number</div>
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('cardName', {
                    validateTrigger: 'onBlur',
                    rules: [
                      {
                        required: true,
                        message: 'Please input your name on Card!',
                      },
                    ],
                  })(<Input placeholder=" " />)}
                  <div className="floating-label required">Name on Card</div>
                </Form.Item>
                <div className="inline-select">
                  <span className="card-month">
                    <Form.Item>
                      {getFieldDecorator('expiry_month', {
                        rules: [{ required: true, message: 'Expiry month' }],
                      })(
                        <Select placeholder=" " showSearch>
                          {expMonths}
                        </Select>,
                      )}
                      <div className="floating-label  required">MM</div>
                    </Form.Item>
                  </span>
                  <span className="date-divider">/</span>
                  <span className="card-year">
                    <Form.Item>
                      {getFieldDecorator('expiry_year', {
                        rules: [{ required: true, message: 'Expiry year' }],
                      })(
                        <Select placeholder=" " showSearch>
                          {expYears}
                        </Select>,
                      )}
                      <div className="floating-label required">YYYY</div>
                    </Form.Item>
                  </span>
                  <span className="cvv">
                    <Form.Item>
                      {getFieldDecorator('cvv', {
                        rules: [{ required: true, message: 'CVV is required' }],
                      })(<Input placeholder={' '} />)}
                      <div className="floating-label required">CVV</div>
                    </Form.Item>
                  </span>
                </div>

                <Form.Item>
                  {getFieldDecorator('country', {
                    validateTrigger: 'onBlur',
                    // initialValue: this.props.userData.phone_number_country,
                    rules: [
                      {
                        required: true,
                        message: "Please select Card Holder's Country!",
                      },
                    ],
                  })(
                    <Select showSearch placeholder={'Select a country'} onChange={this.handleCountryChange}>
                      {this.state.countries.map((country, index) => {
                        return (
                          <Select.Option key={country.sortname + '|' + country.name} >
                            {country.name}
                          </Select.Option>
                        );
                      })}
                    </Select>,
                  )}
                  <div className="floating-label force-float required">Country</div>
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('state', {
                        validateTrigger: 'onBlur',
                        rules: [
                            {
                                required: true,
                                message: "Please select Card Holder's State!",
                            },
                        ],
                    })(
                        <Select showSearch placeholder={'Select a State'} onChange={this.handleStateChange}>
                            {this.state.states.map((singleState, index) => {
                                return (
                                    <Select.Option key={singleState.name} >
                                        {singleState.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>,
                    )}
                  <div className="floating-label force-float required">State</div>
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('city', {
                        validateTrigger: 'onBlur',
                        rules: [
                            {
                                required: true,
                                message: "Please select Card Holder's City!",
                            },
                        ],
                    })(
                        <Select showSearch placeholder={'Select a city'} on>
                            {this.state.cities.map((city, index) => {
                                return (
                                    <Select.Option key={city.name}>
                                        {city.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>,
                    )}
                  <div className="floating-label force-float required">City</div>
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('street_address', {
                    validateTrigger: 'onBlur',
                    rules: [
                      {
                        required: true,
                        message: "Please enter Card Holder's Street Address!",
                      },
                    ],
                  })(<Input placeholder=" " />)}
                  <div className="floating-label required">Street</div>
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('postal_code', {
                    validateTrigger: 'onBlur',
                    rules: [
                      {
                        required: true,
                        message: "Please enter Card Holder's Postal Code!",
                      },
                    ],
                  })(<Input placeholder=" " />)}
                  <div className="floating-label required">Postal/ Zip Code</div>
                </Form.Item>
                <div className="coupen">
                  <Row>
                    <Col xs={16}>
                      <Form.Item>
                        {getFieldDecorator('couponCode', {
                          initialValue: this.state.couponCode,
                        })(<Input placeholder=" " />)}
                        <div className="floating-label">Coupon Code</div>
                      </Form.Item>
                    </Col>
                    <Col xs={8}>
                      <div className={'align-right'}>
                        <Button
                          size="small"
                          type="default"
                          onClick={this.applyDiscountCode}
                        >
                          {this.state.discountApplied ? 'Remove' : 'Apply'}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>

                <Button
                  className={'custom'}
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loading}
                >{`Start your free ${this.state.trialDays} day trial`}</Button>

                <div className={'post-text'}>
                  <ul className={'mini-checks'}>
                    <li>You won't be billed today</li>
                    <li>Cancel any time before {this.state.endDate}</li>
                  </ul>
                  <p>By signing up, you agree to Buzzz's</p>
                  <p>
                    <a target={'_blank'} href={TERMS_CONDITION}>
                      Terms of Conditions
                    </a>{' '}
                    and{' '}
                    <a target={'_blank'} href={PRIVACY_POLICY}>
                      Privacy Policy
                    </a>
                  </p>
                </div>
              </Form>
            </Card>
          </div>
        </div>
        <div className="selected-plan">
          <h3>Your Plan</h3>
          <Card bordered={false}>
            <img src={details.image_url_selected} alt="plan" />
            <h4>
              {details.heading}
              <span>.</span>
            </h4>
            <p>{details.organization_type}</p>
            {pIsCustom ? (
              <div className={'price'}>Say Hi!</div>
            ) : (
              <div className={'price'}>
                ${price} <span>p/m</span>
              </div>
            )}
            <p>{this.state.trialDays} day trial</p>
          </Card>
          <p className={'link'} onClick={this.props.changePlan}>
            Change Plan
          </p>

          <p className={'link'} onClick={this.props.goBack}>
            Go Back
          </p>
        </div>
      </div>
    );
  }
}

export default Form.create()(StartTrialLayout);
