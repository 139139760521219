import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import App from './App';
import store from './store/ConfigStore';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { networkDown, networkOnline } from '@actions/NetworkAction';

axios.interceptors.request.use(
  function(config) {
    if (!navigator.onLine) {
      store.dispatch(networkDown());
      return Promise.reject();
    } else {
      store.dispatch(networkOnline());
    }
    if (localStorage.getItem('token')) {
      config.headers['Authorization'] =
        'bearer ' + localStorage.getItem('token');
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  function(error) {
    console.error('Interceptor : Error occured due to : ', error);
    return Promise.reject(error);
  },
);

ReactDOM.render(
  <Provider store={store}>
    <LocaleProvider locale={enUS}>
      <App />
    </LocaleProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
