import commonApiService from '@apiService';

// --------------------------------------------------------------------------------
export function FetchSubscriptionPre() {
  return {
    type: 'FETCH_SUBSCRIPTION_PRE',
  };
}

export function FetchSubscriptionSuccess(payload) {
  return {
    type: 'FETCH_SUBSCRIPTION_SUCCESS',
    payload,
  };
}

export function FetchSubscriptionFailure(err) {
  return {
    type: 'FETCH_SUBSCRIPTION_FAILURE',
    err,
  };
}

export function FetchPlanSuccess(payload) {
  return {
    type: 'FETCH_PLAN_SUCCESS',
    payload,
  };
}

export function FetchPlanFailure(err) {
  return {
    type: 'FETCH_PLAN_FAILURE',
    err,
  };
}

export function requestCreateSubscriptionSuccess(payload) {
  return {
    type: 'REGISTER_SUBSCRIPTION_SUCCESS',
    payload,
  };
}

export function requestCreateSubscriptionFailure(err) {
  return {
    type: 'REGISTER_SUBSCRIPTION_FAILURE',
    err,
  };
}

export function requestUpdateBillingSuccess(payload) {
  return {
    type: 'UPDATE_BILLING_SUCCESS',
    payload,
  };
}

export function requestUpdateBillingFailure(err) {
  return {
    type: 'UPDATE_BILLING_FAILURE',
    err,
  };
}

export function requestDeactivateAccountSuccess(payload) {
  return {
    type: 'DEACTIVATE_ACCOUNT_SUCCESS',
    payload,
  };
}

export function requestDeactivateAccountFailure(err) {
  return {
    type: 'DEACTIVATE_ACCOUNT_FAILURE',
    err,
  };
}

export function requestactivateAccountSuccess(payload) {
  return {
    type: 'ACTIVATE_ACCOUNT_SUCCESS',
    payload,
  };
}

export function requestactivateAccountFailure(err) {
  return {
    type: 'ACTIVATE_ACCOUNT_FAILURE',
    err,
  };
}

export function requestupdateAccountPlanSuccess(payload) {
  return {
    type: 'UPDATE_ACCOUNT_PLAN_SUCCESS',
    payload,
  };
}

export function requestupdateAccountPlanFailure(err) {
  return {
    type: 'UPDATE_ACCOUNT_PLAN_FAILURE',
    err,
  };
}

export function fetchInvoicesSuccess(payload) {
  return {
    type: 'FETCH_INVOICES_SUCCESS',
    payload,
  };
}

export function fetchInvoicesFailure(err) {
  return {
    type: 'FETCH_INVOICES_FAILURE',
    err,
  };
}

export function updateBillingInfoPre() {
  return {
    type: 'UPDATE_BILLING_INFO_PRE',
  };
}

export function updateBillingInfoSuccess(payload) {
  return {
    type: 'UPDATE_BILLING_INFO_SUCCESS',
    payload,
  };
}

export function updateBillingInfoFailure(err) {
  return {
    type: 'UPDATE_BILLING_INFO_FAILURE',
    err,
  };
}

export function fetchCountryListSuccess(payload) {
  return {
    type: 'FETCH_COUNTRY_LIST_SUCCESS',
    payload,
  };
}

export function fetchCountryListFailure(err) {
  return {
    type: 'FETCH_COUNTRY_LIST_FAILURE',
    err,
  };
}

export function requestCreateSubscription(params) {
  return dispatch => {
    commonApiService
      .post('/web/create_subscription', params)
      .then(successResult => {
        dispatch(requestCreateSubscriptionSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(requestCreateSubscriptionFailure(errorResult));
      });
  };
}

export function fetchSubscription(params) {
  return dispatch => {
    dispatch(FetchSubscriptionPre());
    commonApiService
      .list('/web/subscription', params)
      .then(successResult => {
        dispatch(FetchSubscriptionSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(FetchSubscriptionFailure(errorResult));
      });
  };
}

export function changeBillingDetails(params) {
  return dispatch => {
    commonApiService
      .put('/web/subscription/change_billing', params)
      .then(successResult => {
        dispatch(requestUpdateBillingSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(requestUpdateBillingFailure(errorResult));
      });
  };
}

export function fetchPlan(params) {
  return dispatch => {
    commonApiService
      .list('/plans', params)
      .then(successResult => {
        dispatch(FetchPlanSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(FetchPlanFailure(errorResult));
      });
  };
}

export function deactivateAccount(params) {
  return dispatch => {
    commonApiService
      .put('/web/subscription/deactivate', params)
      .then(successResult => {
        dispatch(requestDeactivateAccountSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(requestDeactivateAccountFailure(errorResult));
      });
  };
}

export function activateAccount(params) {
  return dispatch => {
    commonApiService
      .put('/web/subscription/activate', params)
      .then(successResult => {
        dispatch(requestactivateAccountSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(requestactivateAccountFailure(errorResult));
      });
  };
}

export function updateAccountPlan(params) {
  return dispatch => {
    commonApiService
      .put('/web/subscription/change_plan', params)
      .then(successResult => {
        dispatch(requestupdateAccountPlanSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(requestupdateAccountPlanFailure(errorResult));
      });
  };
}

export function updateBillingInfo(params) {
  return dispatch => {
    dispatch(updateBillingInfoPre());
    commonApiService
      .put('/web/billinginfo', params)
      .then(successResult => {
        dispatch(updateBillingInfoSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(updateBillingInfoFailure(errorResult));
      });
  };
}

export function fetchSubscriptionInvoiceList(params) {
  return dispatch => {
    commonApiService
      .list('/web/subscription/invoices', params)
      .then(successResult => {
        dispatch(fetchInvoicesSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(fetchInvoicesFailure(errorResult));
      });
  };
}

export function fetchCountryList() {
  return dispatch => {
    commonApiService
      .list('/web/countries', {})
      .then(successResult => {
        dispatch(fetchCountryListSuccess(successResult));
      })
      .catch(errorResult => {
        dispatch(fetchCountryListFailure(errorResult));
      });
  };
}

export function fetchInvoicePdf(params) {
  return dispatch => {
    commonApiService
      .pdffetch('/web/subscription/invoices', params)
      .then(successResult => {})
      .catch(errorResult => {});
  };
}
/*Functions end*/
