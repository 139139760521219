import { Button, Col, Row } from 'antd';
import React, { Component } from 'react';
import Observer from 'react-intersection-observer';
import './Plans.scss';

class Plans extends Component {
  state = {
    fullFeatures: false,
    inview: true,
    hoverPlans: [true, true, true],
  };

  toggleFullFeatures = () => {
    this.setState(
      {
        fullFeatures: !this.state.fullFeatures,
      },
      () => {
        this.props.fullFeature && this.props.fullFeature();
      },
    );
  };

  setInView = inView => {
    this.setState({ inview: inView });
    this.props.setInView && this.props.setInView(inView);
  };

  hoverOn = i => {
    const hoverPlans = this.state.hoverPlans;
    for (let k = 0; k < 3; k++) {
      hoverPlans[k] = i === k;
    }
    this.setState({
      hoverPlans: hoverPlans,
    });
  };

  hoverOff = i => {
    let hoverPlans = this.state.hoverPlans;
    hoverPlans[i] = false;

    let checkHoverOffCount = 0;
    for (let k = 0; k < 3; k++) {
      hoverPlans[k] === false && checkHoverOffCount++;
    }
    if (checkHoverOffCount === 3) {
      hoverPlans = [true, true, true];
    }
    this.setState({
      hoverPlans: hoverPlans,
    });
  };

  render() {
    const { plans } = this.props;
    const { fullFeatures } = this.state;

    const planDom = plans.map((plan, index) => {
      const details = plan.details;
      const previousPlan = index > 0 && plans[index - 1];
      return (
        <Col md={8} sm={24} key={index}>
          <div
            className={plan.subscribed  ? 'details active' : 'details'}
            onMouseEnter={() => this.hoverOn(index)}
            onMouseLeave={() => this.hoverOff(index)}
          >
            <div
              className={'plan-icon'}
              style={{
                backgroundImage: `url(${
                  plan.subscribed  ? details.image_url_selected : (this.state.hoverPlans[index]
                    ? details.image_url_selected
                    : details.image_url)
                })`,
              }}
            />
            <h4>
              {details.heading}
              <span>.</span>
            </h4>
            <p>{details.organization_type}</p>
            {plan.pIsCustom ? (
              <div className={'price'}>Say Hi!</div>
            ) : (
              <div className={'price'}>
                ${plan.price} <span>p/m</span>
              </div>
            )}
            <div className="wrap">
              <Observer threshold={1} onChange={this.setInView}>
                {plan.subscribed ? (
                  <Button className={'plan-btn'}>Your current plan</Button>
                ) : plan.pIsCustom ? (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a target="_blank" href="https://buzzz.io">
                    <Button className={'plan-btn'}>Get in touch</Button>
                  </a>
                ) : (
                  <Button
                    onClick={() => {
                      this.props.nextStep(plan);
                    }}
                    className={'plan-btn'}
                  >
                    {plan.pIsTrialAvailable
                      ? 'Try 14 days for free'
                      : 'Subscribe'}
                  </Button>
                )}
              </Observer>
              {index === 0 ? (
                <h6 className={'hidden'}>Small</h6>
              ) : (
                <h6>All of {previousPlan.name} plus:</h6>
              )}
              <ul className={'ticked'}>
                <li>{details.user_count + ' ' + details.user_count > 1 ? 'Users' : 'User'}</li>
                <li>{details.forms} Form</li>
                <li>{details.domain_count} Website</li>
              </ul>
            </div>
            <Button onClick={this.toggleFullFeatures} className={'features'}>
              {fullFeatures ? 'Hide all features' : 'Show all features'}
            </Button>
          </div>
          <div className={'features'} hidden={!fullFeatures}>
            <div className="wrap">
              <ul>
                <li className={details.live_chat ? 'tick' : ''}>Live chat</li>
                <li className={details.allowed_ping ? 'tick' : ''}>
                  Allowed Ping
                </li>
                <li className={details.mobile_apps ? 'tick' : ''}>
                  Mobile apps
                </li>
                <li className={details.email ? 'tick' : ''}>Email</li>
                <li className={details.mobile_sdks ? 'tick' : ''}>
                  Mobile SDKs
                </li>
                <li className={details.twitter_integration ? 'tick' : ''}>
                  Twitter integration
                </li>
                <li className={details.fb_integration ? 'tick' : ''}>
                  Facebook integration
                </li>
                <li
                  className={
                    details.advance_messenger_email_customization ? 'tick' : ''
                  }
                >
                  Advanced mesenger and email customization
                </li>
                <li
                  className={details.automated_lead_qualification ? 'tick' : ''}
                >
                  Automatic lead qualification
                </li>
                <li
                  className={details.automated_meeting_scheduling ? 'tick' : ''}
                >
                  Automated meeting scheduling
                </li>
                <li
                  className={
                    details.automated_article_suggestions ? 'tick' : ''
                  }
                >
                  Automatic article suggestions
                </li>
                <li className={details.custom_bots ? 'tick' : ''}>
                  Custom bots
                </li>
              </ul>
              {!this.state.inview ? (
                plan.subscribed ? (
                  <Button className={'plan-btn mobile-hide'}>
                    Your current plan
                  </Button>
                ) : plan.pIsCustom ? (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <a target="_blank" href="https://buzzz.io">
                    <Button
                      onClick={() => {
                        this.props.nextStep(plan);
                      }}
                      className={'plan-btn mobile-hide'}
                    >
                      Get in touch
                    </Button>
                  </a>
                ) : (
                  <Button
                    onClick={() => {
                      this.props.nextStep(plan);
                    }}
                    className={'plan-btn mobile-hide'}
                  >
                    {plan.pIsTrialAvailable
                      ? 'Try 14 days for free'
                      : 'Subscribe'}
                  </Button>
                )
              ) : null}
            </div>
          </div>
        </Col>
      );
    });

    return (
      <div className={fullFeatures ? 'plan-types full' : 'plan-types'}>
        <Row>{planDom}</Row>
      </div>
    );
  }
}

export default Plans;
